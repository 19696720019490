<!-- ProfileHeader.vue -->
<template>
  <div class="container-fluid">
    <div
      class="page-header min-height-250"
      :style="{
        backgroundImage: 'url(' + headerImage + ')',
        marginRight: '-24px',
        marginLeft: '-34%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="card shadow-lg mt-n6">
      <div class="card-body p-3">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img
                :src="profileImage"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{ userName }}</h5>
            </div>
          </div>
          <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
            <NavHead @update:activeTab="handleActiveTabUpdate" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import NavHead from "./NavHead.vue";

const auth = getAuth();
const db = getFirestore();

const headerImage = ref('');
const profileImage = ref('');
const userName = ref('Unknown');
const userRole = ref('User');

const loadUserData = async () => {
  const user = auth.currentUser;
  if (user) {
    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);
    
    if (userSnap.exists()) {
      const userData = userSnap.data();
      userName.value = userData.name || 'Unknown';
      userRole.value = userData.role || 'User';
      
      // Hent billeder fra adminSettings
      if (userData.adminSettings) {
        headerImage.value = userData.adminSettings.headerImage || '';
        profileImage.value = userData.adminSettings.profileImage || '../assets/img/default-profile.png';
      } else {
        // Fallback til gamle stier, hvis adminSettings ikke findes
        headerImage.value = userData.headerImage || '';
        profileImage.value = userData.profileImage || '../assets/img/default-profile.png';
      }
    }
  }
};

onMounted(() => {
  loadUserData();
});

const emit = defineEmits(['update:activeTab']);

const handleActiveTabUpdate = (newTab) => {
  emit('update:activeTab', newTab);
};
</script>
