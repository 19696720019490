<template>
    <div class="card mt-3">
      <div class="card-body">
        <h2>Opret nyt blogindlæg</h2>
        <div class="mb-3">
          <label for="title" class="form-label">Overskrift</label>
          <input v-model="blogPost.title" type="text" class="form-control" id="title" placeholder="Overskrift">
        </div>
        <div class="mb-3">
          <label class="form-label">Vælg kategori</label>
          <select v-model="blogPost.category" class="form-control">
            <option value="Nyhed">Nyhed</option>
            <option value="Feature">Feature</option>
            <option value="Profil">Profil</option>
            <option value="Kalender">Kalender</option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label">Indhold</label>
          <div ref="quillEditor"></div>
        </div>
        <div class="mb-3">
          <label class="form-label">Banner billede</label>
          <input type="file" @change="handleImageUpload" class="form-control">
        </div>
        <div class="form-check form-switch ps-0 mb-3">
          <input v-model="blogPost.sendNotification" class="form-check-input ms-0" type="checkbox">
          <label class="form-check-label">Send notifikation</label>
        </div>
        <button @click="createBlogPost" class="btn btn-primary">Opret indlæg</button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import Quill from 'quill';
  import 'quill/dist/quill.snow.css';
  import { createBlogPost as createBlogPostService } from '@/services/blogService'; // Du skal oprette denne service
  import { getFirestore, collection, addDoc } from 'firebase/firestore';
  import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

  const quillEditor = ref(null);
  const blogPost = ref({
    title: '',
    category: '',
    content: '',
    bannerImage: null,
    sendNotification: false
  });
  
  onMounted(() => {
    const quill = new Quill(quillEditor.value, {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],
          ['link', 'image', 'video']
        ]
      }
    });
  
    quill.on('text-change', () => {
      blogPost.value.content = quill.root.innerHTML;
    });
  });
  
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      blogPost.value.bannerImage = file;
    }
  };
  
  const createBlogPost = async () => {
    try {
      const db = getFirestore();
      const storage = getStorage();

      // Upload banner-billede, hvis det findes
      let bannerImageUrl = null;
      if (blogPost.value.bannerImage) {
        const imageRef = storageRef(storage, `blog-images/${Date.now()}_${blogPost.value.bannerImage.name}`);
        await uploadBytes(imageRef, blogPost.value.bannerImage);
        bannerImageUrl = await getDownloadURL(imageRef);
      }

      // Opret blogindlæg dokument
      const docRef = await addDoc(collection(db, 'blogPosts'), {
        title: blogPost.value.title,
        category: blogPost.value.category,
        content: blogPost.value.content,
        bannerImageUrl: bannerImageUrl,
        sendNotification: blogPost.value.sendNotification,
        createdAt: new Date()
      });

      console.log('Blogindlæg oprettet med ID:', docRef.id);

      // Nulstil formularen efter oprettelse
      blogPost.value = {
        title: '',
        category: '',
        content: '',
        bannerImage: null,
        sendNotification: false
      };
      if (quillEditor.value) {
        quillEditor.value.querySelector('.ql-editor').innerHTML = '';
      }
    } catch (error) {
      console.error('Fejl ved oprettelse af blogindlæg:', error);
    }
  };
  </script>
