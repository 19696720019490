import { ref, onMounted } from 'vue';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export function usePasswordManagement() {
  const auth = getAuth();
  const resetEmail = ref('');
  const errorMessage = ref('');
  const successMessage = ref('');

  const resetPassword = async () => {
    errorMessage.value = '';
    successMessage.value = '';

    if (!resetEmail.value) {
      errorMessage.value = 'Indtast venligst din e-mailadresse.';
      return;
    }

    try {
      console.log('Forsøger at sende nulstillingsmail til:', resetEmail.value);
      await sendPasswordResetEmail(auth, resetEmail.value);
      console.log('Firebase rapporterer, at nulstillingsmail er sendt succesfuldt');
      successMessage.value = 'En e-mail med instruktioner til nulstilling af adgangskode er blevet sendt.';
    } catch (error) {
      console.error('Fejl ved nulstilling af adgangskode:', error);
      errorMessage.value = 'Der opstod en fejl. Kontroller din e-mailadresse og prøv igen.';
    }
  };

  const loadUserEmail = () => {
    const user = auth.currentUser;
    if (user) {
      resetEmail.value = user.email || '';
    }
  };

  onMounted(loadUserEmail);

  return {
    resetEmail,
    errorMessage,
    successMessage,
    resetPassword,
    loadUserEmail
  };
}
