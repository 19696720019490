<template>
  <main class="main-content">
    <div class="header pb-0 d-flex align-items-center">
      <div class="search-container d-flex align-items-center">
        <input 
          v-model="searchQuery" 
          type="text" 
          placeholder="Søg efter biler..." 
          class="form-control search-input"
        >
        <span class="car-count ml-3">{{ filteredCars.length }} biler</span>
      </div>
    </div>

    <!-- Biler Galleri -->
    <div v-if="filteredCars && filteredCars.length > 0" class="card-body">
      <div class="row g-4">
        <div v-for="car in filteredCars" :key="car.id" class="col-auto">
          <div 
            class="card car-card"
            :class="{ 'selected': selectedCar === car.id }"
            @click="handleSelectCar(car)"
          >
            <div class="card-body">
              <div class="car-image">
                <img
                  :src="car.attachments.image_1"
                  :alt="car.headline"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="card-footer">
              <h6 class="mb-0 text-truncate">{{ car.headline }}</h6>
              <small class="text-muted">ID: {{ car.id }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p v-else-if="cars && cars.length === 0" class="text-center mt-4">
      Ingen biler fundet
    </p>

    <p v-if="error" class="text-center mt-4 text-danger">
      {{ error }}
    </p>
  </main>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";
import { collection, getDocs, doc, collectionGroup } from 'firebase/firestore';
import { db, auth } from '@/firebase';
import { useStore } from 'vuex';
import { useUserContext } from '@/composables/useUserContext';

const store = useStore();
const { contextUserId, canAccessOtherUsers } = useUserContext();
const cars = ref([]);
const error = ref(null);
const selectedCar = ref(null);
const searchQuery = ref('');

const emit = defineEmits(['carSelected']);

async function fetchCarsFromFirestore() {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('Ingen bruger er logget ind');
    }

    let carsQuery;

    if (canAccessOtherUsers.value) {
      if (contextUserId.value !== store.state.activeUser?.id) {
        const userDocRef = doc(db, 'users', contextUserId.value);
        carsQuery = collection(userDocRef, 'dealerCars');
      } else {
        carsQuery = collectionGroup(db, 'dealerCars');
      }
    } else {
      const userDocRef = doc(db, 'users', contextUserId.value);
      carsQuery = collection(userDocRef, 'dealerCars');
    }

    const carsSnapshot = await getDocs(carsQuery);
    cars.value = carsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      dealerId: doc.ref.parent.parent?.id || 'Unknown'
    }));

    error.value = null;
  } catch (e) {
    console.error("Fejl ved hentning af biler fra Firestore:", e);
    error.value = "Der opstod en fejl ved hentning af biler. Prøv venligst igen senere.";
    cars.value = [];
  }
}

watch(contextUserId, () => {
  fetchCarsFromFirestore();
});

const filteredCars = computed(() => {
  let carsList = cars.value;

  if (searchQuery.value) {
    const searchText = searchQuery.value.toLowerCase();
    carsList = carsList.filter(car => {
      const searchableFields = {
        mærke: car.fields?.Mærke,
        model: car.fields?.Model,
        variant: car.fields?.Variant,
        headline: car.headline,
        description: car.description,
        brændstof: car.fields?.Brændstoftype,
        kilometer: car.fields?.Kilometer,
        årgang: car.fields?.Årgang,
        udstyr: car.fields?.Udstyr
      };
      
      return Object.values(searchableFields).some(value => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchText);
        }
        if (typeof value === 'number') {
          return value.toString().includes(searchText);
        }
        return false;
      });
    });
  }

  // Fjern dubletter og ugyldige biler
  const uniqueCars = carsList.reduce((acc, current) => {
    if (!current || !current.id || !current.headline) return acc;
    
    const x = acc.find(item => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  // Sikker sortering med fallback til tom streng
  return uniqueCars.sort((a, b) => 
    (a.headline || '').localeCompare(b.headline || '')
  );
});

function handleSelectCar(car) {
  selectedCar.value = car.id;
  emit('carSelected', car);
}

onMounted(() => {
  fetchCarsFromFirestore();
});
</script>

<style scoped>
.car-card {
width: 20rem;
height: 19rem;
cursor: pointer;
transition: all 0.3s ease;
background: rgba(255, 255, 255, 0.05);
backdrop-filter: blur(10px);
border: 1px solid rgba(255, 255, 255, 0.1);
box-shadow: 
  0 8px 32px 0 rgba(31, 38, 135, 0.37),
  0 0 0 1px rgba(255, 255, 255, 0.05);
display: flex;
flex-direction: column;
overflow: hidden;
border-radius: 0.375rem;
transform: translateZ(0);
will-change: transform;
}

.car-card:hover {
transform: scale(1.05);
box-shadow: 0 4px 20px 0 rgba(94, 114, 228, 0.3);
}

.car-card.selected {
border: 2px solid #5e72e4;
box-shadow: 0 4px 20px 0 rgba(94, 114, 228, 0.3);
}

.card-body {
flex-grow: 1;
overflow: hidden;
background-color: transparent; /* Tilføj denne linje */
}

.car-image {
height: 100%;
width: 100%;
border-radius: 0.375rem;
overflow: hidden;
}

.car-image img {
height: 100%;
width: 100%;
object-fit: cover;
}

.card-footer {
background: rgba(22, 22, 22, 0.8);
backdrop-filter: blur(8px);
border-top: none;
padding: 0.5rem;
height: auto;
display: flex;
flex-direction: column;
align-items: flex-start;
}

.card-footer h6 {
color: #ffffff;
font-size: 0.9rem;
margin: 0;
line-height: 1.2;
width: 100%;
}

.card-footer small {
color: #aaaaaa; /* Lysegrå farve til ID-teksten */
font-size: 0.75rem;
margin-top: 0.25rem; /* Lille afstand mellem overskrift og ID */
}

/* Fjern denne stil */
.page-background {
background-color: #1e1e1e; /* Meget mørk baggrund for hele siden */
}

/* Opdater denne stil */
.card-wrapper > .card {
background-color: transparent; /* Ændret fra #1e1e1e til transparent */
border: none;
}

.card-header {
display: flex;
justify-content: space-between;
align-items: center;
padding: 1rem;
}

.card-header h6 {
color: #ffffff; /* Hvid tekst for overskriften */
}

.search-container {
width: 100%;
}

.search-input {
width: 25%;
background: rgba(255, 255, 255, 0.07);
backdrop-filter: blur(8px);
border: 1px solid rgba(255, 255, 255, 0.1);
color: white;
border-radius: 12px;
padding: 12px 20px;
transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-input::placeholder {
color: #aaa;
}

.search-input:focus {
background: rgba(255, 255, 255, 0.1);
border-color: #5e72e4;
box-shadow: 0 0 0 0.2rem rgba(94, 114, 228, 0.25);
outline: none;
}

.car-count {
color: #fff;
font-size: 0.875rem;
margin-left: 1rem;
}

.card .card-body {
padding: 0.5rem; /* Ændret fra standard 1.5rem til 0.5rem */
}
</style>