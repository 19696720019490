// Logger levels
const LEVELS = {
    ERROR: 'error',
    WARN: 'warn',
    INFO: 'info',
    DEBUG: 'debug'
  };
  
  // Kun log i development miljø
  const isDevelopment = process.env.NODE_ENV === 'development';
  
  // Firebase-relaterede mønstre der skal ignoreres
  const ignorePatterns = [
    /Fetch (finished|failed) loading/,
    /webchannel_blob/,
    /firestore\.googleapis\.com/,
    /Profilbillede URL ændret/,
    /google\.firestore\.v1\.Firestore\/Listen/,
    /SID=/,
    /AID=/,
    /CI=/,
    /TYPE=xmlhttp/,
    /RID=/,
    /gsessionid=/,
    /channel\?VER=/,
    /webchannel_blob_es2018\.js/,
    /projects%2Ftoft-d4f39%2Fdatabases/
  ];
  
  // Helper funktion til at tjekke om en besked skal ignoreres
  const shouldIgnoreMessage = (message) => {
    if (typeof message !== 'string') return false;
    
    // Tjek først for webchannel_blob specifikt
    if (message.includes('webchannel_blob_es2018.js')) return true;
    
    // Tjek derefter alle andre mønstre
    return ignorePatterns.some(pattern => pattern.test(message)) || 
           message.includes('Firestore') ||
           message.includes('Listen/channel');
  };
  
  const logger = {
    error: (message, ...args) => {
      if (isDevelopment && !shouldIgnoreMessage(message)) {
        console.error(message, ...args);
      }
    },
    
    warn: (message, ...args) => {
      if (isDevelopment && !shouldIgnoreMessage(message)) {
        console.warn(message, ...args);
      }
    },
    
    info: (message, ...args) => {
      if (isDevelopment && !shouldIgnoreMessage(message)) {
        console.info(message, ...args);
      }
    },
    
    debug: (message, ...args) => {
      if (isDevelopment && !shouldIgnoreMessage(message)) {
        console.debug(message, ...args);
      }
    }
  };
  
  export default logger; 