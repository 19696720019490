import { db } from '@/firebase';
import { collection, query, where, getDocs, deleteDoc, doc, writeBatch } from 'firebase/firestore';

export async function deleteDesignAndReferences(designId, designUuid) {
  const batch = writeBatch(db);
  
  try {
    // Arrays af collections vi skal tjekke for referencer
    const collectionsToCheck = ['autoposts', 'schedules', 'templates', 'campaigns'];
    const referencesToDelete = [];

    // Find alle referencer til dette design
    for (const collectionName of collectionsToCheck) {
      const q = query(
        collection(db, collectionName),
        where('designUuid', '==', designUuid)
      );
      
      const snapshot = await getDocs(q);
      
      snapshot.docs.forEach(document => {
        referencesToDelete.push({
          collection: collectionName,
          docId: document.id
        });
      });
    }

    // Slet selve designet
    batch.delete(doc(db, 'designImages', designId));

    // Slet eller opdater alle referencer
    for (const ref of referencesToDelete) {
      batch.delete(doc(db, ref.collection, ref.docId));
    }

    // Udfør alle sletninger i én transaktion
    await batch.commit();

    return {
      success: true,
      deletedReferences: referencesToDelete.length
    };
  } catch (error) {
    console.error('Fejl ved sletning af design og referencer:', error);
    throw new Error('Kunne ikke slette design og referencer');
  }
} 