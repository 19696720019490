<script setup>
import { ref, onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "@/firebase";
import { useRouter } from "vue-router";

// Argon components
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const ROLE_ADMIN = 1;
const ROLE_USER = 3;
const ROLE_MANAGER = 2

// Debugging to check if localStorage works
console.log("Initial email in localStorage:", localStorage.getItem("email"));

const email = ref(localStorage.getItem("email") || ""); // Pre-fill email from localStorage if available
const password = ref(""); // Password will not be saved for security reasons
const rememberMe = ref(false); // Remember Me toggle
const errorMessage = ref("");
const isLoading = ref(false); // Loading state for sign-in button
const router = useRouter();
const store = useStore();

// Check if user is already logged in when component mounts
onBeforeMount(() => {
  store.state.showConfig = false
  store.state.hideConfigButton = true
  store.state.showSidenav = true
  store.state.showFooter = false
  store.state.setImpersonatedUser = null
  const body = document.getElementsByTagName("body")[0];
  body.classList.remove("bg-gray-100");
  body.classList.add("bg-black");

  // Check if user is authenticated
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // Fetch user data from Firestore
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        // Dispatch setCurrentUser handling
        store.dispatch("setCurrentUser", { ...userData, id: user.uid, isOnline: true });

        console.log("User role:", userData.role); // Debugging

        // Redirect user to appropriate page
        if (userData.email === "toft@fliva.com") {
          router.push("/pages/pickdesigns");
        } else if (userData.role === ROLE_ADMIN || userData.role === ROLE_MANAGER) {
          router.push("/pages/tables");
        } else if (userData.role === ROLE_USER) {
          router.push("/profile"); // Omdiriger til /profile for role 3
        } else {
          router.push("/default-route"); // Replace with your default route
        }
      }
    }
  });
});

onBeforeUnmount(() => {
  store.state.showConfig = false
  store.state.showSidenav = true
  store.state.showFooter = false
  store.state.hideConfigButton = true
  store.state.setImpersonatedUser = null

  const body = document.getElementsByTagName("body")[0];
  body.classList.remove("bg-black");
  body.classList.add("bg-gray-100");
});

const signIn = async () => {
  isLoading.value = true;
  try {
    // Set persistence
    const persistenceType = rememberMe.value
      ? browserLocalPersistence
      : browserSessionPersistence;
    await setPersistence(auth, persistenceType);

    // Sign in
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email.value,
      password.value,
    );
    const user = userCredential.user;
    console.log("User signed in with UID:", user.uid);

    // Fetch user data
    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      await updateDoc(userDocRef, { isOnline: true });
      // Dispatch setCurrentUser handling
      store.dispatch("setCurrentUser", { ...userData, id: user.uid, isOnline: true });

      console.log("User role:", userData.role); // Debugging

      // Redirect user based on role
      if (userData.email === "toft@fliva.com") {
        router.push("/pages/pickdesigns");
      } else if (userData.role === ROLE_ADMIN) {
        router.push("/pages/tables");
      } else if (userData.role === ROLE_USER) {
        router.push("/profile"); // Omdiriger til /profile for role 3
      } else {
        router.push("/default-route"); // Replace with your default route
      }
    } else {
      console.error("User document not found");
      errorMessage.value = "User data not found. Please contact support.";
    }
  } catch (error) {
    console.error("Sign-in error:", error);
    errorMessage.value = error.message;
  } finally {
    isLoading.value = false;
  }
};

// Tilføj scroll funktion
const scrollToLogin = () => {
  const loginSection = document.querySelector('.card-plain');
  if (loginSection) {
    loginSection.scrollIntoView({ 
      behavior: 'smooth',
      block: 'center'
    });
  }
};
</script>

<template>
  <!-- Velkomst sektion - nu med egen sektion over login -->
  <section class="welcome-section">
    <div class="welcome-container">
      <div class="text-center">
        <h1 class="type">MakeMore – Where Innovation Meets Dedication!</h1>
        <!-- Tilføj Sign in tekst og scroll-pil som én klikbar enhed -->
        <div class="scroll-container" @click="scrollToLogin">
          <p class="sign-in-text bounce">Sign in</p>
          <div class="scroll-arrow">
            <i class="fas fa-chevron-down bounce"></i>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Login sektion - starter efter velkomst -->
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          :dark-mode="true"
          is-btn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain bg-black text-white">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder text-pink-300">Sign In</h4>
                  <p class="mb-0 text-black-400">
                    Enter your email and password to sign in
                  </p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="signIn">
                    <div class="mb-3">
                      <argon-input
                        id="email"
                        v-model="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        size="lg"
                        aria-label="Email"
                        class="text-white placeholder-gray-500 bg-black border-gray-600 focus:ring-pink-500"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="password"
                        v-model="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                        aria-label="Password"
                        class="text-white placeholder-gray-500 bg-black border-gray-600 focus:ring-pink-500"
                      />
                    </div>
                    <argon-switch
                      id="rememberMe"
                      v-model="rememberMe"
                      name="remember-me"
                      class="text-white"
                    >
                      Remember me
                    </argon-switch>

                    <div v-if="errorMessage" class="text-red-500 text-sm">
                      {{ errorMessage }}
                    </div>

                    <div class="text-center">
                      <argon-button
                        :disabled="isLoading"
                        type="submit"
                        class="mt-4 bg-gradient-to-r from-purple-500 to-pink-500 text-white py-2 rounded-full hover:opacity-90 focus:ring-pink-500"
                        variant="gradient"
                        color="success"
                        full-width
                        size="lg"
                      >
                        <span v-if="!isLoading">Sign in</span>
                        <span v-else>Signing in...</span>
                      </argon-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
:root {
  --primary-color: #c8dc71;
  --gradient-color: linear-gradient(180deg, #d2ff00 0%, #00d2ff 100%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Space Grotesk", sans-serif;
  background-color: #16181b;
}

.welcome-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16181b;
  margin-bottom: 2rem; /* Tilføjer afstand til login-sektionen */
}

.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

h1.type {
  color: #dbd56e;
  background: linear-gradient(25deg, #eee, #333);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid;
  animation: type 1.5s steps(90) 0.8s 1 normal both, cursor 1s step-end infinite;
}

@keyframes type {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes cursor {
  50% {
    border-color: transparent;
  }
}

/* Eksisterende styles */
.custom-button {
  margin-bottom: 20px !important;
}

.custom-card-header {
  margin-bottom: 15px !important;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 10px;
}

.search-bar {
  margin-top: 15px;
}

.card-header {
  padding: 1.5rem 1.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #fff;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #525f7f;
  background-color: transparent;
}

.table th,
.table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}

.search-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  max-width: 400px;
  padding: 0.5rem;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
}

.scroll-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  cursor: pointer;
}

.sign-in-text {
  color: #d5d5d5;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.scroll-arrow {
  color: #c5c5c5;
  font-size: 2rem;
}

.scroll-container:hover .sign-in-text,
.scroll-container:hover .scroll-arrow {
  color: #fff;
}

/* Opdateret bounce animation der bruges på både tekst og pil */
.bounce {
  animation: bounce 2s infinite;
  display: inline-block; /* Sikrer at animationen virker korrekt */
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(-10px);
  }
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,.5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
  margin-bottom: 30px;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

.login-box button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
}

.login-box button:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
              0 0 25px #03e9f4,
              0 0 50px #03e9f4,
              0 0 100px #03e9f4;
}

.login-box button span {
  position: absolute;
  display: block;
}

.login-box button span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.login-box button span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.login-box button span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.login-box button span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

.remember-me {
  color: #fff;
  margin-bottom: 20px;
}

.error-message {
  color: #ff4444;
  margin-top: 10px;
  text-align: center;
}
</style>
