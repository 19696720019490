<!-- src/components/ArgonSelect.vue -->
<template>
  <div class="argon-select-container">
    <input
      v-model="searchQuery"
      type="text"
      :placeholder="placeholder"
      class="argon-select-input"
      @input="onInput"
    />
    <ul v-if="showOptions" class="argon-select-options">
      <li
        v-for="option in filteredOptions"
        :key="option.value"
        class="argon-select-option"
        @click="selectOption(option)"
      >
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";

// Assign defineProps to a constant
const props = defineProps({
  modelValue: {
    type: [Array, Object],
    default: () => [],
  },
  options: {
    type: Array,
    required: true,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "Select...",
  },
});

// Define emits
const emit = defineEmits(["update:modelValue", "search"]);

const searchQuery = ref("");
const showOptions = ref(false);

// Computed property for filtering options based on search query
const filteredOptions = computed(() => {
  if (!searchQuery.value) return props.options;
  return props.options.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.value.toLowerCase()),
  );
});

// Function to handle option selection
const selectOption = (option) => {
  if (props.multiple) {
    const selected = [...props.modelValue];
    if (!selected.find((item) => item.value === option.value)) {
      selected.push(option);
    } else {
      const index = selected.findIndex((item) => item.value === option.value);
      selected.splice(index, 1);
    }
    emit("update:modelValue", selected);
  } else {
    emit("update:modelValue", option);
    showOptions.value = false;
  }
};

// Function to handle input in the search box
const onInput = () => {
  showOptions.value = true;
  emit("search", searchQuery.value);
};

// Close options when clicking outside
const handleClickOutside = (event) => {
  if (
    !event.target.closest(".argon-select-input") &&
    !event.target.closest(".argon-select-options")
  ) {
    showOptions.value = false;
  }
};

window.addEventListener("click", handleClickOutside);

// Cleanup watcher
watch(
  () => showOptions.value,
  (newVal) => {
    if (!newVal) {
      searchQuery.value = "";
    }
  },
);
</script>

<style scoped>
.argon-select-container {
  position: relative;
  width: 100%;
}

.argon-select-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.argon-select-options {
  list-style: none;
  padding: 0;
  margin: 4px 0 0 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.argon-select-option {
  padding: 8px;
  cursor: pointer;
}

.argon-select-option:hover {
  background-color: #f0f0f0;
}
</style>
