<script setup>
import { ref, computed, reactive, watch } from "vue";
import { auth, db } from "@/firebase";
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useStore } from "vuex";
import axios from "axios";

const store = useStore();

const emit = defineEmits(["close"]);

const cvrInput = ref("");
const searchResult = ref(null);
const message = ref(null);
const isLoading = ref(false);
const showManualForm = ref(false);

const isAutoChainUser = computed(() => {
  return store.state.activeUser.userType === "AutoChain";
});

const showDealerId = computed(() => {
  return isAutoChainUser.value && manualCompany.companyType === "auto";
});

watch(() => searchResult.value, (newValue) => {
  if (newValue && isAutoChainUser.value) {
    manualCompany.companyType = "auto";
  }
});

const manualCompany = reactive({
  name: "",
  address: "",
  zipcode: "",
  city: "",
  phone: "",
  email: "",
  vat: "",
  dealerId: "",
  companyType: "auto",
});

const fetchCompanyData = async () => {
  if (!cvrInput.value) {
    message.value = { type: "error", text: "Indtast venligst et CVR-nummer." };
    return;
  }

  isLoading.value = true;
  try {
    const response = await fetch(
      `https://cvrapi.dk/api?country=dk&search=${cvrInput.value}`,
      {
        headers: {
          "User-Agent": "MyAppName",
        },
      },
    );

    if (response.ok) {
      const data = await response.json();
      console.log("Virksomhedsdata:", data);
      searchResult.value = data;
      
      // Udfyld manualCompany med data fra søgningen
      manualCompany.name = data.name || "";
      manualCompany.address = data.address || "";
      manualCompany.zipcode = data.zipcode || "";
      manualCompany.city = data.city || "";
      manualCompany.phone = data.phone || "";
      manualCompany.email = data.email || "";
      manualCompany.vat = data.vat || "";
      
      if (isAutoChainUser.value) {
        manualCompany.companyType = "auto";
      }
      
      message.value = null;
      showManualForm.value = true; // Vis den manuelle formular efter søgning
    } else {
      message.value = {
        type: "error",
        text: "Fejl ved hentning af virksomhedsdata.",
      };
    }
  } catch (error) {
    console.error("Error:", error);
    message.value = { type: "error", text: "Der opstod en fejl ved søgning." };
  } finally {
    isLoading.value = false;
  }
};

const handleAddCompany = async (isManual = false) => {
  const companyData = isManual ? manualCompany : searchResult.value;

  if (companyData) {
    isLoading.value = true;
    const { name, address, zipcode, city, phone, email, vat, dealerId, companyType } = companyData;
    const fullAddress = `${address}, ${zipcode} ${city}`;
    const defaultEmail = email || `${name.replace(/\s+/g, "")}@example.com`;
    const password = "123456789"; // Midlertidig adgangskode

    if (!email && !isManual) {
      message.value = { type: "error", text: "E-mail er påkrævet. Tilføj venligst manuelt." };
      isLoading.value = false;
      return;
    }

    try {
      const functionUrl = "https://us-central1-toft-d4f39.cloudfunctions.net/addUserDataToFirestore";

      const response = await axios.post(functionUrl, {
        email: defaultEmail,
        password: password
      });

      const uid = response.data.data.uid;

      const userData = {
        name: name,
        address: fullAddress,
        phone: phone,
        email: defaultEmail,
        role: 3,
        isOnline: false,
        cvr: vat,
        createdBy: store.state.activeUser.id,
      };

      if (isAutoChainUser.value) {
        userData.companyType = companyType || "auto";
        userData.userType = "dealer";
        if (companyType === "auto") {
          userData.dealerId = dealerId || null;
        }
      }

      await setDoc(doc(db, "users", uid), userData);

      const companyDoc = await addDoc(collection(db, "companies"), {
        name: name,
        address: fullAddress,
        phone: phone,
        email: email || "Ikke oplyst",
        cvr: vat,
        userId: uid,
      });

      await setDoc(
        doc(db, "users", uid),
        { companyId: companyDoc.id },
        { merge: true },
      );

      console.log(
        "Bruger og virksomhed oprettet og tilføjet til Firestore:",
        name,
      );
      message.value = {
        type: "success",
        text: `Virksomhed "${name}" er blevet tilføjet succesfuldt.`,
      };
      cvrInput.value = "";
      searchResult.value = null;

      // Vent i 3 sekunder før modalen lukkes
      setTimeout(() => {
        emit("close");
      }, 3000);
    } catch (error) {
      console.error("Error adding company to Firebase:", error);
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);
      message.value = {
        type: "error",
        text: `Fejl ved tilføjelse af virksomhed: ${error.message}`,
      };
    } finally {
      isLoading.value = false;
    }
  }
};

const closeModal = () => {
  emit("close");
};

const toggleManualForm = () => {
  showManualForm.value = !showManualForm.value;
};
</script>

<template>
  <div class="company-add-modal">
    <div class="modal-header">
      <h5 class="modal-title">Tilføj Virksomhed</h5>
      <button class="close-button" @click="closeModal">&times;</button>
    </div>
    <div class="modal-body">
      <form @submit.prevent="fetchCompanyData">
        <ArgonInput
          v-model="cvrInput"
          type="text"
          placeholder="CVR-nummer"
          aria-label="CVR-nummer"
        />
        <ArgonButton
          type="submit"
          full-width
          color="dark"
          variant="gradient"
          class="custom-button"
          :disabled="isLoading"
        >
          Søg virksomhed
        </ArgonButton>
      </form>

      <div class="mt-4 position-relative text-center">
        <p
          class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
        >
          eller
        </p>
      </div>

      <ArgonButton
        full-width
        color="info"
        variant="gradient"
        class="my-4 mb-2"
        @click="toggleManualForm"
      >
        {{ showManualForm ? 'Skjul manuel formular' : 'Vis manuel formular' }}
      </ArgonButton>

      <form v-if="showManualForm" class="mt-4" @submit.prevent="handleAddCompany(true)">
        <ArgonInput
          v-model="manualCompany.name"
          type="text"
          placeholder="Virksomhedens navn"
          aria-label="Virksomhedens navn"
        />
        <ArgonInput
          v-model="manualCompany.address"
          type="text"
          placeholder="Adresse"
          aria-label="Adresse"
        />
        <ArgonInput
          v-model="manualCompany.zipcode"
          type="text"
          placeholder="Postnummer"
          aria-label="Postnummer"
        />
        <ArgonInput
          v-model="manualCompany.city"
          type="text"
          placeholder="By"
          aria-label="By"
        />
        <ArgonInput
          v-model="manualCompany.phone"
          type="tel"
          placeholder="Telefon"
          aria-label="Telefon"
        />
        <ArgonInput
          v-model="manualCompany.email"
          type="email"
          placeholder="Email"
          aria-label="Email"
        />
        <ArgonInput
          v-model="manualCompany.vat"
          type="text"
          placeholder="CVR-nummer"
          aria-label="CVR-nummer"
        />
        
        <template v-if="isAutoChainUser">
          <div class="mb-3">
            <label class="form-label">Virksomhedstype:</label>
            <select v-model="manualCompany.companyType" class="form-select">
              <option value="auto">Auto</option>
              <option value="client">Klient</option>
            </select>
          </div>
          <ArgonInput
            v-if="showDealerId"
            v-model="manualCompany.dealerId"
            type="text"
            placeholder="Forhandler ID"
            aria-label="Forhandler ID"
          />
        </template>
        
        <ArgonButton
          type="submit"
          full-width
          color="dark"
          variant="gradient"
          class="my-4 mb-2"
          :disabled="isLoading"
        >
          Tilføj virksomhed
        </ArgonButton>
      </form>

      <div v-if="isLoading" class="loading-overlay">
        <div class="loading-spinner"></div>
      </div>

      <div
        v-if="message"
        :class="[
          'mt-4 text-center',
          message.type === 'error' ? 'text-danger' : 'text-success',
        ]"
      >
        {{ message.text }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.company-add-modal {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  position: relative;
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  animation: slideUp 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateZ(0);
  will-change: transform;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.text-sm {
  font-size: 0.875rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.text-center {
  text-align: center;
}

.text-secondary {
  color: #6c757d;
}

.bg-white {
  background-color: #ffffff;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.text-danger {
  color: #dc3545;
}

.text-success {
  color: #28a745;
}

.loading-overlay {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  animation: fadeIn 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

.form-label {
  color: #ffffff;
  margin-bottom: 0.5rem;
  display: block;
}

.form-select {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 12px 20px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-select:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
}
</style>
