<template>
    <div class="container-fluid">
      <!-- Navigation Tabs -->
      <div v-show="showNavTabs" class="row align-items-center" style="margin-top: -30px; margin-bottom: 20px;">
        <div class="col-lg-4 col-sm-8">
          <div class="nav-wrapper position-relative end-0">
            <ul class="nav nav-pills nav-fill p-1" role="tablist" style="background: rgb(255, 255, 255);">
              <li class="nav-item">
                <a 
                  role="tab" 
                  class="nav-link mb-0 px-0 py-1" 
                  :class="{ 'active': activeTab === 'edit' }"
                  @click="handleTabClick('edit')"
                >
                  Opret Autopost
                </a>
              </li>
              <li class="nav-item">
                <a 
                  role="tab" 
                  class="nav-link mb-0 px-0 py-1" 
                  :class="{ 'active': activeTab === 'list' }"
                  @click="handleTabClick('list')"
                >
                  Redigere
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
  
      <!-- Content Area -->
      <AutoPilot 
        v-if="activeTab === 'edit'"
        :editing-post="editingPost"
        @saved="handlePostSaved"
        @cancelled="activeTab = 'list'"
        @step-change="handleStepChange"
        ref="autoPilot"
      />
  
      <!-- AutoPosts List -->
      <div v-else class="card">
        <div class="card-header pb-0">
          <h4>Autoposts</h4>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Oprettet den</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Destination</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Aktiv</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Handlinger</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="post in autoPosts" :key="post.id">
                <td class="align-middle text-center">
                  <span class="text-xs">{{ post.status }}</span>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ formatDate(post.createdAt) }}
                  </p>
                </td>
                <td>
                  <div class="d-flex">
                    <i v-for="channel in post.channels" 
                       :key="channel"
                       :class="getChannelIcon(channel)"
                       class="fab fa-lg me-2">
                    </i>
                  </div>
                </td>
                <td>
                  <span class="text-xs">{{ getPostType(post) }}</span>
                </td>
                <td>
                  <button 
                    class="btn btn-sm"
                    :class="post.active ? 'bg-gradient-success' : 'bg-gradient-warning'"
                    @click="togglePostActive(post)"
                  >
                    {{ post.active ? 'Aktiv' : 'Inaktiv' }}
                  </button>
                </td>
                <td>
                  <button 
                    class="btn btn-danger btn-sm"
                    @click="deletePost(post)"
                  >
                    Slet
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  import { getFirestore, collection, getDocs, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore';
  import { getAuth } from 'firebase/auth';
  import AutoPilot from './AutoPilot.vue';
  
  const db = getFirestore();
  const activeTab = ref('edit');
  const currentStep = ref(1);
  const autoPosts = ref([]);
  const autoPilotRef = ref(null);
  
  // Computed property til at styre synligheden af navigation tabs
  const showNavTabs = computed(() => {
    return activeTab.value === 'list' || 
           (activeTab.value === 'edit' && currentStep.value === 1);
  });
  
  // Handler for tab clicks
  const handleTabClick = (tab) => {
    if (activeTab.value === 'edit' && currentStep.value > 1) {
      // Hvis vi er midt i oprettelsesprocessen, spørg om bekræftelse
      if (confirm('Er du sikker på at du vil forlade oprettelsen? Dine ændringer vil gå tabt.')) {
        activeTab.value = tab;
        currentStep.value = 1; // Reset step når vi skifter tab
      }
    } else {
      activeTab.value = tab;
    }
  };
  
  // Handler for step changes fra AutoPilot
  const handleStepChange = (step) => {
    currentStep.value = step;
  };
  
  // Hent autoposts
  const fetchAutoPosts = async () => {
    try {
      const auth = getAuth();
      const currentUserId = auth.currentUser?.uid;
      
      if (!currentUserId) return;
  
      const userRole = await getUserRole(currentUserId);
      
      if (userRole === 1 || userRole === 2) { // Admin eller SuperAdmin
        // Hent alle brugeres autoposts
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const allPosts = [];
        
        for (const userDoc of usersSnapshot.docs) {
          const userPosts = await getDocs(collection(db, 'users', userDoc.id, 'autoPosts'));
          allPosts.push(...userPosts.docs.map(doc => ({
            id: doc.id,
            userId: userDoc.id, // Tilføj userId til hvert post objekt
            ...doc.data()
          })));
        }
        
        autoPosts.value = allPosts;
      } else {
        // For almindelige brugere - hent kun egne autoposts
        const querySnapshot = await getDocs(collection(db, 'users', currentUserId, 'autoPosts'));
        autoPosts.value = querySnapshot.docs.map(doc => ({
          id: doc.id,
          userId: currentUserId,
          ...doc.data()
        }));
      }
    } catch (error) {
      console.error('Fejl ved hentning af autoposts:', error);
    }
  };
  
  // Toggle aktiv status
  const togglePostActive = async (post) => {
    try {
      const auth = getAuth();
      const currentUserId = auth.currentUser?.uid;
      
      if (!currentUserId) return;
  
      const userRole = await getUserRole(currentUserId);
      const targetUserId = post.userId || currentUserId;
  
      if (userRole === 1 || userRole === 2 || currentUserId === targetUserId) {
        const postRef = doc(db, 'users', targetUserId, 'autoPosts', post.id);
        await updateDoc(postRef, {
          active: !post.active
        });
        
        post.active = !post.active;
      } else {
        throw new Error('Ingen tilladelse til at ændre denne autopost');
      }
    } catch (error) {
      console.error('Fejl ved opdatering af status:', error);
    }
  };
  
  // Slet autopost
  const deletePost = async (post) => {
    if (!confirm('Er du sikker på, at du vil slette denne autopost?')) return;
    
    try {
      const auth = getAuth();
      const currentUserId = auth.currentUser?.uid;
      
      if (!currentUserId) return;
  
      const userRole = await getUserRole(currentUserId);
      const targetUserId = post.userId || currentUserId;
  
      if (userRole === 1 || userRole === 2 || currentUserId === targetUserId) {
        await deleteDoc(doc(db, 'users', targetUserId, 'autoPosts', post.id));
        autoPosts.value = autoPosts.value.filter(p => p.id !== post.id);
      } else {
        throw new Error('Ingen tilladelse til at slette denne autopost');
      }
    } catch (error) {
      console.error('Fejl ved sletning af autopost:', error);
    }
  };
  
  // Håndter gem af post
  const handlePostSaved = () => {
    fetchAutoPosts();
    activeTab.value = 'list';
    currentStep.value = 1; // Reset step efter gemning
  };
  
  // Helper functions
  const getChannelIcon = (channel) => {
    const icons = {
      facebook: 'fa-facebook',
      instagram: 'fa-instagram'
    };
    return icons[channel.toLowerCase()] || '';
  };
  
  const getPostType = (post) => {
    // Implementer logik for at bestemme post type
    return 'Billede'; // Eller 'Video' baseret på post data
  };
  
  // Inline formatDate funktion
  const formatDate = (date) => {
    if (!date) return '';
    const dateObj = date.toDate ? date.toDate() : new Date(date);
    return dateObj.toLocaleDateString('da-DK', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  // Tilføj getUserRole funktion
  const getUserRole = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "users", userId));
      return userDoc.exists() ? userDoc.data().role : null;
    } catch (error) {
      console.error('Fejl ved hentning af brugerrolle:', error);
      return null;
    }
  };
  
  onMounted(() => {
    fetchAutoPosts();
  });
  </script>
  
  <style scoped>
  .nav-link {
    cursor: pointer;
    color: #344767;
    font-weight: 500;
    opacity: 0.7;
    transition: all 0.2s ease;
    padding: 5px 15px !important;
    font-size: 0.875rem;
  }
  
  .nav-link.active {
    background-color: #202124 !important;
    color: white !important;
    font-weight: 600;
    opacity: 1;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .nav-pills {
    background: white;
    border-radius: 0.5rem;
    padding: 3px !important;
    max-height: 40px;
  }
  
  .nav-item {
    margin: 0 3px;
  }
  
  .nav-item:first-child {
    margin-left: 0;
  }
  
  .nav-item:last-child {
    margin-right: 0;
  }
  
  .table th {
    font-size: 0.65rem;
    font-weight: 700;
  }
  
  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
  </style>