<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/firebase";
import ArgonButton from "@/components/ArgonButton.vue";

const route = useRoute();
const router = useRouter();
const user = ref(null);
const isAdminAccessing = ref(false);

onMounted(async () => {
  const userId = route.params.userId;
  const adminAccessingUser = localStorage.getItem("adminAccessingUser");

  isAdminAccessing.value = !!adminAccessingUser;

  const userDoc = await getDoc(doc(db, "users", userId));
  if (userDoc.exists()) {
    user.value = { id: userDoc.id, ...userDoc.data() };
  } else {
    console.error("Bruger ikke fundet");
    router.push({ name: "Dashboard" });
  }
});

const exitAdminAccess = () => {
  localStorage.removeItem("adminAccessingUser");
  router.push({ name: "Dashboard" });
};

// Her kan du tilføje flere metoder for at håndtere brugerspecifikke handlinger
const updateUserProfile = () => {
  // Implementer logik for at opdatere brugerprofilen
  console.log("Opdaterer brugerprofil");
};

const sendMessage = () => {
  // Implementer logik for at sende en besked
  console.log("Sender besked");
};

// Tilføj flere brugerspecifikke metoder efter behov
</script>

<template>
  <div v-if="user" class="user-profile">
    <h1>{{ user.name }}'s Profil</h1>
    <div class="profile-details">
      <p><strong>Email:</strong> {{ user.email }}</p>
      <p><strong>Rolle:</strong> {{ user.role }}</p>
      <!-- Tilføj flere brugerdetaljer her -->
    </div>

    <div class="user-actions">
      <h2>Brugerhandlinger</h2>
      <argon-button color="primary" class="mb-2" @click="updateUserProfile">
        Opdater profil
      </argon-button>
      <argon-button color="info" class="mb-2" @click="sendMessage">
        Send besked
      </argon-button>
      <!-- Tilføj flere brugerspecifikke handlinger her -->
    </div>

    <div v-if="isAdminAccessing" class="admin-actions">
      <argon-button color="warning" class="mt-4" @click="exitAdminAccess">
        Afslut brugeradgang
      </argon-button>
    </div>
  </div>
</template>

<style scoped>
.user-profile {
  padding: 20px;
}

.profile-details,
.user-actions,
.admin-actions {
  margin-top: 20px;
}
</style>
