// src/services/uploadService.js
import { storage } from "../firebase.js"; // Justér stien efter behov
import {
  ref as firebaseStorageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getAuth } from "firebase/auth";

/**
 * Funktion til at uploade en fil til Firebase Storage og returnere download-URL'en.
 * @param {File} file - Filen der skal uploades.
 * @param {string} folder - Mappen i Firebase Storage hvor filen skal gemmes.
 * @param {function} onProgress - Callback-funktion til at rapportere uploadfremskridtet.
 * @returns {Promise<string>} - En promise der resolver til download-URL'en.
 */
export const uploadFile = async (file, folder, onProgress) => {
  try {
    console.log(`Starter upload af fil: ${file.name}`);
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('Ingen bruger er logget ind');
    }

    const storageRef = firebaseStorageRef(storage, `${folder}/${user.uid}/${Date.now()}_${file.name}`);
    console.log(`Storage reference oprettet: ${storageRef.fullPath}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload af ${file.name} er ${progress}% færdig.`);
        if (onProgress) {
          onProgress(progress);
        }
      }, 
      (error) => {
        console.error(`Fejl under upload af ${file.name}:`, error);
        throw error;
      }
    );

    console.log(`Venter på at upload af ${file.name} færdiggøres...`);
    const snapshot = await uploadTask;
    console.log(`Upload af ${file.name} er færdig.`);

    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log(`Download URL for ${file.name}: ${downloadURL}`);
    return downloadURL;
  } catch (error) {
    console.error(`Fejl ved upload af fil ${file.name}:`, error);
    throw error;
  }
};
