<template>
  <div v-if="show" class="position-fixed top-0 end-0 p-3" style="z-index: 1050;">
    <argon-snackbar
      :title="title"
      :date="date"
      :description="message"
      :icon="{ component: 'ni ni-bell-55', color: 'white' }"
      :color="color"
      @close="closeHandler"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";

const props = defineProps({
  message: {
    type: String,
    default: ''
  },
  show: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: 'Notifikation'
  },
  color: {
    type: String,
    default: 'success'
  }
});

const date = ref('Nu');

const closeHandler = () => {
  emit('update:show', false);
};

const emit = defineEmits(['update:show']);

watch(() => props.show, (newValue) => {
  if (newValue) {
    date.value = new Date().toLocaleTimeString();
  }
});
</script>

<style scoped>
.position-fixed {
  top: 20px !important;
}
</style>