<template>
  <div class="modal-overlay" v-if="isVisible">
    <div class="modal-container">
      <div class="modal-header">
        <h3>{{ isEditing ? 'Rediger Design' : 'Opret Design' }}</h3>
        <argon-button @click="closeModal" class="close-button" variant="text" color="secondary">&times;</argon-button>
      </div>
      <form @submit.prevent="saveDesign" class="modal-form">
        <div class="form-group">
          <label for="uuid">UUID</label>
          <input type="text" id="uuid" v-model="localDesign.uuid" placeholder="Indtast UUID">
        </div>
        
        <div class="form-group">
          <label>Type</label>
          <div class="button-group">
            <argon-button 
              :color="localDesign.type === 'statisk' ? 'primary' : 'secondary'" 
              @click.prevent="localDesign.type = 'statisk'"
            >
              Statisk
            </argon-button>
            <argon-button 
              :color="localDesign.type === 'dynamisk' ? 'primary' : 'secondary'" 
              @click.prevent="localDesign.type = 'dynamisk'"
            >
              Dynamisk
            </argon-button>
          </div>
        </div>
        
        <div class="form-group">
          <label>Kanaler</label>
          <div class="checkbox-group">
            <label v-for="channel in channels" :key="channel">
              <input 
                type="checkbox" 
                :value="channel" 
                v-model="localDesign.channels"
              >
              {{ channel }}
            </label>
          </div>
        </div>
        
        <div class="form-group">
          <label>Format</label>
          <div class="checkbox-group">
            <label v-for="formatOption in formats" :key="formatOption">
              <input 
                type="checkbox" 
                :value="formatOption" 
                v-model="localDesign.format"
              >
              {{ formatOption }}
            </label>
          </div>
        </div>
        
        <div class="form-group">
          <label for="fileUpload">Upload Design</label>
          <input 
            type="file" 
            id="fileUpload" 
            @change="handleFileUpload" 
            accept="image/*,application/pdf"
          >
          <div v-if="uploadProgress" class="upload-progress">
            <span>Upload fremskridt: {{ uploadProgress.toFixed(2) }}%</span>
            <div class="progress-bar" :style="{ width: `${uploadProgress}%` }"></div>
          </div>
        </div>
        
        <div class="form-group">
          <label>Vælg Grupper</label>
          <div class="checkbox-group">
            <label v-for="group in designGroups" :key="group.id">
              <input 
                type="checkbox" 
                :value="group.name" 
                v-model="localDesign.groups"
              >
              {{ group.name }}
            </label>
          </div>
        </div>
        
        <div class="form-group switch-group">
          <label>Kan redigeres</label>
          <argon-switch 
            :checked="localDesign.canEdit"
            @change="toggleSetting('canEdit', $event)"
          />
        </div>
        
        <div class="form-group switch-group">
          <label>Er en PDF</label>
          <argon-switch 
            :checked="localDesign.isPDF"
            @change="toggleSetting('isPDF', $event)"
          />
        </div>
        
        <div class="form-group" v-if="localDesign.type === 'statisk'">
          <label>Emne</label>
          <div class="checkbox-group">
            <label v-for="topic in topics" :key="topic">
              <input 
                type="checkbox" 
                :value="topic" 
                v-model="localDesign.topics"
              >
              {{ topic }}
            </label>
          </div>
        </div>
        
        <div class="button-group">
          <argon-button @click="closeModal" color="secondary" full-width>Annuller</argon-button>
          <argon-button type="submit" color="success" full-width>{{ isEditing ? 'Opdater design' : 'Opret design' }}</argon-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, reactive } from 'vue';
import { collection, addDoc, updateDoc, doc, getDocs } from 'firebase/firestore';
import { db } from '@/firebase';
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { uploadFile } from "../../../services/uploadeService.js";

const props = defineProps({
  isVisible: Boolean,
  designToEdit: Object
});

const emit = defineEmits(['close', 'save']);

const isEditing = ref(false);
const localDesign = reactive({
  uuid: '',
  canEdit: false,
  isPDF: false,
  type: 'statisk',
  channels: [],
  format: [],
  file: null,
  groups: [],
  createdAt: null,
  updatedAt: null,
  topics: []
});

const designGroups = ref([]);

const channels = ['Facebook', 'Instagram', 'LinkedIn', 'Stories', 'Print'];
const formats = ['1:1', '16:9', '4:3', '4:5', '2:3', 'A4', 'A5'];

const uploadProgress = ref(null);

const topics = [
  'Nyhed', 'Ny bil på lager', 'Tilbud', 'Prisjustering', 'Dagens bil',
  'Ugens bil', 'Netop solgt', 'Eftertragtet model', 'Skarp pris'
];

onMounted(() => {
  fetchDesignGroups();
});

const fetchDesignGroups = async () => {
  const querySnapshot = await getDocs(collection(db, 'designGroup'));
  designGroups.value = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
};

const toggleSetting = async (settingKey, event) => {
  const settingValue = event.target.checked;
  console.log(`Toggling ${settingKey} to ${settingValue}`);

  localDesign[settingKey] = settingValue;
};

watch(() => props.designToEdit, (newValue) => {
  if (newValue) {
    Object.assign(localDesign, JSON.parse(JSON.stringify(newValue)));
    isEditing.value = true;
  } else {
    Object.assign(localDesign, {
      uuid: '',
      canEdit: false,
      isPDF: false,
      type: 'statisk',
      channels: [],
      format: [],
      file: null,
      groups: [],
      createdAt: null,
      updatedAt: null
    });
    isEditing.value = false;
  }
}, { immediate: true, deep: true });

const handleFileUpload = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  try {
    uploadProgress.value = 0;
    const downloadURL = await uploadFile(file, "designFiles", (progress) => {
      uploadProgress.value = progress;
    });
    localDesign.file = downloadURL;
  } catch (error) {
    console.error(`Fejl ved upload af fil ${file.name}:`, error);
    alert(`Fejl ved upload af fil ${file.name}: ${error.message}`);
  } finally {
    uploadProgress.value = null;
  }
};

const saveDesign = async () => {
  console.log('Design data før gemning:', localDesign);
  try {
    const designData = { ...localDesign };
    
    if (!isEditing.value) {
      designData.createdAt = new Date();
    }
    designData.updatedAt = new Date();

    Object.keys(designData).forEach(key => 
      designData[key] === undefined && delete designData[key]
    );

    emit('save', designData);
  } catch (error) {
    console.error('Fejl ved gemning af design:', error);
    alert('Der opstod en fejl ved gemning af designet. Prøv venligst igen.');
  }
};

const closeModal = () => {
  emit('close');
};

// Tilføj denne watch for at nulstille topics, når typen ændres
watch(() => localDesign.type, (newType) => {
  if (newType !== 'statisk') {
    localDesign.topics = [];
  }
});

</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  color: white;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
select,
.multiselect {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #3c3c3c;
  color: white;
}

.button-group {
  display: flex;
  gap: 10px;
}

.button-group button {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-group button.active {
  background-color: #5e72e4;
  color: white;
}

.upload-progress {
  margin-top: 10px;
}

.progress-bar {
  height: 4px;
  background-color: #5e72e4;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.cancel-button,
.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.submit-button {
  background-color: #2ecc71;
  color: white;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 5px;
}

.switch-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
