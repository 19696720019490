<template>
  <div class="post-cars-component card-body border-radius-lg p-3" :class="{ 'invisible-card': isInvisible }">
    <div v-if="currentStep === 1" class="content-container">
      <div class="row align-items-center">
        <div class="col-auto">
          <div class="gradient-text me-3">lav et opslag med en:</div>
        </div>
        <div class="col">
          <div class="form-container">
            <div class="select-button-group">
              <select id="choices-category" v-model="selectedStatus" class="form-control">
                <option value="" disabled>Vælg status</option>
                <option v-for="status in statusOptions" :key="status" :value="status">{{ status }}</option>
              </select>
              <button @click="handleNext" type="button" class="btn bg-gradient-success btn-md">Næste</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="currentStep === 2" class="content-container">
      <div class="d-flex justify-content-between align-items-center w-100 mb-4">
        <button @click="handleBack" class="btn btn-outline-secondary btn-md">
          <i class="fas fa-arrow-left mr-2"></i> Tilbage
        </button>
        <div class="gradient-text mb-0">Vælg eller søg efter en bil</div>
        <div style="width: 80px;"></div>
      </div>
      <SelectCar @car-selected="selectCar" />
    </div>

    <div v-else-if="currentStep === 3" class="content-container">
      <div class="d-flex justify-content-between align-items-center w-100 mb-4">
        <button @click="handleBack" class="btn btn-outline-secondary btn-md">
          <i class="fas fa-arrow-left mr-2"></i> Tilbage
        </button>
        <div class="gradient-text mb-0">Vælg kanal og type</div>
        <button @click="handleNext" class="btn btn-outline-secondary btn-md">
          Næste <i class="fas fa-arrow-right ml-2"></i>
        </button>
      </div>
      
      <div class="channel-type-container">
        <div class="channel-section">
          <h6 class="section-title">Kanal</h6>
          <div class="options-row">
            <div v-for="channel in channels" :key="channel.id" 
                 @click="toggleSelection(channel)"
                 class="option-item channel-option" 
                 :class="{ 'selected': channel.selected }">
              <div class="avatar">
                <img :src="channel.image" alt="channel image">
              </div>
            </div>
          </div>
        </div>
        
        <div class="type-section">
          <h6 class="section-title">Type</h6>
          <div class="options-row">
            <div v-for="type in types" :key="type.id" 
                 @click="toggleSelection(type)"
                 class="option-item media-type-option" 
                 :class="{ 'selected': type.selected }">
              <div class="avatar">
                <img :src="type.image" alt="type image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="currentStep === 4" class="content-container">
      <GetDesign
        :designTypes="designTypes"
        :designImages="designImages"
        :selectedStatus="selectedStatus"
        v-model:selectedDesign="selectedDesign"
        @back="handleBack"
        @next="handleNext"
      />
    </div>

    <div v-else-if="currentStep === 5" class="content-container">
      <div class="row align-items-center">
        <div class="col-auto">
          <div class="gradient-text me-3">Jeg vil gerne</div>
        </div>
        <div class="col">
          <div class="form-container">
            <div class="mb-4">
              <button @click="previewPost" type="button" class="btn bg-gradient-primary btn-sm w-50">Se preview</button>
            </div>
            <div class="option-wrapper mt-3">
              <div class="d-flex justify-content-between">
                <div class="option-container mb-3" @click="togglePublishNow" :class="{ 'selected': publishNow }">
                  <span class="form-label">Offentliggør opslag</span>
                </div>
                <div class="option-container mb-3" @click="togglePublishLater" :class="{ 'selected': publishLater }">
                  <span class="form-label">Planlæg opslag</span>
                </div>
              </div>
            </div>
            <div v-if="publishLater" class="mt-3">
              <input type="datetime-local" v-model="scheduledDateTime" class="form-control">
            </div>
            <div class="d-flex justify-content-between mt-4">
              <button @click="handleBack" type="button" class="btn btn-outline-secondary btn-md">Tilbage</button>
              <button @click="createPost" type="button" class="btn bg-gradient-success btn-md" :disabled="!isFormValid || isLoading">
                <span v-if="!isLoading">Opret</span>
                <span v-else class="loading-spinner"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Opdateret preview modal -->
    <div class="preview-modal" v-if="showPreview">
      <div class="preview-content">
        <div class="preview-image-container">
          <img :src="previewImageUrl" alt="Preview" class="preview-image">
        </div>
        <div class="preview-text-container">
          <textarea v-model="previewText" readonly class="preview-textarea"></textarea>
        </div>
        <div class="button-container">
          <button @click="shuffleText" class="shuffle-button">Ny tekst</button>
          <button @click="aiAssistedText" class="ai-assisted-button" :disabled="isLoading">
            <span v-if="!isLoading">AI-tekst</span>
            <span v-else class="loading-spinner"></span>
          </button>
          <button @click="savePreview" class="save-button">Gem</button>
          <button @click="closePreview" class="close-button">Luk</button>
        </div>
      </div>
    </div>

    <Notifications 
      :message="notificationMessage" 
      :show="showNotification"
      :title="notificationTitle"
      :color="notificationColor"
      @update:show="showNotification = $event"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import SelectCar from './SelectCar.vue';
import GetDesign from './getDesign.vue';
import { getFirestore, collection, getDocs, doc, getDoc, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { initializeApp, getApps } from 'firebase/app';
import { generateImage } from '@/utils/imageGenerator';
import { generateSocialMediaPost } from '@/services/socialMediaPostGenerator';
import axios from 'axios';
import { generatePostData } from '@/utils/postDataGenerator';
import Notifications from './Notifications.vue';
import { useUserContext } from '@/composables/useUserContext';

// Firebase konfiguration
const firebaseConfig = {
  // Din Firebase konfiguration her
};

// Tjek om appen allerede er initialiseret
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0]; // Brug den eksisterende app
}

const db = getFirestore(app);

const statusOptions = ref([
  'Nyhed', 'Ny bil på lager', 'Tilbud', 'Prisjustering', 'Dagens bil',
  'Ugens bil', 'Netop solgt', 'Eftertragtet model', 'Skarp pris'
]);

const selectedStatus = ref('');
const currentStep = ref(1);
const channels = ref([
  { id: 'facebook', image: 'https://firebasestorage.googleapis.com/v0/b/estate-f5e0f.appspot.com/o/designs%2Ffilters%2Fnew%2Ffacebook.png?alt=media', selected: false },
  { id: 'instagram', image: 'https://firebasestorage.googleapis.com/v0/b/estate-f5e0f.appspot.com/o/designs%2Ffilters%2Fnew%2Finstagram.png?alt=media', selected: false }
]);
const types = ref([
  { id: 'image', image: 'https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/car-image.png?alt=media&token=d365475f-1ad6-49d1-8ef3-97ebe4de5997', selected: false },
  { id: 'video', image: 'https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/car_video.png?alt=media&token=558137e2-2dd6-42c0-9b62-70eadd0a72a2', selected: false }
]);
const selectedCar = ref(null);

const designImages = ref([]);
const designTypes = ref([]);
const activeDesignType = ref('');

const selectedFormat = ref('');

const selectedDesign = ref(null);
const publishNow = ref(false);
const publishLater = ref(false);
const scheduledDateTime = ref('');

const togglePublishNow = () => {
  publishNow.value = !publishNow.value;
  if (publishNow.value) {
    publishLater.value = false;
    scheduledDateTime.value = '';
  }
};

const togglePublishLater = () => {
  publishLater.value = !publishLater.value;
  if (publishLater.value) {
    publishNow.value = false;
  }
};

const isFormValid = computed(() => {
  return (publishNow.value || (publishLater.value && scheduledDateTime.value)) &&
         selectedStatus.value &&
         selectedCar.value &&
         selectedDesign.value &&
         channels.value.some(channel => channel.selected) &&
         types.value.some(type => type.selected);
});

const fetchDesignImages = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'designImages'));
    designImages.value = querySnapshot.docs.map(doc => doc.data());
    console.log('Design Images:', designImages.value);
    
    // Udtræk unikke designtyper fra 'groups' feltet i de hentede designs
    const types = new Set(designImages.value.flatMap(design => design.groups || []).filter(Boolean));
    designTypes.value = Array.from(types);
    
    // Sæt den aktive designtype til den første i listen, hvis der er nogen
    if (designTypes.value.length > 0) {
      activeDesignType.value = designTypes.value[0];
    }
  } catch (error) {
    console.error('Fejl ved hentning af design billeder:', error);
  }
};

const logSelections = () => {
  console.log(`Status: ${selectedStatus.value} (choices - step 1)`);
  console.log(`Car: ${selectedCar.value ? selectedCar.value.id : 'Ingen bil valgt'} (id - step 2)`);
  const selectedChannels = channels.value.filter(channel => channel.selected).map(channel => channel.id).join(', ');
  console.log(`Kanal: ${selectedChannels} (kanal - step 3)`);
  const selectedTypes = types.value.filter(type => type.selected).map(type => type.id).join(', ');
  console.log(`Type: ${selectedTypes} (type - step 3)`);
};

const statusToGroupMap = {
  'Nyhed': 'Ny bil',
  'Ny bil på lager': 'Ny bil',
  'Tilbud': 'Tilbud',
  'Prisjustering': 'Prisjustering',
  'Dagens bil': 'Dagens bil',
  'Ugens bil': 'Ugens bil',
  'Netop solgt': 'Netop solgt',
  'Eftertragtet model': 'Eftertragtet model',
  'Skarp pris': 'Skarp pris'
};

const filteredDesignImages = computed(() => {
  logSelections(); // Log valg når designbilleder filtreres
  return designImages.value.filter(design => {
    const matchesDesignType = design.groups && design.groups.includes(activeDesignType.value);
    const matchesStatus = Array.isArray(design.emner) && design.emner.includes(selectedStatus.value);
    const matchesChannel = Array.isArray(design.channels) && design.channels.includes('Facebook');
    const matchesType = /\.(jpg|jpeg|png)$/i.test(design.file.split('?')[0]);

    console.log(`Design ID: ${design.uuid}, matchesDesignType: ${matchesDesignType}, matchesStatus: ${matchesStatus}, matchesChannel: ${matchesChannel}, matchesType: ${matchesType}`);

    return matchesDesignType && matchesStatus && matchesChannel && matchesType;
  });
});

const handleNext = () => {
  if (currentStep.value === 1 && selectedStatus.value) {
    currentStep.value = 2;
  } else if (currentStep.value === 2) {
    currentStep.value = 3;
  } else if (currentStep.value === 3) {
    currentStep.value = 4;
  } else if (currentStep.value === 4 && selectedDesign.value) {
    currentStep.value = 5;
  } else {
    alert('Vælg venligst de nødvendige muligheder før du fortsætter.');
  }
};

const handleBack = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
};

const selectCar = (car) => {
  selectedCar.value = car;
  console.log('Valgt bil:', car);
  console.log('Valgt status:', selectedStatus.value);
  // Gå automatisk til næste trin efter at en bil er valgt
  currentStep.value = 3;
};

const handleCarSelected = (car) => {
  console.log('Valgt bil:', car);
  // Implementer logik for hvad der skal ske når en bil er valgt
  // F.eks. gå til næste trin eller gemme den valgte bil
};

const toggleSelection = (design) => {
  design.selected = !design.selected;
};

const isInvisible = ref(false); // Sæt denne til true for at gøre card'et usynligt

const prepareStepData = (step) => {
  switch (step) {
    case 1:
      // Forbered data til step 2
      console.log('Forbereder data til step 2');
      // Her kan du tilføje logik til at hente eller forberede data til step 2
      break;
    case 2:
      // Forbered data til step 3
      console.log('Forbereder data til step 3');
      // Her kan du tilføje logik til at hente eller forberede data til step 3
      break;
    default:
      break;
  }
};

const selectDesignType = (designType) => {
  activeDesignType.value = designType;
};

const selectDesign = (design) => {
  selectedDesign.value = design;
};

const showPreview = ref(false);
const previewImageUrl = ref('');
const previewText = ref('');
const isLoading = ref(false);

const previewPost = async () => {
  console.log('Starter forhåndsvisning');
  if (!selectedCar.value || !selectedDesign.value) {
    console.log('Mangler bil eller design');
    alert('Vælg venligst en bil og et design før forhåndsvisning.');
    return;
  }

  isLoading.value = true;
  try {
    console.log('Genererer billede');
    const imageUrl = await generateImage(selectedCar.value, selectedDesign.value.uuid);
    if (imageUrl) {
      console.log('Billede genereret:', imageUrl);
      previewImageUrl.value = imageUrl;
      
      console.log('Genererer tekst');
      const postContent = generateSocialMediaPost(selectedCar.value, 'facebook', selectedStatus.value);
      previewText.value = postContent.text;
      console.log('Tekst genereret:', postContent.text);
      
      showPreview.value = true;
      console.log('Forhåndsvisning vist');
    } else {
      throw new Error('Kunne ikke generere billedet');
    }
  } catch (error) {
    console.error('Fejl ved generering af forhåndsvisning:', error);
    alert('Der opstod en fejl ved generering af forhåndsvisning: ' + error.message);
  } finally {
    isLoading.value = false;
  }
};

const shuffleText = () => {
  const postContent = generateSocialMediaPost(selectedCar.value, 'facebook', selectedStatus.value);
  previewText.value = postContent.text;
};

const aiAssistedText = async () => {
  isLoading.value = true;
  try {
    const messages = constructMessages('correct');
    const response = await correctText(messages);
    if (response && response.correctedText) {
      previewText.value = response.correctedText;
    } else {
      throw new Error('Uventet svarformat fra serveren');
    }
  } catch (error) {
    console.error('Fejl under AI-assisteret tekstgenerering:', error);
    alert(`Der opstod en fejl: ${error.message}`);
  } finally {
    isLoading.value = false;
  }
};

const correctText = async (messages) => {
  try {
    const functionUrl = "https://us-central1-toft-d4f39.cloudfunctions.net/correctText";
    const requestData = {
      action: 'correct',
      text: previewText.value,
      emne: `${selectedCar.value.maerke} ${selectedCar.value.model}`,
      platform: 'Facebook',
      messages: messages
    };

    const response = await axios.post(functionUrl, requestData, {
      headers: { 'Content-Type': 'application/json' }
    });
    
    if (response.data && response.data.correctedText) {
      return { correctedText: response.data.correctedText };
    } else if (response.data && response.data.error) {
      throw new Error(response.data.error);
    } else {
      throw new Error('Uventet svarformat fra serveren');
    }
  } catch (error) {
    console.error('Fejl under processering af tekst:', error);
    throw error;
  }
};

const constructMessages = (action) => {
  let messages = [
    {
      role: "system",
      content: `Du er en erfaren bilsælger og social media manager. Din opgave er at skabe fængende og salgsorienterede opslag til sociale medier om biler. 
                Regler:
                - Brug emojis naturligt og moderat
                - Hold teksten kort og fængende, max 280 tegn
                - Tilpas tonen og indholdet specifikt til den pågældende bil
                - Skab et opslag, der opfordrer til interaktion og klik
                - Undgå at bruge formateringsmærker som **, [], eller overskrifter
                - Skriv teksten, så den er klar til at blive kopieret direkte ind i et socialt medie opslag
                - Inkluder en kort, fængende beskrivelse af bilen og dens vigtigste features
                - Afslut med en opfordring til handling (CTA)`
    }
  ];

  if (action === 'correct') {
    const carFields = Object.entries(selectedCar.value.fields || {})
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');

    messages.push({
      role: "user",
      content: `Lav et fængende socialt medie opslag baseret på følgende information:
                Mærke og model: ${selectedCar.value.maerke} ${selectedCar.value.model}
                Overskrift: ${selectedCar.value.headline}
                Beskrivelse: ${selectedCar.value.description}
                År: ${selectedCar.value.aar}
                Pris: ${selectedCar.value.pris}
                Status: ${selectedStatus.value}
                
                Yderligere detaljer:
                ${carFields}
                
                Nuværende tekst (skal forbedres):
                ${previewText.value}
                
                Husk at følge reglerne i system-prompten, og lav en tekst der er klar til at blive postet direkte.`
    });
  }

  return messages;
};

const closePreview = () => {
  showPreview.value = false;
};

const savedPreviewImage = ref('');
const savedPreviewText = ref('');

const savePreview = () => {
  savedPreviewImage.value = previewImageUrl.value;
  savedPreviewText.value = previewText.value;
  alert('Forhåndsvisning gemt!');
  closePreview();
};

const userMetaSettings = ref(null);

// Tilf��j dealerId ref
const dealerId = ref(null);

// Hent bruger context
const { contextUserId } = useUserContext();

// Tilføj userId computed property
const userId = computed(() => contextUserId.value);

const fetchUserMetaSettings = async () => {
  if (!userId.value) {
    console.error('Ingen bruger ID tilgængelig');
    return;
  }

  try {
    const userDocRef = doc(db, 'users', userId.value);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      userMetaSettings.value = userDoc.data().MetaSettings;
      dealerId.value = userDoc.data().dealerId;
    } else {
      logger.error('Ingen brugerdata fundet');
      showToast('Kunne ikke finde brugerdata', 'Fejl', 'danger');
    }
  } catch (error) {
    console.error('Fejl ved hentning af brugerdata:', error);
  }
};

const notificationMessage = ref('');
const showNotification = ref(false);
const notificationTitle = ref('Notifikation');
const notificationColor = ref('success');

const showToast = (message, title = 'Notifikation', color = 'success') => {
  notificationMessage.value = message;
  notificationTitle.value = title;
  notificationColor.value = color;
  showNotification.value = true;
  // Skjul notifikationen efter 5 sekunder
  setTimeout(() => {
    showNotification.value = false;
  }, 5000);
};

const createPost = async () => {
  if (!isFormValid.value) {
    showToast('Venligst udfyld alle nødvendige felter.', 'Advarsel', 'warning');
    return;
  }

  if (!dealerId.value) {
    showToast('Kunne ikke finde forhandler ID', 'Fejl', 'danger');
    return;
  }

  isLoading.value = true;
  
  try {
    let imageUrl = savedPreviewImage.value;
    let postText = savedPreviewText.value;

    if (!imageUrl || !postText) {
      console.log('Genererer nyt billede og tekst');
      
      imageUrl = await generateImage(selectedCar.value, selectedDesign.value.uuid);
      if (!imageUrl) {
        throw new Error('Kunne ikke generere billedet');
      }
      
      const postContent = generateSocialMediaPost(selectedCar.value, 'facebook', selectedStatus.value);
      postText = postContent.text;
    }

    if (!userMetaSettings.value) {
      throw new Error('Brugerindstillinger kunne ikke hentes');
    }

    // Brug userId.value i stedet for getAuth().currentUser?.uid
    const postData = generatePostData({
      selectedCar: selectedCar.value,
      previewImageUrl: imageUrl,
      previewText: postText,
      selectedStatus: selectedStatus.value,
      channels: channels.value,
      userMetaSettings: userMetaSettings.value,
      userId: userId.value, // Brug computed userId her
      dealerId: dealerId.value,
      publishLater: publishLater.value,
      scheduledDateTime: scheduledDateTime.value
    });

    await addDoc(collection(db, 'posts'), postData);
    showToast('Opslag oprettet!', 'Succes', 'success');
    resetForm();
    
  } catch (error) {
    console.error('Fejl ved oprettelse af opslag:', error);
    showToast(
      `Fejl ved oprettelse af opslag: ${error.message}`, 
      'Fejl', 
      'danger'
    );
  } finally {
    isLoading.value = false;
  }
};

const resetForm = () => {
  // Nulstil alle relevante værdier
  selectedStatus.value = '';
  selectedCar.value = null;
  selectedDesign.value = null;
  channels.value.forEach(channel => channel.selected = false);
  types.value.forEach(type => type.selected = false);
  publishNow.value = false;
  publishLater.value = false;
  scheduledDateTime.value = '';
  savedPreviewImage.value = '';
  savedPreviewText.value = '';
  currentStep.value = 1; // Gå tilbage til første trin
};

// Kald prepareStepData, når komponenten monteres
onMounted(async () => {
  fetchDesignImages();
  prepareStepData(currentStep.value);
  await fetchUserMetaSettings();
});

// Overvåg currentStep og forbered data til det næste trin
watch(currentStep, (newStep) => {
  prepareStepData(newStep);
});

const selectedChannel = ref('');
const selectedMediaType = ref('');

const selectChannel = (channel) => {
  selectedChannel.value = channel;
};

const selectMediaType = (type) => {
  selectedMediaType.value = type;
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

.card-body {
  padding-bottom: 3rem !important;
  min-height: 500px;
  display: flex;
  align-items: center;
}

.invisible-card {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.invisible-card::before {
  content: none !important;
}

.invisible-card::after {
  content: none !important;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 300px; /* Juster denne værdi efter behov */
  justify-content: center;
  margin-top: -2rem;
}

.gradient-text {
  background-image: linear-gradient(0deg, hsla(0, 0%, 100%, .5), hsla(0, 0%, 100%, .5)), linear-gradient(90.1deg, #ad4f20 .08%, #ffffff 98.91%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: clamp(38px, 7vw, 46px); /* Øget skriftstørrelse her */
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.form-container {
  width: 100%;
  max-width: 400px;
}

.form-label {
  font-weight: bolder;
  font-size: 16px;
}

.select-button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.form-control {
  flex: 1 1 200px;
}

.btn {
  white-space: nowrap;
  flex: 0 0 auto;
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    gap: 1rem;
  }

  .form-container {
    width: 100%;
    max-width: none;
  }
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.fas {
  font-family: "Font Awesome 5 Free";
}

.mr-2 {
  margin-right: 0.5rem;
}

.row.align-items-center {
  flex-wrap: nowrap;
}

.gradient-text {
  white-space: nowrap;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .row.align-items-center {
    flex-direction: column;
    align-items: flex-start !important;
  }
  
  .gradient-text {
    margin-bottom: 1rem;
  }
}

.hoveredImg {
  transition: all 0.3s ease;
}

.hoveredImg:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.hoveredImg.selected {
  box-shadow: 0 0 15px rgba(0, 123, 255, 0.5);
  border: 2px solid #007bff;
}

.mainCardClass {
  margin-bottom: 20px;
}

.card-body {
  padding: 1rem;
}

.form-check-input {
  width: 3rem;
  height: 1.5rem;
}

.btn-md {
  padding: 0.65rem 1.25rem;
}

.gradient-text {
  font-size: clamp(24px, 5vw, 38px);
}

.card {
  background-color: #303030;
  border-radius: 15px;
  margin-bottom: 20px;
}

.card-header {
  background-color: #303030;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.card-body {
  padding: 1rem;
}

.designGroup {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.designGroup.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-control {
  background-color: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 3.5rem !important;
}

.btn {
  margin: 0 5px;
}

.custom-switch .form-check-input {
  height: 1.5rem;
  width: 3rem;
}

.hoveredImg {
  transition: all 0.3s ease;
}

.hoveredImg:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
}

.mainCardClass {
  margin-bottom: 20px;
}

.card {
  border-radius: 15px;
  overflow: hidden;
}

.card-body {
  padding: 0 !important;
}

.card img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card .d-flex.justify-content-between {
  padding: 15px;
}

.card p {
  font-size: 14px;
  margin-bottom: 0;
}

.btn-md {
  font-size: 0.875rem;
  padding: 0.65rem 1.25rem;
}

.row_gap_5 {
  row-gap: 1.25rem;
}

.card-body img {
  object-fit: cover;
  border-radius: 8px;
}

.button {
  font-size: 0.65rem !important;
  line-height: 1.2 !important;
  padding: 0.15rem 0.3rem !important;
}

.designs-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}


.design-card.selected,
.option-container.selected {
  border: 2px solid #2dce89;
  box-shadow: 0 0 10px rgba(45, 206, 137, 0.5);
}

.option-container {
  display: flex;
  align-items: center;
  padding: 10px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.option-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-label {
  margin-bottom: 0;
  font-weight: 500;
}

.design-card {
  background-color: #2c2c2c;
  border-radius: 8px;
  overflow: hidden;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;/* 250px for billedet + 10px padding (5px på hver side) */
  height: auto; /* Lad højden tilpasse sig indholdet */
  margin: 0 auto; /* Centrerer kortet hvis det er i et grid */
}

.design-image {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.designGroup {
  cursor: pointer;
  transition: color 0.3s ease;
}

.designGroup:hover {
  color: #5e72e4;
}

.designGroup.active {
  color: #5e72e4;
  font-weight: bold;
}

.btn {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.btn-md {
  font-size: 0.875rem;
  padding: 0.65rem 1.25rem;
}

.gradient-text {
  font-size: clamp(24px, 5vw, 38px);
}

.card-header h4 {
  font-size: 1.25rem;
}

.form-control {
  font-size: 0.875rem;
}

/* Tilføj disse styles for at ensrette knapperne */
.btn-outline-secondary,
.bg-gradient-success,
.bg-gradient-dark {
  border-radius: 0.5rem;
  transition: all 0.15s ease-in-out;
}

.btn-outline-secondary:hover,
.bg-gradient-success:hover,
.bg-gradient-dark:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

/* Ensret mellemrum mellem knapper */
.btn + .btn {
  margin-left: 0.5rem;
}

/* Juster alignmentet af knapper i header */
.d-flex.justify-content-between {
  padding: 1rem 0;
}

/* Ensret styling af kort */
.card {
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.card-header {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.design-card.selected {
  border: 2px solid #5e72e4;
  box-shadow: 0 0 10px rgba(94, 114, 228, 0.5);
}

.custom-switch {
  width: 3rem;
  height: 1.5rem;
}

.form-container {
  width: 100%;
  max-width: 450px;
}

.gradient-text {
  font-size: clamp(32px, 5vw, 42px);
  background: linear-gradient(90deg, #eee, #777);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

.option-container {
  display: flex;
  align-items: center;
  padding: 10px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.option-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-weight: 500;
  cursor: pointer;
}

.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #2dce89;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.custom-checkbox:checked {
  background-color: #2dce89;
}

.custom-checkbox:checked::after {
  content: '\2714';
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  height: 30px;
}

.btn-md {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, #7928CA 0%, #FF0080 100%);
}

.bg-gradient-success {
  background-image: linear-gradient(310deg, #2dce89 0%, #26c6da 100%);
}

.btn-outline-secondary {
  border-width: 2px;
}

.btn-outline-secondary:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.d-flex.justify-content-between {
  margin-top: 2rem;
}

.design-card.selected,
.option-container.selected,
.channel-option.selected,
.media-type-option.selected {
  border: 2px solid #5e72e4;
  box-shadow: 0 0 10px rgba(94, 114, 228, 0.5);
}

/* Tilføj disse nye styles hvis de ikke allerede eksisterer */
.channel-option,
.media-type-option {
  border-radius: 1rem;
  height: 27.6rem; /* Øget fra 23rem (20% større) */
  width: 20.4rem; /* Øget fra 17rem (20% større) */
  max-width: 20.4rem; /* Øget fra 17rem (20% større) */
  margin: 0.5rem;
  padding: 0;
  background: #303030;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.channel-type-container .channel-option,
.channel-type-container .media-type-option {
  border-radius: 1rem !important;
  height: 27.6rem !important;
  width: 20.4rem !important;
  max-width: 20.4rem !important;
  margin: 0.5rem !important;
  padding: 0 !important;
  background: #303030 !important;
  cursor: pointer !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  transition: all 0.3s ease !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.channel-type-container .channel-option.selected,
.channel-type-container .media-type-option.selected {
  border: 2px solid #5e72e4 !important;
  box-shadow: 0 0 15px rgba(94, 114, 228, 0.7) !important;
}

.avatar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar img {
  height: 22.8rem; /* Øget fra 19rem (20% større) */
  width: 15.6rem; /* Øget fra 13rem (20% større) */
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.option-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.option-container .form-label {
  margin-bottom: 0;
  font-weight: 500;
  color: white; /* Gør teksten hvid */
}

.option-container.selected {
  border: 2px solid #5e72e4;
  box-shadow: 0 0 15px rgba(94, 114, 228, 0.7);
}

.option-wrapper {
  margin-top: 5rem; /* Positiv værdi for at flytte indholdet ned */
}

.option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  max-height: 90%;
}

.preview-image-container {
  width: 100%;
  max-width: 600px; /* Juster dette efter behov */
  margin-bottom: 20px;
}

.preview-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.preview-text-container {
  width: 100%;
  max-width: 600px; /* Matcher max-width af billedet */
  margin-bottom: 20px;
}

.preview-textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  background-color: rgba(255, 255, 255, 0.9);
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px; /* Matcher max-width af billedet */
}

.shuffle-button,
.ai-assisted-button,
.close-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  color: white;
}

.shuffle-button {
  background-color: #4CAF50;
}

.ai-assisted-button {
  background-color: #2196F3;
}

.close-button {
  background-color: #f44336;
}

.shuffle-button:hover,
.ai-assisted-button:hover,
.close-button:hover {
  opacity: 0.8;
}

.option-wrapper {
  display: flex;
  flex-direction: column;
}

.option-wrapper .d-flex {
  gap: 1rem;
}

.option-container {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-width: 180px; /* Juster denne værdi efter behov */
  height: 60px; /* Fast højde for at sikre ensartethed */
}

.form-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 0.9rem; /* Juster skriftstørrelsen efter behov */
}

.channel-type-container {
  display: flex !important;
  justify-content: center !important;
  gap: 120px !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
}

.channel-type-container .channel-section,
.channel-type-container .type-section {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.channel-type-container .channel-option,
.channel-type-container .media-type-option {
  border-radius: 1rem !important;
  height: 20.6rem !important;
  width: 14.4rem !important;
  max-width: 20.4rem !important;
  margin: 0.5rem !important;
  padding: 0 !important;
  background: #303030 !important;
  cursor: pointer !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  transition: all 0.3s ease !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.channel-type-container .channel-option .avatar,
.channel-type-container .media-type-option .avatar {
  height: 19rem !important;
  width: 13rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.channel-type-container .channel-option .avatar img,
.channel-type-container .media-type-option .avatar img {
  max-height: 100% !important;
  max-width: 100% !important;
  object-fit: contain !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.options-row {
  display: flex;
  justify-content: center;
  gap: 20px; /* Mellemrum mellem muligheder i hver sektion */
  margin-top: 1rem;
}

.option-item {
  width: 170px; /* Juster dette efter behov */
  height: 230px; /* Juster dette efter behov */
}

.avatar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.section-title {
  text-align: center;
  margin-bottom: 1rem;
}

.d-flex.justify-content-between {
  padding: 0rem !important;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top: 2px solid #2196F3;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ai-assisted-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.save-button {
  background-color: #FFA500;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.save-button:hover {
  opacity: 0.8;
}

/* Opdater card styling til at følge glassmorphism principper */
.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
  will-change: transform;
}

/* Opdater design-card styling */
.design-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  overflow: hidden;
  padding: 1rem;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

.design-card:hover {
  transform: translateY(-5px);
}

.design-card.selected {
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

/* Opdater button styling */
.btn {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
}

/* Opdater form controls */
.form-control {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

/* Opdater option container styling */
.option-container {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  transition: all 0.3s ease;
}

.option-container:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Tilføj animationer */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.content-container {
  animation: fadeIn 0.3s ease-out;
}
</style>