<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonBadge from "@/components/ArgonBadge.vue"; // Tilføj denne import
import { collection, onSnapshot, query, where, deleteDoc } from "firebase/firestore";
import { db, auth } from "@/firebase";
import { deleteUser } from "firebase/auth";
import CompanyAddComponent from "./CompanyAddComponent.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { doc, getDoc, updateDoc } from "firebase/firestore";

// Definer roller som konstanter
const ROLE_ADMIN = 1;
const ROLE_MANAGER = 2;
const ROLE_USER = 3;

const store = useStore();
const router = useRouter();

const showAddCompanyModal = ref(false);
const users = ref([]);
const showAllUserListButton = ref("false")
const searchQuery = ref("");
const isLoading = ref(true);

const openAddCompanyModal = () => {
  showAddCompanyModal.value = true;
};

const closeAddCompanyModal = () => {
  showAddCompanyModal.value = false;
};

let unsubscribe;

onMounted(() => {
  const usersCollection = collection(db, "users");
  console.log("currentUser.role",store.state.activeUser, store.state.activeUser.role)
  const q = query(usersCollection, store.state.activeUser.role === 2 ? where("createdBy", "==", store.state.activeUser.id) : null);
  unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const usersArray = [];
      querySnapshot.forEach((doc) => {
        usersArray.push({ id: doc.id, ...doc.data() });
      });
      console.log("Hentede brugere:", usersArray);
      users.value = usersArray;
      isLoading.value = false;
    },
    (error) => {
      console.error("Fejl ved hentning af brugere:", error);
      isLoading.value = false;
    },
  );
});

onUnmounted(() => {
  if (unsubscribe) unsubscribe();
});

const filteredUsers = computed(() => {
  if (!users.value) return [];
  return users.value.filter(
    (user) =>
      user &&
      user.name &&
      user.email &&
      (user.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.value.toLowerCase())) &&
      [ROLE_MANAGER, ROLE_USER].includes(user.role) // Inkluder kun roller 2 og 3
  );
});

// Brug getters til at få den aktive bruger
const currentUser = computed(() => {
  const user = store.getters.activeUser;
  // Tjek om vi har et gyldigt user objekt med rolle
  if (!user) return null;
  
  // Hvis role findes direkte på objektet
  if (user.role) return user;
  
  // Hvis role findes i reloadUserInfo
  if (user.reloadUserInfo?.role) return { ...user, role: user.reloadUserInfo.role };
  
  // Hvis vi har uid/id, men mangler role, redirect til signin
  if (user.uid || user.id) {
    router.push('/authentication/signin');
    return null;
  }
  
  return null;
});

// Tilføj en watch på currentUser hvis nødvendigt
watch(currentUser, (newValue) => {
  console.log('currentUser ændret:', newValue)
  if (!newValue) {
    // Håndter situationen hvor bruger er null
    router.push('/authentication/signin')
  }
})

// Funktion til at få rolle navn
const getRoleName = (role) => {
  switch (role) {
    case ROLE_ADMIN:
      return "Admin";
    case ROLE_MANAGER:
      return "Manager";
    case ROLE_USER:
      return "Bruger";
    default:
      return "Ukendt";
  }
};

const accessUserProfile = (userId) => {
  if (!userId) return;
  
  const currentUser = store.state.activeUser;
  const isAdminOrSubAdmin = currentUser?.role === 1 || currentUser?.role === 2;

  if (isAdminOrSubAdmin) {
    router.push(`/profile/${userId}`);
  } else {
    router.push('/profile');
  }
};

const markUserRole = async(user, role) => {
  console.log(user, user.id)
  if (user && user.id) {
    try {
      const userDocRef = doc(db, "users", user.id);
      await updateDoc(userDocRef, {
        role: role
      });
      // alert("Profil opdateret!");

    } catch (error) {
      console.error("Fejl ved opdatering af profil: ", error);
      alert(`Fejl ved opdatering af profil: ${error.message}`);
    }
  } else {
    console.log("Ingen bruger er logget ind eller bruger-ID mangler.");
  }
}

const allUsers = async(user) => {
  showAllUserListButton.value = true
  const usersCollection = collection(db, "users");
  const q = query(usersCollection, where("createdBy", "==", user.id));
  unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const usersArray = [];
      querySnapshot.forEach((doc) => {
        usersArray.push({ id: doc.id, ...doc.data() });
      });
      console.log("Hentede brugere:", usersArray);
      users.value = usersArray;
      isLoading.value = false;
    },
    (error) => {
      console.error("Fejl ved hentning af brugere:", error);
      isLoading.value = false;
    },
  );
}

const allUserLists = async(user) => {
  showAllUserListButton.value = false
  const usersCollection = collection(db, "users");
  const q = query(usersCollection);
  unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const usersArray = [];
      querySnapshot.forEach((doc) => {
        usersArray.push({ id: doc.id, ...doc.data() });
      });
      console.log("Hentede brugere:", usersArray);
      users.value = usersArray;
      isLoading.value = false;
    },
    (error) => {
      console.error("Fejl ved hentning af brugere:", error);
      isLoading.value = false;
    },
  );
}

const deleteUserConfirmation = async (user) => {
  if (confirm(`Er du sikker på, at du vil slette ${user.name}?`)) {
    try {
      // Slet bruger fra Firestore
      await deleteDoc(doc(db, "users", user.id));
      
      // Slet bruger fra Authentication
      const authUser = auth.currentUser;
      if (authUser && authUser.uid === user.id) {
        await deleteUser(authUser);
      }
      
      console.log("Bruger slettet succesfuldt");
    } catch (error) {
      console.error("Fejl ved sletning af bruger:", error);
      alert(`Fejl ved sletning af bruger: ${error.message}`);
    }
  }
};

</script>

<template>
  <div class="card">
    <div v-if="isLoading">Indlæser...</div>
    <div v-else-if="!currentUser">
      <p>Omdirigerer til login...</p>
    </div>
    <div v-else>
      <!-- Søgebjælke og knapper -->
      <div class="d-flex justify-content-between align-items-center px-3 py-2">
        <div class="search-bar flex-grow-1 me-3">
          <!-- Her kunne du eventuelt erstatte input med en custom Argon input component -->
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Søg efter en bruger..."
            class="form-control search-input"
          />
        </div>

        <div class="d-flex gap-2">
          <argon-button
            color="secondary"
            size="sm"
            variant="outline"
            class="custom-button"
            v-if="currentUser.role === 1 && showAllUserListButton"
            @click="allUserLists()"
          >
            Vis alle brugere
          </argon-button>

          <argon-button
            color="success"
            size="sm"
            variant="outline"
            class="custom-button"
            @click="openAddCompanyModal"
          >
            Tilføj virksomhed
          </argon-button>
        </div>
      </div>

      <!-- Tabel indhold -->
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Bruger
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Funktion
                </th>
                <th v-if="currentUser.role === 1" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Skift brugerrolle
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Status
                </th>
                <th class="text-center text-uppercase text-xxs font-weight-bolder opacity-7">
                  ID
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Actions
                </th>
                <th v-if="currentUser.role === 1 || currentUser.role === 2" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Slet
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in filteredUsers" :key="user.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ user.name }}</h6>
                      <p class="text-xs text-secondary mb-0">{{ user.email }}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ getRoleName(user.role) }}</p>
                </td>
                <td v-if="currentUser.role === 1">
                  <div class="d-flex gap-1">
                    <argon-button
                      color="secondary"
                      size="sm"
                      variant="outline"
                      class="custom-button"
                      v-if="user.role !== 2"
                      @click="markUserRole(user, 2)"
                    >
                      Manager
                    </argon-button>
                    <argon-button
                      color="secondary"
                      size="sm"
                      variant="outline"
                      class="custom-button"
                      v-if="user.role !== 3"
                      @click="markUserRole(user, 3)"
                    >
                      Bruger
                    </argon-button>
                  </div>
                </td>
                <td class="align-middle text-center text-sm">
                  <argon-badge
                    :color="user.isOnline ? 'success' : 'dark'"
                    variant="gradient"
                    size="sm"
                    class="badge-sm"
                  >
                    {{ user.isOnline ? "Online" : "Offline" }}
                  </argon-badge>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ user.id }}</span>
                </td>
                <td class="align-middle">
                  <div class="d-flex gap-2">
                    <argon-button
                      color="azure"
                      size="sm"
                      variant="gradient"
                      class="custom-button"
                      @click="accessUserProfile(user.id)"
                    >
                      Se profil
                    </argon-button>
                    <argon-button
                      v-if="user.role === 2"
                      color="navy"
                      size="sm"
                      variant="gradient"
                      class="custom-button"
                      @click="allUsers(user)"
                    >
                      Alle brugere
                    </argon-button>
                  </div>
                </td>
                <td v-if="currentUser.role === 1 || currentUser.role === 2" class="align-middle">
                  <argon-button
                    color="danger"
                    size="sm"
                    variant="gradient"
                    class="custom-button"
                    @click="deleteUserConfirmation(user)"
                  >
                    Slet bruger
                  </argon-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Modal -->
      <div v-if="showAddCompanyModal" class="modal-overlay">
        <div class="modal-container">
          <CompanyAddComponent @close="closeAddCompanyModal" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Card base */
.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
  will-change: transform;
}

/* Tabel */
.table {
  background: transparent;
  color: #ffffff;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.table th {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 20px 15px;
  background: rgba(255, 255, 255, 0.05);
  border: none;
}

.table td {
  padding: 15px;
  border: none;
  backdrop-filter: blur(5px);
}

.table tbody tr {
  background: rgba(255, 255, 255, 0.03);
  transition: background-color 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateZ(0);
}

.table tbody tr:hover {
  background: rgba(255, 255, 255, 0.07);
}

/* Søgefelt */
.search-input {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 12px 20px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-input:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
}

/* Fælles knap styling */
:deep(.custom-button) {
  background: rgba(255, 255, 255, 0.08) !important;
  backdrop-filter: blur(8px) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  padding: 8px 16px !important;
  color: white !important;
  font-weight: 500 !important;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1) !important;
  transform: translateZ(0);
  will-change: transform, background-color;
  box-shadow: 
    0 2px 5px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.05) !important;
}

:deep(.custom-button:hover) {
  background: rgba(255, 255, 255, 0.15) !important;
}

/* Farve varianter med subtil gradient */
:deep(.custom-button.btn-azure) {
  background: linear-gradient(
    135deg, 
    rgba(33, 150, 243, 0.15), 
    rgba(33, 150, 243, 0.25)
  ) !important;
}

:deep(.custom-button.btn-success) {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.15), 
    rgba(66, 186, 150, 0.25)
  ) !important;
}

:deep(.custom-button.btn-danger) {
  background: linear-gradient(
    135deg, 
    rgba(245, 54, 92, 0.15), 
    rgba(245, 54, 92, 0.25)
  ) !important;
}

:deep(.custom-button.btn-secondary) {
  background: linear-gradient(
    135deg, 
    rgba(130, 214, 255, 0.15), 
    rgba(130, 214, 255, 0.25)
  ) !important;
}

/* Hover effekter for farve varianter */
:deep(.custom-button.btn-azure:hover),
:deep(.custom-button.btn-success:hover),
:deep(.custom-button.btn-danger:hover),
:deep(.custom-button.btn-secondary:hover) {
  opacity: 0.9 !important;
}

/* Badge */
.badge-sm {
  backdrop-filter: blur(4px);
  border-radius: 20px;
  padding: 5px 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  z-index: 1000;
  animation: fadeIn 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.modal-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  animation: slideUp 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  padding: 20px;
  position: relative;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}
</style>
