// src/store/index.js
import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [
    createPersistedState({
      paths: ['activeUser', 'isAuthLoading']
    })
  ],
  state: {
    // UI-relateret state
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    setImpersonatedUser: null,
    // Brugerrelateret state
    activeUser: null,
    // Cache-relateret state
    cachedCars: [],
    lastCarsFetch: null,
    isAuthLoading: true,
  },
  mutations: {
    // UI-relaterede mutationer
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      state.isNavFixed = !state.isNavFixed;
    },

    // Brugerrelaterede mutationer
    SET_CURRENT_USER(state, user) {
      state.activeUser = user;
      localStorage.setItem('currentUser', JSON.stringify(user));
    },
    CLEAR_CURRENT_USER(state) {
      state.activeUser = null;
      localStorage.removeItem('currentUser');
    },

    setCachedCars(state, cars) {
      state.cachedCars = cars;
      state.lastCarsFetch = Date.now();
      
      // Gem også i localStorage
      try {
        localStorage.setItem('cachedCars', JSON.stringify(cars));
        localStorage.setItem('lastCarsFetch', state.lastCarsFetch.toString());
      } catch (e) {
        console.error('Kunne ikke gemme i localStorage:', e);
      }
    },

    loadCachedCars(state) {
      try {
        const cachedCars = localStorage.getItem('cachedCars');
        const lastFetch = localStorage.getItem('lastCarsFetch');
        
        if (cachedCars && lastFetch) {
          const lastFetchTime = parseInt(lastFetch);
          const now = Date.now();
          const sixAM = new Date();
          sixAM.setHours(7, 0, 0, 0);
          const sixAMTime = sixAM.getTime();
          
          // Hvis sidste fetch var før kl 6 i dag, og klokken er over 6 nu,
          // så lad være med at indlæse cachen
          if (lastFetchTime < sixAMTime && now > sixAMTime) {
            state.cachedCars = [];
            state.lastCarsFetch = null;
            localStorage.removeItem('cachedCars');
            localStorage.removeItem('lastCarsFetch');
          } else {
            state.cachedCars = JSON.parse(cachedCars);
            state.lastCarsFetch = lastFetchTime;
          }
        }
      } catch (e) {
        console.error('Fejl ved indlæsning af cache:', e);
        state.cachedCars = [];
        state.lastCarsFetch = null;
      }
    },

    clearCarCache(state) {
      state.cachedCars = [];
      state.lastCarsFetch = null;
      localStorage.removeItem('cachedCars');
      localStorage.removeItem('lastCarsFetch');
    },

    SET_AUTH_LOADING(state, isLoading) {
      state.isAuthLoading = isLoading;
    },
  },
  actions: {
    // UI-relaterede handlinger
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },

    // Brugerrelaterede handlinger
    setCurrentUser({ commit }, user) {
      console.log('Setting user data:', user);
      commit("SET_CURRENT_USER", user);
    },
    clearCurrentUser({ commit }) {
      console.log("Action clearCurrentUser called");
      commit("CLEAR_CURRENT_USER");
    },
  },
  getters: {
    // UI-relaterede getters kan tilføjes her

    // Brugerrelaterede getters
    activeUser: (state) => state.activeUser,
    isAuthenticated: (state) => !!state.activeUser,
    getCachedCars: (state) => state.cachedCars,
    getLastCarsFetch: (state) => state.lastCarsFetch,
    shouldRefetchCars: (state) => {
      if (!state.lastCarsFetch) return true;
      
      const now = new Date();
      const lastFetch = new Date(state.lastCarsFetch);
      const sixAM = new Date();
      sixAM.setHours(7, 0, 0, 0);
      
      // Hvis sidste fetch var før kl 6 i dag, og klokken er over 6 nu
      return lastFetch < sixAM && now >= sixAM;
    }
  },
});
