/* eslint-disable */

function safelyAccessElement(selector) {
  const element = document.querySelector(selector);
  return element || null;
}

function safelyHandleNavPills() {
  const total = document.querySelectorAll('.nav-pills');
  const movingDiv = safelyAccessElement('.moving-tab');
  
  if (!total.length || !movingDiv) return;

  total.forEach(item => {
    const activeLink = item.querySelector('.nav-link.active');
    if (!activeLink) return;

    const li = activeLink.closest('li');
    if (!li) return;

    const nodes = Array.from(li.parentElement?.children || []);
    if (!nodes.length) return;

    const index = nodes.indexOf(li) + 1;
    let sum = 0;

    if (window.innerWidth < 991) {
      if (!item.classList.contains('flex-column')) {
        item.classList.remove('flex-row');
        item.classList.add('flex-column', 'on-resize');
        
        // Beregn vertikal position
        for (let j = 1; j <= nodes.indexOf(li); j++) {
          const child = item.querySelector(`li:nth-child(${j})`);
          if (child) {
            sum += child.offsetHeight;
          }
        }
        
        const firstChild = item.querySelector('li:nth-child(1)');
        if (firstChild) {
          movingDiv.style.width = `${firstChild.offsetWidth}px`;
          movingDiv.style.transform = `translate3d(0px, ${sum}px, 0px)`;
        }
      }
    } else {
      if (item.classList.contains('on-resize')) {
        item.classList.remove('flex-column', 'on-resize');
        item.classList.add('flex-row');
        
        // Beregn horisontal position
        for (let j = 1; j <= nodes.indexOf(li); j++) {
          const child = item.querySelector(`li:nth-child(${j})`);
          if (child) {
            sum += child.offsetWidth;
          }
        }
        
        const indexChild = item.querySelector(`li:nth-child(${index})`);
        if (indexChild) {
          movingDiv.style.transform = `translate3d(${sum}px, 0px, 0px)`;
          movingDiv.style.width = `${indexChild.offsetWidth}px`;
        }
      }
    }
  });
}

export default function setNavPills() {
  window.addEventListener('load', safelyHandleNavPills);
  window.addEventListener('resize', safelyHandleNavPills);
}
