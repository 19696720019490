<template>
  <Teleport to="body">
    <div v-if="isOpen" class="modal-backdrop show"></div>
    <div 
      v-if="isOpen" 
      class="modal show d-block" 
      tabindex="-1" 
      aria-modal="true" 
      role="dialog"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Link Oversigt</h5>
            <button 
              type="button" 
              class="btn-close" 
              @click="closeModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="pb-0 card-header">
                <div class="d-lg-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="mb-0">Alle Links</h5>
                    <p class="mb-0 text-sm">Oversigt over alle dine links og deres performance</p>
                  </div>
                  <div class="d-flex gap-3 mt-3 mt-lg-0">
                    <div class="search-container">
                      <input 
                        type="text" 
                        v-model="searchQuery" 
                        class="form-control" 
                        placeholder="Søg efter links..."
                      >
                    </div>
                    <select v-model="sortBy" class="form-select">
                      <option value="newest">Nyeste først</option>
                      <option value="oldest">Ældste først</option>
                      <option value="mostClicks">Flest kliks</option>
                      <option value="leastClicks">Færrest kliks</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="px-0 pb-0 card-body table-container">
                <div class="table-responsive">
                  <div class="dataTable-wrapper">
                    <div class="dataTable-container">
                      <table class="table table-flush">
                        <thead class="thead-light">
                          <tr>
                            <th></th>
                            <th>URL</th>
                            <th>Kort Link</th>
                            <th>Kliks</th>
                            <th>Oprettet</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="link in sortedAndFilteredLinks" :key="link.id">
                            <td>
                              <img 
                                v-if="link.url"
                                :src="getThumbnail(link.url)" 
                                class="thumbnail-img" 
                                alt="thumbnail"
                                @error="handleImageError"
                              >
                              <div v-else class="thumbnail-placeholder">
                                <i class="fas fa-link"></i>
                              </div>
                            </td>
                            <td class="text-sm">
                              <a :href="link.url" target="_blank">{{ truncateUrl(link.url) }}</a>
                            </td>
                            <td class="text-sm">
                              <a :href="link.shortLink" target="_blank">{{ link.shortLink }}</a>
                            </td>
                            <td class="text-sm">{{ link.clicks }}</td>
                            <td class="text-sm">{{ formatDate(link.createdAt) }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { getFirestore, collection, getDocs } from 'firebase/firestore'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()
const links = ref([])
const isOpen = ref(false)
const searchQuery = ref('')
const sortBy = ref('newest')

const openModal = () => {
  isOpen.value = true
  fetchLinks()
}

const closeModal = () => {
  isOpen.value = false
}

// Eksporter metoderne så de kan kaldes fra parent
defineExpose({
  openModal,
  closeModal
})

// Hent links fra Firestore
const fetchLinks = async () => {
  const db = getFirestore()
  try {
    const userId = route.params.id || store.state.activeUser?.id
    if (!userId) {
      console.error('Ingen bruger ID fundet')
      return
    }

    const linksRef = collection(db, `users/${userId}/linkAnalytics`)
    const querySnapshot = await getDocs(linksRef)
    
    links.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      url: doc.data().url || '',
      shortLink: doc.data().shortLink || '',
      clicks: doc.data().clicks || 0,
      createdAt: doc.data().createdAt || null
    }))

    // Sorter links efter oprettelsesdato (nyeste først)
    links.value.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
  } catch (error) {
    console.error('Fejl ved hentning af links:', error)
  }
}

// Hent thumbnail
const getThumbnail = (url) => {
  try {
    const urlObj = new URL(url)
    return `https://www.google.com/s2/favicons?domain=${urlObj.hostname}&sz=128`
  } catch (error) {
    console.error('Fejl ved parsing af URL:', error)
    return '/path-to-your-default-image.jpg'
  }
}

// Håndter billede fejl
const handleImageError = (event) => {
  const target = event.target
  target.style.display = 'none'
  target.parentElement.innerHTML = `
    <div class="thumbnail-placeholder">
      <i class="fas fa-link"></i>
    </div>
  `
}

// Truncate lange URLs
const truncateUrl = (url) => {
  return url.length > 50 ? url.substring(0, 47) + '...' : url
}

// Format dato
const formatDate = (timestamp) => {
  if (!timestamp) return ''
  const date = new Date(timestamp)
  return date.toLocaleDateString('da-DK', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

// Computed property for filtrerede og sorterede links
const sortedAndFilteredLinks = computed(() => {
  let filteredLinks = links.value

  // Søgefilter
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    filteredLinks = filteredLinks.filter(link => 
      link.url.toLowerCase().includes(query) ||
      link.shortLink.toLowerCase().includes(query)
    )
  }

  // Sortering
  return filteredLinks.sort((a, b) => {
    switch (sortBy.value) {
      case 'newest':
        return (b.createdAt || 0) - (a.createdAt || 0)
      case 'oldest':
        return (a.createdAt || 0) - (b.createdAt || 0)
      case 'mostClicks':
        return (b.clicks || 0) - (a.clicks || 0)
      case 'leastClicks':
        return (a.clicks || 0) - (b.clicks || 0)
      default:
        return 0
    }
  })
})

onMounted(() => {
  fetchLinks()
})
</script>

<style scoped>
/* Basis styles */
.modal-content {
  background: #202125 !important;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1) !important;
  border: none !important;
}

.card {
  background: #202125 !important;
  border: none !important;
  box-shadow: none !important;
}

/* Table styles */
.table {
  background: #202125 !important;
}

.thead-light th {
  background: #202125 !important;
  color: #888c9f !important;
  font-weight: 600 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.table td {
  background: #202125 !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
}

/* Text styles */
.text-sm {
  color: white !important;
}

.table a {
  color: #5e72e4 !important;
}

.table a:hover {
  color: #8392f7 !important;
}

.modal-title {
  color: #ffffff !important;
}

.card-header {
  background: #202125 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.card-header h5,
.card-header p {
  color: white !important;
}

.card-body {
  background: #202125 !important;
}

/* Thumbnail container */
.thumbnail-placeholder {
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

/* Thumbnail styles */
.thumbnail-img {
  width: 100px;
  height: 60px;
  object-fit: contain;
  border-radius: 8px;
  background-color: white !important;
  padding: 10px;
  border: 1px solid #eee;
}

.thumbnail-placeholder {
  width: 100px;
  height: 60px;
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #eee;
}

.thumbnail-placeholder i {
  font-size: 24px;
  color: #666 !important;
}

/* Modal backdrop */
.modal-backdrop.show {
  background: rgba(0, 0, 0, 0.5) !important;
}

/* Close button */
.btn-close {
  background-color: #202125 !important;
  opacity: 1 !important;
  padding: 0.75rem !important;
  border-radius: 8px !important;
  transition: all 0.2s ease !important;
}

.btn-close:hover {
  background-color: #dc3545 !important; /* Rød farve på hover */
  transform: rotate(90deg);
}

/* Tilføj dette hvis du vil have en blødere overgang mellem farverne */
.btn-close {
  position: relative !important;
  width: 10px !important;
  height: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

/* Optional: Hvis du vil have en mere synlig X */
.btn-close::before {
  color: white !important;
  opacity: 1 !important;
}

/* Nye styles */
.table-container {
  max-height: 70vh;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-container::-webkit-scrollbar {
  width: 6px;
}

.table-container::-webkit-scrollbar-track {
  background: #2c2c30;
}

.table-container::-webkit-scrollbar-thumb {
  background: #4a4a4f;
  border-radius: 3px;
}

.search-container {
  min-width: 250px;
}

.form-control, .form-select {
  background: #2c2c30 !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: white !important;
}

.form-control:focus, .form-select:focus {
  border-color: #5e72e4 !important;
  box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.25) !important;
}

.form-select {
  background: #2c2c30 !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-image: none !important;
}

.form-select option {
  background: #2c2c30;
  color: white;
}

/* Fjern også den native dropdown pil i Firefox */
.form-select::-ms-expand {
  display: none;
}
</style> 