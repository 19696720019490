<template>
  <main class="py-4 blog-overview">
    <div class="container">
      <div class="blog-header">
        <p class="blog-category">BLOG</p>
        <h1 class="blog-title">Creative Automation Ideas</h1>
        <p class="blog-description">
          Need inspiration? Browse through our ideas for creative automations to
          make your content shareable here!
        </p>
      </div>
      
      <div v-if="blogPosts.length > 0" class="article-list">
        <article v-for="post in blogPosts" :key="post.id" class="article-list-entry">
          <div class="article-list-entry__visual">
            <img :src="post.bannerImageUrl" :alt="`Billede til artikel ${post.title}`" loading="lazy">
          </div>
          <div class="article-list-entry__content">
            <h2 class="article-list-entry__title">
              {{ post.title }}
            </h2>
            <p class="article-list-entry__lead">
              {{ stripHtmlAndTruncate(post.content, 150) }}
            </p>
            <router-link :to="{ name: 'BlogPost', params: { slug: post.slug, id: post.id } }" class="article-list-entry__button">READ NOW</router-link>
          </div>
        </article>
      </div>
      <div v-else>
        Indlæser blogindlæg...
      </div>
    </div>
  </main>
</template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { collection, getDocs } from 'firebase/firestore';
  import { db } from '@/firebase';
  
  const blogPosts = ref([]);
  
  function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/[\s_]+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }
  
  onMounted(async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'blogPosts'));
      blogPosts.value = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          slug: data.slug || slugify(data.title)
        };
      });
      console.log('All blog posts:', blogPosts.value);
    } catch (error) {
      console.error('Fejl ved hentning af blogindlæg:', error);
    }
  });
  
  function stripHtmlAndTruncate(html, maxLength) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    const text = tmp.textContent || tmp.innerText || '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  }
  </script>
  
  <style scoped>
  .blog-overview {
    background-color: #1a1a1a;
    color: #ffffff;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .blog-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .blog-category {
    text-transform: uppercase;
    color: #888;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  
  .blog-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 1rem;
  }
  
  .blog-description {
    font-size: 1.1rem;
    color: #cccccc;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .article-list-entry {
    display: flex;
    margin-bottom: 2rem;
    background-color: #2a2a2a;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  .article-list-entry__visual {
    flex: 0 0 40%;
    background-color: #8a4baf;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .article-list-entry__visual img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .article-list-entry__content {
    flex: 1;
    padding: 1.5rem;
  }
  
  .article-list-entry__title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #ffffff;
  }
  
  .article-list-entry__lead {
    margin-bottom: 1rem;
    color: #cccccc;
  }
  
  .article-list-entry__button {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background-color: #8a4baf;
    color: white;
    text-decoration: none;
    border-radius: 20px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
  
  .article-list-entry__button:hover {
    background-color: #7a3d9f;
  }
  </style>
