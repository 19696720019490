<script setup>
import ProfileHeader from "./components/ProfileHeader.vue";
import AuthorsTable from "./components/AuthorsTable.vue";
import Settings from "./Settings.vue";
import OpretDesigns from "./OpretDesigns.vue"; // Importer den nye komponent
import { ref, computed } from "vue";
import { useStore } from "vuex";
import BlogAdmin from "./components/BlogAdmin.vue"; // Importer den nye komponent

const store = useStore();
const activeTab = ref("App");

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

const currentContent = computed(() => {
  switch (activeTab.value) {
    case "App":
      return "AppContent";
    case "AdminPanel":
      return "AdminPanelContent";
    case "OpretDesigns":
      return "OpretDesignsContent";
    case "Indstillinger":
      return "IndstillingerContent";
    case "BlogAdmin":
      return "BlogAdminContent";
    default:
      return "AppContent";
  }
});
</script>

<template>
  <profile-header
    header-image="https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80"
    name="MakeMore"
    @update:active-tab="setActiveTab"
  />
  <div class="py-4 container-fluid">
    <div v-if="currentContent === 'AppContent'">
      <!-- Dit eksisterende App indhold -->
      <div class="row">
        <div class="col-12">
          <authors-table />
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-12">
          <projects-table />
        </div>
      </div>
    </div>
    <div v-else-if="currentContent === 'AdminPanelContent'">
      <!-- Indhold for Admin Panel -->
      <div class="row">
        <div class="col-12">
          <authors-table />
        </div>
      </div>
    </div>
    <div v-else-if="currentContent === 'OpretDesignsContent'">
      <!-- Indhold for Opret Designs -->
      <opret-designs />
    </div>
    <div v-else-if="currentContent === 'IndstillingerContent'">
      <!-- Indhold for Indstillinger -->
      <settings />
    </div>
    <div v-else-if="currentContent === 'BlogAdminContent'">
      <!-- Indhold for Blog Admin -->
      <blog-admin />
    </div>
  </div>
</template>
