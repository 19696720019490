<template>
  <div class="modal-dialog modal-xl">
    <!-- Tilføj loading overlay -->
    <div v-if="isLoading" class="global-loading-overlay">
      <div class="loading-content">
        <div class="loading-text">
          Indlæser skabelon...
        </div>
        <div class="container">
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
        </div>
      </div>
    </div>
    <div class="modal-content p-5 position-relative">
      <div class="modal-header">
        <h5 class="modal-title">Rediger design</h5>
      </div>
      
      <div class="modal-body">
        <div class="row">
          <!-- Venstre side med input felter - smallere -->
          <div class="col-4">
            <form class="text-start">
              <div v-for="node in apiNodes" 
                   :key="node.id" 
                   class="mb-3">
                <!-- Normal tekstfelt for ikke-billede nodes -->
                <textarea 
                  v-if="!node.name?.includes('api_img')"
                  :id="node.name"
                  class="form-control"
                  v-model="node.textContent"
                  @input="updateTemplate"
                  :placeholder="getReadableName(node.name)"
                  style="width: 100%;"
                ></textarea>
                
                <!-- Kun file input for billede nodes -->
                <div v-else class="image-upload-container">
                  <small class="upload-label">(Billede)</small>
                  <input class="form-control file-input" 
                         :id="`${node.name}_file`" 
                         type="file" 
                         accept="image/*"
                         @change="handleImageUpload($event, node)">
                </div>
              </div>
            </form>
          </div>

          <!-- Mellemrum mellem kolonnerne -->
          <div class="col-1"></div>

          <!-- Højre side med preview - beholder størrelse -->
          <div class="col-7 preview-section">
            <div class="preview-container" ref="previewContainer">
              <div class="moveable-container"
                   :style="containerStyle">
                <div class="editor-wrapper">
                  <div class="nodes-canvas"
                       :style="canvasStyle">
                    <!-- Nodes (elementer) -->
                    <template v-for="node in nodes" :key="node.id">
                      <div class="c-node"
                           :style="{
                             position: 'absolute',
                             width: `${node.width}px`,
                             height: `${node.height}px`,
                             transform: `translate(${node.x}px, ${node.y}px) rotate(${node.angle}deg)`
                           }">
                        <!-- Billede node -->
                        <div v-if="node.type === 'image'" style="width: 100%; height: 100%; overflow: hidden;">
                          <img :src="node.src"
                               :style="{
                                 width: '100%',
                                 height: '100%',
                                 display: 'block',
                                 background: node.background?.color || 'transparent',
                                 borderRadius: `${node.borderRadius}px`,
                                 borderStyle: 'solid',
                                 borderWidth: `${node.borderWidth}px`,
                                 borderColor: node.borderColor,
                                 opacity: node.opacity,
                                 objectFit: node.objectFit || 'cover'
                               }" />
                        </div>
                        
                        <!-- Tekst node -->
                        <div v-else-if="node.type === 'text'"
                             :style="{
                               width: '100%',
                               height: '100%',
                               background: node.background?.color || 'transparent',
                               borderRadius: `${node.borderRadius}px`,
                               borderStyle: 'solid',
                               borderWidth: `${node.borderWidth}px`,
                               borderColor: node.borderColor,
                               opacity: node.opacity,
                               fontFamily: `'${node.fontId}'`,
                               fontWeight: node.fontVariantId,
                               fontSize: `${node.fontSize}px`,
                               letterSpacing: `${node.letterSpacing}px`,
                               lineHeight: node.lineHeight,
                               textAlign: node.textAlign,
                               display: 'flex',
                               alignItems: 'center'
                             }">
                          <div style="max-height: 100%; width: 100%; direction: ltr;">
                            <span :style="{ color: node.textColor }">{{ node.textContent }}</span>
                          </div>
                        </div>

                        <!-- Rektangel node -->
                        <div v-else-if="node.type === 'rect'"
                             :style="{
                               width: '100%',
                               height: '100%',
                               background: node.background?.color || 'transparent',
                               borderRadius: `${node.borderRadius}px`,
                               borderStyle: 'solid',
                               borderWidth: `${node.borderWidth}px`,
                               borderColor: node.borderColor,
                               opacity: node.opacity,
                               boxShadow: node.boxShadow ? 
                                 `${node.boxShadow.offsetX}px ${node.boxShadow.offsetY}px ${node.boxShadow.blurRadius}px ${node.boxShadow.spreadRadius}px ${node.boxShadow.color}` : 
                                 'none'
                             }">
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="row" style="width: 100%;">
          <div class="col-3">
            <button type="button" 
                    class="btn bg-gradient-danger btn-sm" 
                    @click="$emit('cancel')">
              Fortryd
            </button>
          </div>
          <div class="col-3">
            <button type="button" 
                    class="btn bg-gradient-success btn-sm"
                    @click="saveTemplate">
              Gem skabelon
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    templateId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: true,
      apiUrl: 'https://us-central1-toft-d4f39.cloudfunctions.net/getTemplateEditor',
      template: null,
      nodes: [],
      artboardWidth: 1200,
      artboardHeight: 1200,
      backgroundColor: 'rgb(235, 237, 224)',
      selectedNode: null,
      scale: 1,
      containerWidth: 1200, // Default container width
    }
  },
  
  async created() {
    try {
      this.isLoading = true;
      const response = await fetch(`${this.apiUrl}?id=${this.templateId}`);
      const data = await response.json();
      
      if (data.value && data.value[0]) {
        const template = data.value[0];
        this.template = template;
        this.nodes = template.nodes;
        
        this.artboardWidth = template.width || 1200;
        this.artboardHeight = template.height || 1200;
        this.backgroundColor = template.backgroundColor;
        
        await this.loadGoogleFonts();
        
        this.$nextTick(() => {
          this.calculateScale();
          this.isLoading = false;
        });
      }
    } catch (error) {
      console.error('Fejl ved hentning af template:', error);
      this.isLoading = false;
    }
  },

  computed: {
    apiNodes() {
      return this.nodes.filter(node => {
        // Behold alle api_ nodes
        return node.name?.startsWith('api_');
      }).map(node => {
        // For billeder, konverter noden til kun at være file input
        if (node.name?.includes('api_img')) {
          return {
            ...node,
            type: 'image',
            // Bevar kun de nødvendige properties for file upload
            textContent: '', // Fjern tekstindhold
            isImageUpload: true // Marker som file upload
          };
        }
        return node;
      });
    },

    containerStyle() {
      // Beregn aspect ratio baseret på artboard dimensioner
      const aspectRatio = (this.artboardHeight / this.artboardWidth) * 100;
      return {
        paddingBottom: `${aspectRatio}%`, // Bevarer aspect ratio
        height: 0 // Nødvendig for padding-bottom trick
      };
    },
    
    canvasStyle() {
      return {
        width: `${this.artboardWidth}px`,
        height: `${this.artboardHeight}px`,
        transform: `scale(${this.scale})`,
        transformOrigin: '0 0',
        background: this.backgroundColor,
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
        userSelect: 'none'
      };
    },

    requiredFonts() {
      // Saml alle unikke fonte der bruges
      const fonts = new Set();
      this.nodes.forEach(node => {
        if (node.type === 'text' && node.fontId) {
          fonts.add({
            family: node.fontId,
            weight: node.fontVariantId
          });
        }
      });
      return Array.from(fonts);
    }
  },

  mounted() {
    this.initializeScale();
    window.addEventListener('resize', this.initializeScale);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.initializeScale);
  },

  methods: {
    getNodeStyle(node) {
      return {
        position: 'absolute',
        left: `${node.x}px`,
        top: `${node.y}px`,
        width: `${node.width}px`,
        height: `${node.height}px`,
        transform: `rotate(${node.angle}deg)`,
        opacity: node.opacity,
        borderRadius: `${node.borderRadius}px`,
        backgroundColor: node.type === 'rect' ? node.background?.color : 'transparent',
        color: node.textColor,
        fontSize: `${node.fontSize}px`,
        fontFamily: node.fontFamily,
        textAlign: node.textAlign,
        lineHeight: node.lineHeight,
        letterSpacing: `${node.letterSpacing}px`,
        objectFit: node.objectFit || 'cover',
        border: node.borderWidth ? `${node.borderWidth}px solid ${node.borderColor}` : 'none',
        boxShadow: node.boxShadow ? 
          `${node.boxShadow.offsetX}px ${node.boxShadow.offsetY}px ${node.boxShadow.blurRadius}px ${node.boxShadow.spreadRadius}px ${node.boxShadow.color}` : 
          'none'
      }
    },

    getNodeByName(name) {
      return this.nodes.find(node => node.name === name) || { textContent: '' };
    },

    getReadableName(name) {
      // Fjerner 'api_' prefix og formaterer navnet pænt
      return name
        .replace('api_', '')
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },

    updateTemplate() {
      // Her kan du implementere logik til at gemme ændringer
      this.$emit('update:template', this.nodes);
    },

    selectNode(node) {
      this.selectedNode = node;
    },

    calculateScale() {
      const container = this.$el.querySelector('.preview-container');
      if (!container) return;
      
      const containerWidth = container.clientWidth;
      const containerHeight = containerWidth * (this.artboardHeight / this.artboardWidth);
      
      // Beregn scale baseret på container dimensioner
      const scaleX = containerWidth / this.artboardWidth;
      const scaleY = containerHeight / this.artboardHeight;
      
      // Brug den mindste scale-værdi for at sikre at alt indhold er synligt
      this.scale = Math.min(scaleX, scaleY);
    },

    initializeScale() {
      const container = this.$refs.previewContainer;
      if (!container) return;

      // Get container dimensions
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      // Calculate scale factors for both width and height
      const scaleX = (containerWidth - 40) / this.artboardWidth;
      const scaleY = (containerHeight - 40) / this.artboardHeight;

      // Use the smaller scale to maintain aspect ratio
      this.scale = Math.min(scaleX, scaleY);
    },

    handleImageUpload(event, node) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          node.src = e.target.result;
          this.updateTemplate();
        };
        reader.readAsDataURL(file);
      }
    },

    saveTemplate() {
      // Implementer gem funktionalitet her
      this.$emit('save', this.nodes);
    },

    async loadGoogleFonts() {
      const fonts = this.requiredFonts
        .map(font => `family=${font.family}:wght@${font.weight}`)
        .join('&');
      
      if (fonts) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `https://fonts.googleapis.com/css2?${fonts}&display=swap`;
        document.head.appendChild(link);
      }
    }
  }
}
</script>

<style scoped>
/* Tilføj øverst i style section */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&display=swap');

/* Base modal styling */
.modal-dialog.modal-xl {
  max-width: 1400px;
  margin: 1rem auto;
}

.modal-content {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
  will-change: transform;
  color: white;
}

/* Modal header */
.modal-header {
  background: rgba(255, 255, 255, 0.02);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
}

.modal-title {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  font-size: 1.25rem;
}

/* Input fields styling */
.form-control {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 12px 20px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-control:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(94, 114, 228, 0.3);
  box-shadow: 
    inset 0 2px 4px rgba(0, 0, 0, 0.1),
    0 0 0 3px rgba(94, 114, 228, 0.1);
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Preview container */
.preview-container {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
  padding: 1.5rem;
  height: 100%;
  min-height: 500px;
}

.moveable-container {
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

/* Nodes styling */
.c-node {
  backdrop-filter: blur(4px);
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.c-node:hover {
  box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.3);
}

/* Image upload styling */
.image-upload-container {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1rem;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.image-upload-container:hover {
  border-color: rgba(94, 114, 228, 0.3);
  background: rgba(94, 114, 228, 0.05);
}

.upload-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

/* Button styling */
.btn {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 8px 16px;
  color: white;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateZ(0);
  will-change: transform, background-color;
}

.btn:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.12);
}

.bg-gradient-success {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.15), 
    rgba(66, 186, 150, 0.25)
  );
  border-color: rgba(66, 186, 150, 0.3);
}

.bg-gradient-danger {
  background: linear-gradient(
    135deg, 
    rgba(245, 54, 92, 0.15), 
    rgba(245, 54, 92, 0.25)
  );
  border-color: rgba(245, 54, 92, 0.3);
}

/* Loading overlay styling */
.global-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  z-index: 9999;
}

.loading-content {
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.loading-text {
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Loading animation container */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* Loading balls */
.ball {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  animation: bounce 0.5s alternate infinite;
}

.ball:nth-child(2) { animation-delay: 0.1s; }
.ball:nth-child(3) { animation-delay: 0.2s; }
.ball:nth-child(4) { animation-delay: 0.3s; }
.ball:nth-child(5) { animation-delay: 0.4s; }
.ball:nth-child(6) { animation-delay: 0.5s; }
.ball:nth-child(7) { animation-delay: 0.6s; }

@keyframes bounce {
  from {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  to {
    transform: translateY(-10px) scale(0.8);
    opacity: 0.5;
  }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .modal-dialog.modal-xl {
    margin: 0.5rem;
  }
  
  .row {
    flex-direction: column;
  }
  
  .col-4, .col-7 {
    width: 100%;
    max-width: 100%;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    transition-duration: 0.01ms !important;
  }
  
  .ball {
    animation: none;
  }
  
  .loading-content {
    animation: none;
  }
}
</style>
