<template>
  <div v-if="loading" class="loading">Indlæser...</div>
  <article v-else-if="post" class="article-detail__main">
    <div class="article-banner" :style="{ backgroundImage: `url(${post.bannerImage})` }">
      <div class="article-banner__overlay">
        <div class="article-banner__content">
          <div class="article-banner__category">{{ post.category }}</div>
          <h1 class="article-banner__title">{{ post.title }}</h1>
          <div class="article-banner__date">{{ formatDate(post.date) }}</div>
        </div>
      </div>
    </div>
    <div class="article-content">
      <ul class="article-breadcrumbs">
        <li class="article-breadcrumbs__link"><a href="/blog">Blog</a></li>
        <li class="article-breadcrumbs__separator">/</li>
        <li class="article-breadcrumbs__category">{{ post.category }}</li>
      </ul>
      <div class="article-body__intro" v-html="post.intro"></div>
      <div class="article-body__text" v-html="post.content"></div>
    </div>
  </article>
  <div v-else-if="error" class="error">{{ error }}</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import { useRoute } from 'vue-router';

const route = useRoute();
const post = ref(null);
const loading = ref(true);
const error = ref(null);

const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('da-DK', { year: 'numeric', month: 'long', day: 'numeric' });
};

onMounted(async () => {
  const id = route.params.id;
  console.log('Document ID:', id);
  
  try {
    const docRef = doc(db, 'blogPosts', id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      post.value = { id: docSnap.id, ...docSnap.data() };
      console.log('Post data:', post.value);
    } else {
      console.log("Ingen sådan dokument!");
      error.value = "Blogindlægget blev ikke fundet.";
    }
  } catch (err) {
    console.error("Fejl ved hentning af dokument:", err);
    error.value = "Der opstod en fejl ved indlæsning af blogindlægget.";
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped>
.article-detail__main {
  max-width: 100%;
  background-color: #2a2a2a;
  color: #ffffff;
}

.article-banner {
  height: 400px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.article-banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.8));
  display: flex;
  align-items: flex-end;
}

.article-banner__content {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.article-banner__category {
  font-size: 0.9rem;
  color: #8a4baf;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.article-banner__title {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.article-banner__date {
  font-size: 0.9rem;
  color: #cccccc;
}

.article-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.article-breadcrumbs {
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
  display: flex;
}

.article-breadcrumbs li {
  margin-right: 0.5rem;
}

.article-breadcrumbs__link a {
  color: #8a4baf;
  text-decoration: none;
}

.article-breadcrumbs__separator {
  color: #666666;
}

.article-body__intro {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #cccccc;
  line-height: 1.6;
}

.article-body__text {
  line-height: 1.8;
}

.loading, .error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #cccccc;
}

@media (max-width: 768px) {
  .article-banner {
    height: 300px;
  }

  .article-banner__title {
    font-size: 2rem;
  }

  .article-content {
    padding: 1rem;
  }
}
</style>
