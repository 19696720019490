export const introSentences = {
    'Nyhed': [
      "{emoji} Nyhed! Vi har netop fået en {maerke} {model} på lager {emoji}",
      "Sensationen er landet: {maerke} {model} er her!",
      "Opdag vores nyeste tilføjelse: {maerke} {model} {emoji}",
      "{emoji} Gå ikke glip af denne nye {maerke} {model}!",
      "{maerke} {model} – vores nyeste ankomst!",
      "Vi præsenterer stolt den nye {maerke} {model} {emoji}",
      "Ny bil i vores sortiment: {maerke} {model} {emoji}",
      "Oplev den splinternye {maerke} {model} hos os!",
      "{emoji} Frisk på lager: {maerke} {model}!",
      "Kom og se vores nye {maerke} {model} {emoji}",
    ],
    'Ny bil på lager': [
      "{emoji} Vi har fået en ny {biltype} på lager: {maerke} {model} {emoji}",
      "Ny bil på hylden: {maerke} {model}",
      "Kom og oplev vores nye {maerke} {model} i dag!",
      "Nyankommet: {farve} {maerke} {model} fra {aar}",
      "Vi har netop modtaget en {maerke} {model} – kom og se!",
      "{emoji} Frisk importeret: {maerke} {model}",
      "Ny på lager: {maerke} {model} {emoji}",
      "Din drømmebil er ankommet: {maerke} {model}",
      "Nyhed i butikken: {maerke} {model}",
      "Se vores nyeste bil: {maerke} {model} {emoji}",
    ],
    'Tilbud': [
      "{emoji} Fantastisk tilbud på {maerke} {model}!",
      "Specialpris på {maerke} {model} – kun i begrænset tid!",
      "Gør et kup med denne {maerke} {model} {emoji}",
      "Tilbud: Spar penge på {maerke} {model} nu!",
      "Vi har et skarpt tilbud på {maerke} {model}",
      "{maerke} {model} til en uimodståelig pris!",
      "Få din nye {maerke} {model} til tilbudspris!",
      "Eksklusivt tilbud på {maerke} {model} {emoji}",
      "Drømmebil til drømmepris: {maerke} {model}",
      "{emoji} Nedsat pris på {maerke} {model}!",
    ],
    'Prisjustering': [
      "Prisen er netop sat ned på {maerke} {model}!",
      "{emoji} Prisjustering på {maerke} {model}!",
      "Nu endnu billigere: {maerke} {model} til {pris}",
      "Vi har sænket prisen på {maerke} {model}!",
      "Spar penge! Ny pris på {maerke} {model}",
      "Prisreduktion: {maerke} {model} er blevet billigere!",
      "{emoji} Prisfald på {maerke} {model}!",
      "Gode nyheder – lavere pris på {maerke} {model}",
      "Tjek den nye pris på {maerke} {model}",
      "Opdateret pris: {maerke} {model} til en bedre pris!",
    ],
    'Dagens bil': [
      "Dagens bil: {maerke} {model} {emoji}",
      "Vi fremhæver i dag vores {maerke} {model}",
      "Tag et kig på dagens udvalgte: {maerke} {model}",
      "{emoji} Dagens anbefaling: {maerke} {model}",
      "Hele dagens fokus er på {maerke} {model}",
      "Dagens highlight: {maerke} {model}",
      "Se nærmere på dagens bil: {maerke} {model}",
      "Vores bil i rampelyset i dag: {maerke} {model}",
      "{maerke} {model} er dagens stjerne!",
      "Dagens specielle bil: {maerke} {model} {emoji}",
    ],
    'Ugens bil': [
      "Ugens bil er denne flotte {maerke} {model}!",
      "{emoji} Ugens udvalgte: {maerke} {model}",
      "Gå ikke glip af ugens bil: {maerke} {model}",
      "Vi præsenterer ugens bil: {maerke} {model}",
      "Ugens highlight er {maerke} {model}",
      "Topvalg denne uge: {maerke} {model}",
      "{emoji} Ugens favorit: {maerke} {model}",
      "Se hvorfor {maerke} {model} er ugens bil!",
      "Ugens mest populære: {maerke} {model}",
      "Fokus på ugens bil: {maerke} {model}",
    ],
    'Netop solgt': [
      "Netop solgt: {maerke} {model}",
      "Vi har lige solgt denne {farve} {maerke} {model}!",
      "{emoji} Endnu en glad kunde har købt en {maerke} {model}",
      "{maerke} {model} er netop kørt fra pladsen!",
      "Tak til vores kunde for købet af {maerke} {model}",
      "Vi fejrer salget af {maerke} {model}",
      "Solgt: {maerke} {model}",
      "Farvel til {maerke} {model} – solgt i dag!",
      "{emoji} Denne {maerke} {model} har fundet sin nye ejer!",
      "Endnu et salg: {maerke} {model}",
    ],
    'Eftertragtet model': [
      "Den eftertragtede {maerke} {model} er nu på lager!",
      "Folk spørger efter den, og vi har den: {maerke} {model}",
      "{emoji} Populære {maerke} {model} kan nu blive din!",
      "Sikre dig {maerke} {model} – en eftertragtet model!",
      "Begrænset antal: {maerke} {model} er her!",
      "Oplev den efterspurgte {maerke} {model} hos os",
      "{maerke} {model} – modellen alle vil have!",
      "Kom og se den populære {maerke} {model}",
      "Eftertragtet og nu tilgængelig: {maerke} {model}",
      "{emoji} Skaf dig {maerke} {model} før andre!",
    ],
    'Skarp pris': [
      "Skarp pris på {maerke} {model}!",
      "Få meget bil for pengene med {maerke} {model}",
      "{emoji} Vi tilbyder {maerke} {model} til en skarp pris!",
      "{maerke} {model} – kvalitet til lav pris!",
      "Super tilbud: {maerke} {model} til skarp pris",
      "Prisvenlig {maerke} {model} klar til dig!",
      "God pris på {maerke} {model} – kom og se!",
      "{emoji} Spar penge på {maerke} {model}",
      "Topkvalitet møder skarp pris: {maerke} {model}",
      "Dit bedste bilkøb: {maerke} {model} til skarp pris",
    ],
  };
  
  export const midSectionSentences = {
    'Nyhed': [
      "Denne bil er spækket med udstyr og klar til en ny ejer.",
      "Kom og oplev denne fantastiske bil før alle andre.",
      "Vær den første til at prøvekøre denne nyhed!",
      "Den nyeste teknologi samlet i én bil.",
      "Kvalitet og innovation mødes i denne model.",
      "Du vil elske at køre i denne nyhed!",
      "Fuld af moderne features og komfort.",
      "En ny standard for køreoplevelse.",
      "Design og ydeevne i topklasse.",
      "Perfekt kombination af stil og funktionalitet.",
    ],
    'Ny bil på lager': [
      "Den står klar til prøvekørsel hos os.",
      "Skynd dig, inden den bliver solgt!",
      "Book en fremvisning allerede i dag.",
      "Kom og se den før alle andre.",
      "Denne bil venter på sin nye ejer.",
      "Fantastisk stand og klar til dig.",
      "Gå ikke glip af denne mulighed.",
      "Kontakt os for at aftale en prøvetur.",
      "Først til mølle – denne bil er eftertragtet.",
      "Et besøg værd for enhver bilentusiast.",
    ],
    'Tilbud': [
      "Tilbuddet gælder kun i en begrænset periode.",
      "Få en ekstra god pris ved hurtig handel.",
      "Kontakt os for at høre mere om dette fantastiske tilbud.",
      "Spar penge uden at gå på kompromis med kvalitet.",
      "Eksklusive besparelser på denne model.",
      "Gør en god handel i dag!",
      "Tilbuddet er tidsbegrænset – slå til nu!",
      "Denne pris ser du ikke igen.",
      "Vi hjælper med attraktiv finansiering.",
      "Din drømmebil til drømmepris.",
    ],
    'Prisjustering': [
      "Vi har sænket prisen for at gøre plads til nye modeller.",
      "Grib chancen for at gøre en god handel nu.",
      "Den nye pris gør denne bil til et kup!",
      "Kvalitet til en endnu bedre pris.",
      "Prisen er faldet – værdien er den samme!",
      "Opdateret pris – samme fantastiske bil.",
      "Gør en besparelse ved køb i dag.",
      "Prisjustering til din fordel.",
      "Bedre pris – samme høje standard.",
      "Kontakt os for at drage fordel af prisfaldet.",
    ],
    'Dagens bil': [
      "Denne bil skiller sig ud med sit unikke design.",
      "Et perfekt valg til dig, der ønsker kvalitet og komfort.",
      "Den er velholdt og kører som en drøm.",
      "Gør din hverdag bedre med denne bil.",
      "Komfort og stil i ��n pakke.",
      "En sand perle på vejene.",
      "Du vil elske hvert øjeblik bag rattet.",
      "Klar til nye eventyr med dig.",
      "Gør en forskel i din kørsel i dag.",
      "En oplevelse ud over det sædvanlige.",
    ],
    'Ugens bil': [
      "Et populært valg blandt vores kunder.",
      "Kom forbi og se, hvorfor denne bil er ugens favorit.",
      "Vi tilbyder attraktive finansieringsmuligheder på denne model.",
      "Ugens bedste tilbud venter på dig.",
      "Denne bil har alt, hvad du behøver.",
      "En kombination af stil og ydeevne.",
      "Gør ugen endnu bedre med denne bil.",
      "Specielt udvalgt til dig denne uge.",
      "Et fremragende valg til en skarp pris.",
      "Tag en prøvetur og oplev forskellen.",
    ],
    'Netop solgt': [
      "Vi siger tillykke til den nye ejer!",
      "Hold øje med vores side for flere spændende biler.",
      "Kontakt os, hvis du også leder efter en lignende model.",
      "Endnu en tilfreds kunde har fundet sin bil.",
      "Tak for tilliden – vi er glade for at hjælpe.",
      "Denne model blev hurtigt solgt!",
      "Vi får løbende nye biler ind – stay tuned.",
      "Glæd dig til vores kommende nyheder.",
      "Vi hjælper gerne med at finde din drømmebil.",
      "Hold dig opdateret på vores seneste tilbud.",
    ],
    'Eftertragtet model': [
      "Denne model er kendt for sin pålidelighed og ydeevne.",
      "Et begrænset antal tilgængeligt – skynd dig!",
      "Oplev hvorfor denne bil er så eftertragtet.",
      "Gå ikke glip af chancen for at eje denne bil.",
      "Efterspurgt af mange – måske din tur nu.",
      "Eksklusiv mulighed for at eje en populær model.",
      "Bliv en del af eliten med denne bil.",
      "Vi forventer stor interesse – kontakt os i dag.",
      "Kør væk i en bil, som alle taler om.",
      "Din chance for at eje en eftertragtet klassiker.",
    ],
    'Skarp pris': [
      "Du får meget for pengene med denne bil.",
      "En kvalitetsbil til en pris, der er svær at slå.",
      "Kontakt os for at få et uforpligtende tilbud.",
      "Bedste pris på markedet – garanteret!",
      "Spar penge uden at gå på kompromis.",
      "En investering du ikke vil fortryde.",
      "Topkvalitet til lavpris.",
      "Et enestående tilbud til en skarp pris.",
      "Finansiering til favorable vilkår.",
      "Få luksus til budgetpris.",
    ],
  };
  
  export const outroSentences = {
    'Nyhed': [
      "Kom forbi og prøvekør den allerede i dag!",
      "Vi glæder os til at vise den frem for dig.",
      "Ring til os for mere information.",
      "Book en fremvisning nu!",
      "Kontakt os for at høre mere.",
      "Vi står klar til at hjælpe dig.",
      "Gør din bil drøm til virkelighed hos os.",
      "Lad os hjælpe dig med at finde den perfekte bil.",
      "Din nye bil venter på dig.",
      "Vi ser frem til dit besøg.",
    ],
    'Ny bil på lager': [
      "Denne bil venter på sin nye ejer – måske er det dig?",
      "Kontakt os for at høre mere.",
      "Vi tager gerne din gamle bil i bytte.",
      "Kom og se den før den er væk.",
      "Ring og book en prøvetur.",
      "Vi tilbyder fordelagtig finansiering.",
      "Gør en god handel i dag.",
      "Besøg os og oplev bilen selv.",
      "Vi hjælper dig med at komme godt videre.",
      "Din næste bil er kun et opkald væk.",
    ],
    'Tilbud': [
      "Tilbuddet gælder kun så længe lager haves.",
      "Slå til nu og gør en god handel!",
      "Vi står klar til at hjælpe dig med finansiering.",
      "Kontakt os inden tilbuddet udløber.",
      "Spar penge i dag – kontakt os nu.",
      "Begrænset tid – skynd dig!",
      "Få mere info ved at kontakte os.",
      "Lad os gøre din bil drøm til virkelighed.",
      "Book en prøvetur og oplev forskellen.",
      "Vi glæder os til at høre fra dig.",
    ],
    'Prisjustering': [
      "Kom og se bilen hos os.",
      "Ring for at aftale en prøvetur.",
      "Vi tilbyder attraktive finansieringsmuligheder.",
      "Gør en god handel i dag.",
      "Din chance for at få en kvalitetsbil til lavere pris.",
      "Kontakt os for mere information.",
      "Vi hjælper dig med at komme bag rattet.",
      "Tilbuddet gælder kun i begrænset tid.",
      "Besøg os og oplev bilen selv.",
      "Lad os finde den rette løsning for dig.",
    ],
    'Dagens bil': [
      "Denne mulighed vil du ikke gå glip af!",
      "Vi ser frem til at byde dig velkommen.",
      "Kontakt os for mere information.",
      "Book en prøvetur i dag.",
      "Gør dagen endnu bedre med en ny bil.",
      "Vi står klar til at hjælpe dig.",
      "Lad os gøre din bil drøm til virkelighed.",
      "Besøg os og se bilen i virkeligheden.",
      "Din drømmebil er tættere på end du tror.",
      "Vi glæder os til at se dig.",
    ],
    'Ugens bil': [
      "Book en prøvetur inden den er væk.",
      "Gør ugens bedste handel hos os!",
      "Vi hjælper dig med en god finansieringsaftale.",
      "Kontakt os for mere info.",
      "Denne uge er det din tur til at få ny bil.",
      "Vi ser frem til at høre fra dig.",
      "Skynd dig – ugens bil er populær.",
      "Lad os hjælpe dig med at komme bag rattet.",
      "Besøg os og oplev bilen selv.",
      "Din tilfredshed er vores mål.",
    ],
    'Netop solgt': [
      "Vi har mange andre spændende biler på lager.",
      "Kontakt os for at finde din drømmebil.",
      "Tak for tilliden til vores kunder.",
      "Vi hjælper gerne med at finde din næste bil.",
      "Hold øje med vores nyheder.",
      "Vi får løbende nye modeller ind.",
      "Din drømmebil er måske vores næste nyhed.",
      "Vi ser frem til at hjælpe dig.",
      "Tak for endnu et godt salg.",
      "Vi er her for at opfylde dine bilbehov.",
    ],
    'Eftertragtet model': [
      "Vent ikke for længe – den forsvinder hurtigt.",
      "Book en fremvisning nu.",
      "Vi hjælper med attraktiv finansiering.",
      "Gør en god investering i dag.",
      "Kontakt os for mere information.",
      "Denne bil kan blive din – kontakt os.",
      "Besøg os for at opleve bilen selv.",
      "Tag beslutningen i dag og kør væk i morgen.",
      "Vi ser frem til at høre fra dig.",
      "Lad os gøre din bil drøm til virkelighed.",
    ],
    'Skarp pris': [
      "Denne pris finder du ikke andre steder.",
      "Kontakt os i dag og hør mere.",
      "Finansiering uden udbetaling tilbydes.",
      "Gør en god handel før det er for sent.",
      "Vi står klar til at hjælpe dig.",
      "Book en prøvetur og oplev værdien.",
      "Spar penge og få kvalitet.",
      "Din nye bil venter på dig.",
      "Vi glæder os til at vise den frem for dig.",
      "Kontakt os for et uforpligtende tilbud.",
    ],
  };
  
  export const linkSentences = [
    "Se mere til bilen her: {url} 📲",
    "Få alle detaljerne her: {url} 💻",
    "Læs mere om bilen på vores hjemmeside: {url} 🌐",
    "Klik her for at se flere billeder og info: {url} 🖥️",
    "Besøg linket for mere information: {url} 🔗",
    "Se alle specifikationer her: {url} 📋",
    "Oplev bilen online her: {url} 🚗",
    "Find flere detaljer via linket: {url} 🔍",
    "Tjek bilen ud på vores side: {url} 🏁",
    "Din næste bil er kun ét klik væk: {url} 🖱️",
  ];

  export const karruselIntroSentences = {
    'Nyheder': [
      "{emoji} Se vores seneste nyheder! Vi har fået {antal} spændende biler på lager",
      "Friske nyheder! {antal} nye biler er netop ankommet {emoji}",
      "{emoji} Spændende nyt! Tjek vores {antal} seneste tilføjelser",
      "Nyhed x{antal}! Se vores seneste biler {emoji}",
      "Vi har travlt! {antal} nye biler er landet hos os {emoji}"
    ],
    'Nye priser': [
      "{emoji} Vi har justeret priserne på {antal} af vores biler!",
      "Nye priser på {antal} udvalgte biler {emoji}",
      "Prisopdatering! Se de nye priser på {antal} biler {emoji}",
      "{emoji} Prisændringer på {antal} populære modeller",
      "Vi har sænket priserne! Se {antal} gode tilbud {emoji}"
    ],
    'Populære biler': [
      "Se vores top {antal} mest populære biler lige nu {emoji}",
      "{emoji} {antal} eftertragtede biler venter på dig!",
      "Kundefavoritter! Her er {antal} af vores mest populære biler {emoji}",
      "Tjek disse {antal} populære modeller ud {emoji}",
      "{emoji} {antal} biler der fanger alles opmærksomhed"
    ]
  };

  export const karruselMidSectionSentences = {
    'Nyheder': [
      "Swipe for at se alle de spændende nyheder!",
      "Der er noget for enhver smag i denne omgang.",
      "Vi er stolte af at præsentere disse nye tilføjelser.",
      "Kvalitet og variation i alle prisklasser.",
      "Kom forbi og se alle nyhederne med egne øjne!"
    ],
    // ... tilføj flere kategorier efter behov ...
  };

  export const karruselOutroSentences = {
    'Nyheder': [
      "Book en fremvisning af dine favoritter i dag!",
      "Vi står klar til at vise dig alle bilerne.",
      "Kom forbi og lad os finde den perfekte bil til dig.",
      "Ring til os for mere information om bilerne.",
      "Vi hjælper dig med at vælge den rigtige bil blandt nyhederne."
    ],
    // ... tilføj flere kategorier efter behov ...
  };