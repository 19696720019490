<template>
  <main class="main-content">
    <div class="filter-section card mt-4">
      <!-- Header med toggle knap -->
      <div class="filter-header" @click="toggleFilterSection">
        <div class="d-flex justify-content-between align-items-center p-3">
          <span class="text-muted">
            Viser {{ filteredCars.length }} af {{ cars.length }} biler
          </span>
          <button class="btn-toggle">
            {{ isFilterExpanded ? 'Skjul filtre' : 'Vis filtre' }}
            <i class="fas" :class="isFilterExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
          </button>
        </div>
      </div>

      <!-- Filter indhold -->
      <div class="filter-content" :class="{ 'expanded': isFilterExpanded }">
        <div class="card-body">
          <!-- Gemte filtre sektion -->
          <div class="saved-filters-bar mb-3" v-if="savedFilters.length || excludedFilters.length">
            <div class="d-flex align-items-center gap-2">
              <div class="filter-type-selector">
                <select 
                  class="form-select"
                  v-model="activeFilterTab"
                >
                  <option value="saved">Gemte Filtre ({{ savedFilters.length }})</option>
                  <option value="excluded">Ekskluderede ({{ excludedFilters.length }})</option>
                </select>
              </div>
              
              <div class="filter-chips flex-grow-1" style="max-height: none;">
                <template v-if="activeFilterTab === 'saved'">
                  <div v-for="filter in savedFilters" :key="filter.id" class="filter-chip-container">
                    <button
                      class="filter-chip"
                      :class="{ 'active': activeFilter?.id === filter.id }"
                      @click="applyFilter(filter)"
                    >
                      {{ filter.name }}
                      <span class="badge ms-1">{{ filter.cars.length }}</span>
                    </button>
                    <div class="filter-actions">
                      <!-- Fjern denne redigér-knap -->
                      <!-- <button 
                        class="filter-chip action-small"
                        @click="editFilter(filter)"
                      >
                        <i class="fas fa-edit"></i>
                      </button> -->
                      <button 
                        class="filter-chip action-danger-small"
                        @click="deleteFilter(filter.id, 'filteredCars')"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div v-for="exclude in excludedFilters" :key="exclude.id" class="filter-chip-container">
                    <button
                      class="filter-chip"
                      :class="{ 'active': activeFilter?.id === exclude.id }"
                    >
                      {{ new Date(exclude.createdAt.seconds * 1000).toLocaleDateString() }}
                      <span class="badge ms-1">{{ exclude.cars.length }}</span>
                    </button>
                    <div class="filter-actions">
                      <!-- Fjern denne redigér-knap -->
                      <!-- <button 
                        class="filter-chip action-small"
                        @click="editExclude(exclude)"
                      >
                        <i class="fas fa-edit"></i>
                      </button> -->
                      <button 
                        class="filter-chip action-danger-small"
                        @click="deleteFilter(exclude.id, 'excludeCars')"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </template>
              </div>

              <button 
                v-if="activeFilter"
                class="filter-chip action-danger"
                @click="clearActiveFilter"
              >
                Ryd filter
              </button>
            </div>
          </div>

          <!-- Eksisterende filter rows -->
          <div class="row">
            <!-- Bilmærker -->
            <div class="col-md-4 mb-3">
              <label>Bilmærker ({{ availableBrands.length }})</label>
              <div class="filter-chips" :class="{ 'collapsed': !expandedSections.brands }">
                <button
                  v-for="brand in visibleBrands"
                  :key="brand"
                  class="filter-chip"
                  :class="{ 'active': selectedBrands.includes(brand) }"
                  @click="toggleFilter('brand', brand)"
                >
                  {{ brand }} ({{ cars.filter(car => car.fields?.Mærke === brand).length }})
                </button>
              </div>
              <button 
                v-if="availableBrands.length > initialVisibleItems" 
                class="expand-button"
                @click="toggleSection('brands')"
              >
                {{ expandedSections.brands ? 'Vis færre' : `Vis alle (${availableBrands.length})` }}
              </button>
            </div>

            <!-- Drivmiddel -->
            <div class="col-md-4 mb-3">
              <label>Drivmiddel ({{ availableFuelTypes.length }})</label>
              <div class="filter-chips" :class="{ 'collapsed': !expandedSections.fuelTypes }">
                <button
                  v-for="type in visibleFuelTypes"
                  :key="type"
                  class="filter-chip"
                  :class="{ 'active': selectedFuelTypes.includes(type) }"
                  @click="toggleFilter('fuelType', type)"
                >
                  {{ type }} ({{ cars.filter(car => car.fields?.Brændstoftype === type).length }})
                </button>
              </div>
              <button 
                v-if="availableFuelTypes.length > initialVisibleItems" 
                class="expand-button"
                @click="toggleSection('fuelTypes')"
              >
                {{ expandedSections.fuelTypes ? 'Vis færre' : `Vis alle (${availableFuelTypes.length})` }}
              </button>
            </div>

            <!-- Kategori -->
            <div class="col-md-4 mb-3">
              <label>Kategori ({{ availableCategories.length }})</label>
              <div class="filter-chips" :class="{ 'collapsed': !expandedSections.categories }">
                <button
                  v-for="category in visibleCategories"
                  :key="category"
                  class="filter-chip"
                  :class="{ 'active': selectedCategories.includes(category) }"
                  @click="toggleFilter('category', category)"
                >
                  {{ category }} ({{ cars.filter(car => car.categoryName === category).length }})
                </button>
              </div>
              <button 
                v-if="availableCategories.length > initialVisibleItems" 
                class="expand-button"
                @click="toggleSection('categories')"
              >
                {{ expandedSections.categories ? 'Vis færre' : `Vis alle (${availableCategories.length})` }}
              </button>
            </div>
          </div>

          <div class="row">
            <!-- Årgang -->
            <div class="col-md-4 mb-3">
              <label>Årgang ({{ yearRange.min }} - {{ yearRange.max }})</label>
              <div class="d-flex">
                <input 
                  type="number" 
                  v-model="selectedYearRange.from" 
                  class="form-control mr-2" 
                  :placeholder="`Fra ${yearRange.min}`"
                  :min="yearRange.min"
                  :max="yearRange.max"
                >
                <input 
                  type="number" 
                  v-model="selectedYearRange.to" 
                  class="form-control" 
                  :placeholder="`Til ${yearRange.max}`"
                  :min="yearRange.min"
                  :max="yearRange.max"
                >
              </div>
            </div>

            <!-- Pris -->
            <div class="col-md-4 mb-3">
              <label>Pris ({{ priceRange.min.toLocaleString() }} - {{ priceRange.max.toLocaleString() }} kr)</label>
              <div class="d-flex">
                <input 
                  type="number" 
                  v-model="selectedPriceRange.from" 
                  class="form-control mr-2" 
                  :placeholder="`Fra ${priceRange.min.toLocaleString()}`"
                  :min="priceRange.min"
                  :max="priceRange.max"
                >
                <input 
                  type="number" 
                  v-model="selectedPriceRange.to" 
                  class="form-control" 
                  :placeholder="`Til ${priceRange.max.toLocaleString()}`"
                  :min="priceRange.min"
                  :max="priceRange.max"
                >
              </div>
            </div>

            <!-- Gem/Ekskluder knapper -->
            <div class="col-md-4 mb-3">
              <label>Handlinger</label>
              <div class="d-flex">
                <button 
                  class="filter-chip action me-2 flex-grow-1" 
                  @click="handleSaveFilter"
                >
                  {{ activeFilter ? 'Opdater filter' : 'Gem nyt filter' }}
                </button>
                <button 
                  class="filter-chip action-danger flex-grow-1" 
                  @click="saveExcludeFilter"
                >
                  Ekskluder biler
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- Biler Galleri -->
      <div v-if="filteredCars && filteredCars.length > 0" class="card-body">
        <div class="row g-4">
          <div v-for="car in filteredCars" :key="car.id" class="col-auto">
            <div 
              class="card car-card"
              :class="{ 'selected': selectedCar === car.id }"
              @click="selectCar(car.id)"
            >
              <div class="card-body">
                <div class="car-image">
                  <img
                    :src="car.attachments.image_1"
                    :alt="car.headline"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div class="card-footer">
                <h6 class="mb-0 text-truncate">{{ car.headline }}</h6>
                <div class="d-flex justify-content-between align-items-center">
                  <small class="text-muted">ID: {{ car.id }}</small>
                  <div v-if="selectedCar === car.id" class="d-flex gap-2">
                    <button 
                      v-if="!newImage"
                      @click.stop="removeBackground(car)" 
                      :disabled="isLoading"
                      class="btn-sm action-button"
                    >
                      {{ isLoading ? 'Fjerner...' : 'Fjern baggrund' }}
                    </button>
                    <div v-else class="d-flex gap-1">
                      <button @click.stop="saveNewImage" class="btn-sm action-button save">Gem</button>
                      <button @click.stop="cancelNewImage" class="btn-sm action-button cancel">Annuller</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p
        v-else-if="cars && cars.length === 0"
        class="text-center mt-4"
      >
        Ingen biler fundet
      </p>

      <p v-if="error" class="text-center mt-4 text-danger">
        {{ error }}
      </p>

      <!-- Tilføj efter filter-section men før biler-galleriet -->
      <div class="modal" :class="{ 'show': showEditModal }" v-if="showEditModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ editingFilter ? 'Rediger Filter' : 'Rediger Eksklusion' }}</h5>
              <button type="button" class="close" @click="closeEditModal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="editingFilter">
                <div class="form-group">
                  <label>Navn</label>
                  <input 
                    type="text" 
                    v-model="editingFilter.name" 
                    class="form-control"
                  >
                </div>
                <!-- Vis andre filter kriterier her -->
              </div>
            </div>
            <div class="modal-footer">
              <button 
                class="filter-chip action-danger" 
                @click="closeEditModal"
              >
                Annuller
              </button>
              <button 
                class="filter-chip action" 
                @click="saveFilterChanges"
              >
                Gem ændringer
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Tilføj denne modal -->
      <div class="modal" :class="{ 'show': showNewFilterModal }" v-if="showNewFilterModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Gem nyt filter</h5>
              <button type="button" class="close" @click="closeNewFilterModal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>Navn på filter</label>
                <input 
                  type="text" 
                  v-model="newFilterName" 
                  class="form-control"
                  placeholder="Indtast navn på filter"
                  ref="filterNameInput"
                >
              </div>
            </div>
            <div class="modal-footer">
              <button 
                class="filter-chip action-danger" 
                @click="closeNewFilterModal"
              >
                Annuller
              </button>
              <button 
                class="filter-chip action" 
                @click="saveNewFilter"
                :disabled="!newFilterName.trim()"
              >
                Gem filter
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Tilføj Notifications komponenten -->
      <Notifications 
        :message="notificationMessage" 
        :show="showNotification"
        :title="notificationTitle"
        :color="notificationColor"
        @update:show="showNotification = $event"
      />

  </main>
</template>

<script setup>
import { onMounted, ref, computed, nextTick, watch } from "vue";
import { collection, getDocs, doc, collectionGroup, addDoc, onSnapshot, query, where, updateDoc, getDoc, deleteDoc, setDoc, getFirestore } from 'firebase/firestore';
import { db, auth } from '@/firebase';
import { useStore } from 'vuex';
import Notifications from './Notifications.vue'; 
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useUserContext } from '@/composables/useUserContext'

const { contextUserId, canAccessOtherUsers } = useUserContext()

const store = useStore();
const cars = ref([]);
const error = ref(null);
const selectedCar = ref(null);
const searchQuery = ref('');

// Tilføj denne computed property for at få den profilerede bruger
const profileUser = computed(() => store.state.setImpersonatedUser || store.getters.activeUser);

// Dynamiske computed properties for filter-muligheder
const availableBrands = computed(() => {
  const brands = cars.value
    .map(car => car.fields?.Mærke)
    .filter(brand => brand)
    .reduce((unique, brand) => {
      if (!unique.includes(brand)) {
        unique.push(brand);
      }
      return unique;
    }, []);
  return brands.sort((a, b) => a.localeCompare(b));
});

const availableFuelTypes = computed(() => {
  const fuelTypes = cars.value
    .map(car => car.fields?.Brændstoftype)
    .filter(type => type)
    .reduce((unique, type) => {
      if (!unique.includes(type)) {
        unique.push(type);
      }
      return unique;
    }, []);
  return fuelTypes.sort((a, b) => a.localeCompare(b));
});

const availableCategories = computed(() => {
  const categories = cars.value
    .map(car => car.categoryName)
    .filter(category => category)
    .reduce((unique, category) => {
      if (!unique.includes(category)) {
        unique.push(category);
      }
      return unique;
    }, []);
  return categories.sort((a, b) => a.localeCompare(b));
});

// Dynamiske min/max værdier for ranges
const yearRange = computed(() => {
  const years = cars.value
    .map(car => parseInt(car.fields?.Årgang))
    .filter(year => !isNaN(year));
  return {
    min: Math.min(...years),
    max: Math.max(...years)
  };
});

const priceRange = computed(() => {
  const prices = cars.value
    .map(car => car.priceInt)
    .filter(price => !isNaN(price));
  return {
    min: Math.min(...prices),
    max: Math.max(...prices)
  };
});

// Filter state
const selectedBrands = ref([]);
const selectedFuelTypes = ref([]);
const selectedCategories = ref([]);
const selectedYearRange = ref({ from: null, to: null });
const selectedPriceRange = ref({ from: null, to: null });

// Opdateret filteredCars computed property
const filteredCars = computed(() => {
  return cars.value.filter(car => {
    // Brand filter
    if (selectedBrands.value.length && !selectedBrands.value.includes(car.fields?.Mærke)) {
      return false;
    }

    // Fuel type filter
    if (selectedFuelTypes.value.length && !selectedFuelTypes.value.includes(car.fields?.Brændstoftype)) {
      return false;
    }

    // Category/Biltype filter
    if (selectedCategories.value.length && !selectedCategories.value.includes(car.categoryName)) {
      return false;
    }

    // Year range filter
    const carYear = parseInt(car.fields?.Årgang);
    if (selectedYearRange.value.from && carYear < selectedYearRange.value.from) {
      return false;
    }
    if (selectedYearRange.value.to && carYear > selectedYearRange.value.to) {
      return false;
    }

    // Price range filter
    const carPrice = car.priceInt;
    if (selectedPriceRange.value.from && carPrice < selectedPriceRange.value.from) {
      return false;
    }
    if (selectedPriceRange.value.to && carPrice > selectedPriceRange.value.to) {
      return false;
    }

    // Søgetekst filter
    if (searchQuery.value) {
      const searchText = searchQuery.value.toLowerCase();
      const searchableFields = {
        mærke: car.fields?.Mærke,
        model: car.fields?.Model,
        variant: car.fields?.Variant,
        headline: car.headline,
        description: car.description,
        brændstof: car.fields?.Brændstoftype,
        kilometer: car.fields?.Kilometer,
        årgang: car.fields?.Årgang,
        udstyr: car.fields?.Udstyr
      };
      
      return Object.values(searchableFields).some(value => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchText);
        }
        if (typeof value === 'number') {
          return value.toString().includes(searchText);
        }
        return false;
      });
    }

    return true;
  });
});

async function fetchCarsFromFirestore() {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('Ingen bruger er logget ind');
    }

    let carsQuery;

    if (canAccessOtherUsers.value) {
      if (contextUserId.value !== store.state.activeUser?.id) {
        // Hvis vi ser på en anden brugers profil
        const userDocRef = doc(db, 'users', contextUserId.value);
        carsQuery = collection(userDocRef, 'dealerCars');
      } else {
        // Hent alle biler fra alle forhandlere
        carsQuery = collectionGroup(db, 'dealerCars');
      }
    } else {
      // For almindelige brugere, hent kun deres egne biler
      const userDocRef = doc(db, 'users', contextUserId.value);
      carsQuery = collection(userDocRef, 'dealerCars');
    }

    const carsSnapshot = await getDocs(carsQuery);
    cars.value = carsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      dealerId: doc.ref.parent.parent?.id || 'Unknown'
    }));

    error.value = null;
  } catch (e) {
    console.error("Fejl ved hentning af biler fra Firestore:", e);
    error.value = "Der opstod en fejl ved hentning af biler. Prøv venligst igen senere.";
    cars.value = [];
  }
}

function viewCarDetails(id) {
  console.log(`Vis detaljer for bil med ID: ${id}`);
  // Implementer logik for at vise bildetaljer her
}

function selectCar(id) {
  selectedCar.value = id;
  // Her kan du tilføje yderligere logik, f.eks. at vise detaljer for den valgte bil
}

function downloadCarsAsJSON() {
  const carsData = JSON.stringify(cars.value, null, 2);
  const blob = new Blob([carsData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'cars.json';
  a.click();
  URL.revokeObjectURL(url);
}

// Toggle filter funktion
const toggleFilter = (filterType, value) => {
  switch (filterType) {
    case 'brand':
      if (selectedBrands.value.includes(value)) {
        selectedBrands.value = selectedBrands.value.filter(brand => brand !== value);
      } else {
        selectedBrands.value.push(value);
      }
      break;
    case 'fuelType':
      if (selectedFuelTypes.value.includes(value)) {
        selectedFuelTypes.value = selectedFuelTypes.value.filter(type => type !== value);
      } else {
        selectedFuelTypes.value.push(value);
      }
      break;
    case 'category':
      if (selectedCategories.value.includes(value)) {
        selectedCategories.value = selectedCategories.value.filter(cat => cat !== value);
      } else {
        selectedCategories.value.push(value);
      }
      break;
  }
};

const initialVisibleItems = 6; // Antal synlige items som standard

// State for expanded sections
const expandedSections = ref({
  brands: false,
  fuelTypes: false,
  categories: false
});

// Computed properties for visible items
const visibleBrands = computed(() => {
  return expandedSections.value.brands 
    ? availableBrands.value 
    : availableBrands.value.slice(0, initialVisibleItems);
});

const visibleFuelTypes = computed(() => {
  return expandedSections.value.fuelTypes 
    ? availableFuelTypes.value 
    : availableFuelTypes.value.slice(0, initialVisibleItems);
});

const visibleCategories = computed(() => {
  return expandedSections.value.categories 
    ? availableCategories.value 
    : availableCategories.value.slice(0, initialVisibleItems);
});

// Toggle expand/collapse
const toggleSection = (section) => {
  expandedSections.value[section] = !expandedSections.value[section];
};

// Ensure selected items are always visible
const ensureSelectedItemsVisible = (items, selectedItems, visibleCount) => {
  const result = [...items];
  selectedItems.forEach(selected => {
    if (!result.slice(0, visibleCount).includes(selected)) {
      const index = result.indexOf(selected);
      if (index !== -1) {
        result.splice(index, 1);
        result.unshift(selected);
      }
    }
  });
  return result;
};

// Tilføj state for filter expansion
const isFilterExpanded = ref(false);

// Toggle filter sektion
const toggleFilterSection = () => {
  isFilterExpanded.value = !isFilterExpanded.value;
};

const saveFilter = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Ingen bruger er logget ind');

    const userDocRef = doc(db, 'users', user.uid);
    const filterCollectionRef = collection(userDocRef, 'filteredCars');

    // Hent antal eksisterende filtre
    const existingFilters = await getDocs(filterCollectionRef);
    const filterNumber = existingFilters.size + 1;

    // Gem filteret med komplet bil-data
    const filterDoc = await addDoc(filterCollectionRef, {
      name: `Filter ${filterNumber}`,
      criteria: {
        brands: selectedBrands.value,
        fuelTypes: selectedFuelTypes.value,
        categories: selectedCategories.value,
        yearRange: selectedYearRange.value,
        priceRange: selectedPriceRange.value
      },
      createdAt: new Date(),
      cars: filteredCars.value.map(car => ({
        id: car.id,
        fields: car.fields,
        headline: car.headline,
        description: car.description,
        attachments: car.attachments,
        priceInt: car.priceInt,
        categoryName: car.categoryName,
        dealerId: car.dealerId,
        // Tilføj andre relevante felter her
        createdAt: new Date()
      }))
    });

    setupFilterListener(filterDoc.id, 'filteredCars');
    showToast('Filter gemt succesfuldt!', 'Succes', 'success');
  } catch (error) {
    console.error('Fejl ved gemning af filter:', error);
    showToast('Der skete en fejl ved gemning af filteret', 'Fejl', 'danger');
  }
};

const saveExcludeFilter = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Ingen bruger er logget ind');

    const userDocRef = doc(db, 'users', user.uid);
    const excludeCollectionRef = collection(userDocRef, 'excludeCars');

    // Gem ekskluderede biler med komplet bil-data
    const excludeDoc = await addDoc(excludeCollectionRef, {
      criteria: {
        brands: selectedBrands.value,
        fuelTypes: selectedFuelTypes.value,
        categories: selectedCategories.value,
        yearRange: selectedYearRange.value,
        priceRange: selectedPriceRange.value
      },
      createdAt: new Date(),
      cars: filteredCars.value.map(car => ({
        id: car.id,
        fields: car.fields,
        headline: car.headline,
        description: car.description,
        attachments: car.attachments,
        priceInt: car.priceInt,
        categoryName: car.categoryName,
        dealerId: car.dealerId,
        // Tilføj andre relevante felter her
        createdAt: new Date()
      }))
    });

    setupFilterListener(excludeDoc.id, 'excludeCars');
    showToast('Ekskluderede biler gemt succesfuldt!', 'Succes', 'success');
  } catch (error) {
    console.error('Fejl ved gemning af ekskluderede biler:', error);
    showToast('Der skete en fejl ved gemning af ekskluderede biler', 'Fejl', 'danger');
  }
};

// Opdater setupFilterListener funktionen
const setupFilterListener = (filterId, collectionName) => {
  const user = auth.currentUser;
  if (!user) return;

  const userDocRef = doc(db, 'users', user.uid);
  const filterDocRef = doc(userDocRef, collectionName, filterId);

  return onSnapshot(filterDocRef, async (filterDoc) => {
    if (!filterDoc.exists()) return;

    const filterData = filterDoc.data();
    const existingCars = new Set(filterData.cars.map(car => car.id));
    
    // Hent kun biler fra den aktuelle forhandler
    let carsQuery;
    const activeUser = store.getters.activeUser;

    if (activeUser.role === 1 || activeUser.role === 2) { // Admin rolle eller rolle 2
      if (profileUser.value && profileUser.value.id !== activeUser.id) {
        // Hvis vi ser på en anden brugers profil
        const userDocRef = doc(db, 'users', profileUser.value.id);
        carsQuery = collection(userDocRef, 'dealerCars');
      } else {
        // Hent alle biler fra alle forhandlere
        carsQuery = collectionGroup(db, 'dealerCars');
      }
    } else {
      // For rolle 3 (forhandler), hent kun deres egne biler
      const userDocRef = doc(db, 'users', user.uid);
      carsQuery = collection(userDocRef, 'dealerCars');
    }

    const snapshot = await getDocs(carsQuery);
    
    const newMatchingCars = snapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data(),
        dealerId: doc.ref.parent.parent?.id || 'Unknown'
      }))
      .filter(car => {
        // Tjek om bilen matcher filterkriterierne og ikke allerede eksisterer
        return checkCarAgainstFilter(car, filterData.criteria) && !existingCars.has(car.id);
      })
      .map(car => ({
        id: car.id,
        fields: car.fields || {},
        headline: car.headline || '',
        description: car.description || '',
        attachments: car.attachments || [],
        priceInt: car.priceInt || 0,
        categoryName: car.categoryName || '',
        dealerId: car.dealerId || '',
        createdAt: new Date()
      }));

    if (newMatchingCars.length > 0) {
      try {
        await updateDoc(filterDocRef, {
          cars: [...filterData.cars, ...newMatchingCars]
        });
      } catch (error) {
        console.error('Fejl ved opdatering af filter:', error);
        showToast('Der skete en fejl ved opdatering af filteret', 'Fejl', 'danger');
      }
    }
  });
};

// Hjælpefunktion til at checke om en bil matcher filterkriterierne
const checkCarAgainstFilter = (car, criteria) => {
  // Brand check
  if (criteria.brands.length && !criteria.brands.includes(car.fields?.Mærke)) {
    return false;
  }

  // Fuel type check
  if (criteria.fuelTypes.length && !criteria.fuelTypes.includes(car.fields?.Brændstoftype)) {
    return false;
  }

  // Category check
  if (criteria.categories.length && !criteria.categories.includes(car.categoryName)) {
    return false;
  }

  // Year range check
  const carYear = parseInt(car.fields?.Årgang);
  if (criteria.yearRange.from && carYear < criteria.yearRange.from) {
    return false;
  }
  if (criteria.yearRange.to && carYear > criteria.yearRange.to) {
    return false;
  }

  // Price range check
  const carPrice = car.priceInt;
  if (criteria.priceRange.from && carPrice < criteria.priceRange.from) {
    return false;
  }
  if (criteria.priceRange.to && carPrice > criteria.priceRange.to) {
    return false;
  }

  return true;
};

const savedFilters = ref([]);
const excludedFilters = ref([]);
const activeFilterTab = ref('saved');
const showEditModal = ref(false);
const editingFilter = ref(null);

// Hent gemte filtre
const fetchSavedFilters = async () => {
  const user = auth.currentUser;
  if (!user) return;

  const userDocRef = doc(db, 'users', user.uid);
  
  // Opsæt real-time listeners
  onSnapshot(collection(userDocRef, 'filteredCars'), (snapshot) => {
    savedFilters.value = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  });

  onSnapshot(collection(userDocRef, 'excludeCars'), (snapshot) => {
    excludedFilters.value = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  });
};

// Opdater applyFilter funktionen til at sætte activeFilter
const applyFilter = (filter) => {
  activeFilter.value = filter;
  selectedBrands.value = filter.criteria.brands;
  selectedFuelTypes.value = filter.criteria.fuelTypes;
  selectedCategories.value = filter.criteria.categories;
  selectedYearRange.value = filter.criteria.yearRange;
  selectedPriceRange.value = filter.criteria.priceRange;
};

// Rediger filter
const editFilter = (filter) => {
  editingFilter.value = JSON.parse(JSON.stringify(filter)); // Deep copy
  showEditModal.value = true;
};

const editExclude = (exclude) => {
  editingFilter.value = JSON.parse(JSON.stringify(exclude)); // Deep copy
  showEditModal.value = true;
};

const closeEditModal = () => {
  showEditModal.value = false;
  editingFilter.value = null;
};

const saveFilterChanges = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Ingen bruger er logget ind');

    const userDocRef = doc(db, 'users', user.uid);
    const filterDocRef = doc(userDocRef, 
      activeFilterTab.value === 'saved' ? 'filteredCars' : 'excludeCars', 
      editingFilter.value.id
    );

    await updateDoc(filterDocRef, editingFilter.value);
    closeEditModal();
    showToast('Ændringer gemt succesfuldt', 'Succes', 'success');
  } catch (error) {
    console.error('Fejl ved gemning af ændringer:', error);
    showToast('Der skete en fejl ved gemning af ændringerne', 'Fejl', 'danger');
  }
};

// Slet filter
const deleteFilter = async (filterId, collectionName) => {
  try {
    if (!confirm('Er du sikker på at du vil slette dette filter?')) return;

    const user = auth.currentUser;
    if (!user) throw new Error('Ingen bruger er logget ind');

    const userDocRef = doc(db, 'users', user.uid);
    const filterDocRef = doc(userDocRef, collectionName, filterId);
    
    await deleteDoc(filterDocRef);
    showToast('Filter slettet succesfuldt', 'Succes', 'success');
    
    // Opdater UI efter sletning
    if (collectionName === 'filteredCars') {
      savedFilters.value = savedFilters.value.filter(f => f.id !== filterId);
    } else {
      excludedFilters.value = excludedFilters.value.filter(f => f.id !== filterId);
    }
  } catch (error) {
    console.error('Fejl ved sletning af filter:', error);
    showToast('Der skete en fejl ved sletning af filteret', 'Fejl', 'danger');
  }
};

// Tilføj til onMounted
onMounted(() => {
  fetchCarsFromFirestore();
  fetchSavedFilters();
});

// Tilføj disse nye refs
const showNewFilterModal = ref(false);
const newFilterName = ref('');
const filterNameInput = ref(null);
const activeFilter = ref(null);

// Tilføj disse refs øverst sammen med de andre refs
const notificationMessage = ref('');
const showNotification = ref(false);
const notificationTitle = ref('Notifikation');
const notificationColor = ref('success');

// Tilføj showToast funktion
const showToast = (message, title = 'Notifikation', color = 'success') => {
  notificationMessage.value = message;
  notificationTitle.value = title;
  notificationColor.value = color;
  showNotification.value = true;
  setTimeout(() => {
    showNotification.value = false;
  }, 5000);
};

// Tilføj disse nye funktioner
const handleSaveFilter = () => {
  if (activeFilter.value) {
    // Hvis der er et aktivt filter, opdater det eksisterende
    updateExistingFilter();
  } else {
    // Hvis det er et nyt filter, vis modal for navngivning
    showNewFilterModal.value = true;
    nextTick(() => {
      filterNameInput.value?.focus();
    });
  }
};

const closeNewFilterModal = () => {
  showNewFilterModal.value = false;
  newFilterName.value = '';
};

const updateExistingFilter = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Ingen bruger er logget ind');

    const userDocRef = doc(db, 'users', user.uid);
    const filterDocRef = doc(userDocRef, 'filteredCars', activeFilter.value.id);

    await updateDoc(filterDocRef, {
      criteria: {
        brands: selectedBrands.value,
        fuelTypes: selectedFuelTypes.value,
        categories: selectedCategories.value,
        yearRange: selectedYearRange.value,
        priceRange: selectedPriceRange.value
      },
      updatedAt: new Date(),
      cars: filteredCars.value.map(car => ({
        id: car.id,
        fields: car.fields,
        headline: car.headline,
        description: car.description,
        attachments: car.attachments,
        priceInt: car.priceInt,
        categoryName: car.categoryName,
        dealerId: car.dealerId
      }))
    });

    showToast('Filter opdateret succesfuldt!', 'Succes', 'success');
  } catch (error) {
    console.error('Fejl ved opdatering af filter:', error);
    showToast('Der skete en fejl ved opdatering af filteret', 'Fejl', 'danger');
  }
};

const saveNewFilter = async () => {
  try {
    if (!newFilterName.value.trim()) {
      showToast('Indtast venligst et navn til filteret', 'Advarsel', 'warning');
      return;
    }

    const user = auth.currentUser;
    if (!user) throw new Error('Ingen bruger er logget ind');

    const userDocRef = doc(db, 'users', user.uid);
    const filterCollectionRef = collection(userDocRef, 'filteredCars');

    const filterData = {
      name: newFilterName.value.trim(),
      criteria: {
        brands: selectedBrands.value,
        fuelTypes: selectedFuelTypes.value,
        categories: selectedCategories.value,
        yearRange: selectedYearRange.value,
        priceRange: selectedPriceRange.value
      },
      cars: filteredCars.value.map(car => ({
        id: car.id,
        fields: car.fields,
        headline: car.headline,
        description: car.description,
        attachments: car.attachments,
        priceInt: car.priceInt,
        categoryName: car.categoryName,
        dealerId: car.dealerId
      })),
      createdAt: new Date()
    };

    const filterDoc = await addDoc(filterCollectionRef, filterData);
    
    // Start listener efter at dokumentet er oprettet
    const unsubscribe = setupFilterListener(filterDoc.id, 'filteredCars');
    
    // Gem unsubscribe funktionen hvis du vil stoppe lytteren senere
    // Du kan gemme den i et objekt eller array hvis du har brug for det
    
    closeNewFilterModal();
    showToast('Nyt filter gemt succesfuldt!', 'Succes', 'success');
  } catch (error) {
    console.error('Fejl ved gemning af nyt filter:', error);
    showToast('Der skete en fejl ved gemning af filteret', 'Fejl', 'danger');
  }
};

// Tilføj clearActiveFilter funktion
const clearActiveFilter = () => {
  activeFilter.value = null;
  selectedBrands.value = [];
  selectedFuelTypes.value = [];
  selectedCategories.value = [];
  selectedYearRange.value = { from: null, to: null };
  selectedPriceRange.value = { from: null, to: null };
};

const isLoading = ref(false);
const newImage = ref(null);

async function removeBackground(car) {
  if (!car) return;

  isLoading.value = true;
  try {
    const formData = new FormData();
    formData.append('size', 'auto');
    
    // Hent billedet først
    try {
      const imageResponse = await fetch(car.attachments.image_1);
      if (!imageResponse.ok) {
        throw new Error(`Kunne ikke hente billedet: ${imageResponse.statusText}`);
      }
      const imageBlob = await imageResponse.blob();
      formData.append('image_file', imageBlob);
    } catch (error) {
      console.error('Fejl ved hentning af billede:', error);
      showToast('Kunne ikke hente billedet', 'Fejl', 'danger');
      isLoading.value = false;
      return;
    }

    // Tilføj parametre for skygge og type
    formData.append('type', 'car');
    formData.append('shadow_type', 'car');
    formData.append('shadow_opacity', '90');
    formData.append('format', 'png');
    formData.append('semitransparency', 'true');
    
    // Skalering og positionering af bilen
    formData.append('scale', '95%'); // Skaler bilen til 80% af billedets størrelse
    formData.append('position', '50% 90%'); // Centrer vandret (50%) og 70% ned fra toppen
    
    // Brug baggrundsbillede
    formData.append('bg_image_url', 'https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/3DCurve.png?alt=media&token=9777af12-e0ff-4c7c-90a0-93e370cb3891');

    const response = await fetch('https://api.remove.bg/v1.0/removebg', {
      method: 'POST',
      headers: {
        'X-Api-Key': 'TyoVQVEbqgcTKVLEkaU7qoxY'
      },
      body: formData
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Remove.bg API fejl:', {
        status: response.status,
        statusText: response.statusText,
        errorText: errorText
      });
      
      let errorMessage = 'Der opstod en fejl ved fjernelse af baggrunden';
      if (response.status === 402) {
        errorMessage = 'API kreditter opbrugt';
      } else if (response.status === 401 || response.status === 403) {
        errorMessage = 'Ugyldig API nøgle - kontakt venligst administrator';
      } else if (response.status === 400) {
        errorMessage = 'Ugyldigt billede eller parametre';
      }
      
      showToast(errorMessage, 'Fejl', 'danger');
      return;
    }

    const blob = await response.blob();
    newImage.value = URL.createObjectURL(blob);
    const selectedCarObj = cars.value.find(c => c.id === selectedCar.value);
    if (selectedCarObj) {
      selectedCarObj.originalImage = selectedCarObj.attachments.image_1;
      selectedCarObj.attachments.image_1 = newImage.value;
    }
    
    showToast('Baggrund fjernet succesfuldt', 'Succes', 'success');

  } catch (error) {
    console.error('Generel fejl:', error);
    showToast('Der opstod en uventet fejl', 'Fejl', 'danger');
  } finally {
    isLoading.value = false;
  }
}

async function saveNewImage() {
  try {
    const selectedCarObj = cars.value.find(c => c.id === selectedCar.value);
    if (!selectedCarObj || !newImage.value) {
      showToast('Ingen ændringer at gemme', 'Advarsel', 'warning');
      return;
    }

    isLoading.value = true;

    // Verificer at vi har en bruger
    const user = auth.currentUser;
    if (!user) {
      throw new Error('Ingen bruger er logget ind');
    }

    // Konverter blob URL til en faktisk blob
    const response = await fetch(newImage.value);
    const blob = await response.blob();

    // Upload til Firebase Storage med korrekt sti
    const storage = getStorage();
    const imageRef = storageRef(storage, `cars/${user.uid}/${selectedCarObj.id}/image_1.png`);
    
    await uploadBytes(imageRef, blob);
    const downloadURL = await getDownloadURL(imageRef);

    // Log værdier for debugging
    console.log('User ID:', user.uid);
    console.log('Car ID:', selectedCarObj.id);
    console.log('Download URL:', downloadURL);

    try {
      // Få reference til Firestore
      const firestore = getFirestore();
      
      // Opret sti som string
      const path = `users/${user.uid}/dealerCars/${selectedCarObj.id}`;
      console.log('Document path:', path);
      
      // Opret doc reference
      const docRef = doc(firestore, path);
      
      // Opdater dokumentet
      await setDoc(docRef, {
        attachments: {
          image_1: downloadURL
        }
      }, { merge: true });

      // Opdater lokalt car objekt
      selectedCarObj.attachments.image_1 = downloadURL;
      
      // Nulstil newImage og vis success besked
      newImage.value = null;
      showToast('Billede gemt succesfuldt', 'Succes', 'success');
    } catch (firestoreError) {
      console.error('Firestore fejl:', firestoreError);
      throw firestoreError;
    }

  } catch (error) {
    console.error('Fejl ved gemning af billede:', error);
    showToast('Der skete en fejl ved gemning af billedet', 'Fejl', 'danger');
  } finally {
    isLoading.value = false;
  }
}

function cancelNewImage() {
  // Find den valgte bil og gendan det originale billede
  const selectedCarObj = cars.value.find(c => c.id === selectedCar.value);
  if (selectedCarObj) {
    selectedCarObj.attachments.image_1 = selectedCarObj.originalImage || selectedCarObj.attachments.image_1;
  }
  newImage.value = null;
}

// Hent biler baseret på bruger kontekst
const fetchCars = async () => {
  const carsRef = collection(db, 'cars')
  let q;

  if (canAccessOtherUsers.value) {
    // Admin/Manager kan se alle biler eller specifik brugers biler
    if (route.params.id) {
      q = query(carsRef, where('userId', '==', contextUserId.value))
    } else {
      q = query(carsRef) // Se alle biler
    }
  } else {
    // Normal bruger kan kun se egne biler
    q = query(carsRef, where('userId', '==', contextUserId.value))
  }

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        const carsData = []
        snapshot.forEach((doc) => {
          carsData.push({ id: doc.id, ...doc.data() })
        })
        cars.value = carsData
        resolve(carsData)
      },
      (error) => {
        console.error('Fejl ved hentning af biler:', error)
        reject(error)
      }
    )
    // Gem unsubscribe funktionen
    unsubscribeSnapshot.value = unsubscribe
  })
}

// Tilføj watch på contextUserId
watch(contextUserId, () => {
  fetchCarsFromFirestore();
});
</script>

<style scoped>
.car-card {
  width: 20rem;
  height: 19rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #212121;
  border: 1px solid #333; /* Tilføjet en tynd, mørk grå ramme */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.375rem; /* Tilføjet for at matche billedets afrundede hjørner */
}

.car-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.3);
}

.car-card.selected {
  border: 2px solid #5e72e4; /* Ændret fra 2px til 1px for en tyndere ramme */
  box-shadow: 0 4px 20px 0 rgba(94,114,228,0.3);
}

.card-body {
  flex-grow: 1;
  overflow: hidden;
  background-color: transparent; /* Tilføj denne linje */
}

.car-image {
  height: 100%;
  width: 100%;
  border-radius: 0.375rem;
  overflow: hidden;
}

.car-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card-footer {
  background-color: #161616;
  border-top: none;
  padding: 0.5rem;
  height: auto; /* Ændret fra fast højde til auto */
  display: flex;
  flex-direction: column; /* Ændret til kolonneretning */
  align-items: flex-start; /* Justeret til venstre */
}

.card-footer h6 {
  color: #ffffff;
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.2;
  width: 100%;
}

.card-footer small {
  color: #aaaaaa; /* Lysegrå farve til ID-teksten */
  font-size: 0.75rem;
  margin-top: 0.25rem; /* Lille afstand mellem overskrift og ID */
}

/* Fjern denne stil */
.page-background {
  background-color: #1e1e1e; /* Meget mørk baggrund for hele siden */
}

/* Opdater denne stil */
.card-wrapper > .card {
  background-color: transparent; /* Ændret fra #1e1e1e til transparent */
  border: none;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.card-header h6 {
  color: #ffffff; /* Hvid tekst for overskriften */
}

.search-container {
  width: 100%;
}

.search-input {
  width: 25%;
  background-color: #2c2c2c;
  border: 1px solid #444;
  color: #fff;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
}

.search-input::placeholder {
  color: #aaa;
}

.search-input:focus {
  background-color: #333;
  border-color: #5e72e4;
  box-shadow: 0 0 0 0.2rem rgba(94, 114, 228, 0.25);
  outline: none;
}

.car-count {
  color: #fff;
  font-size: 0.875rem;
  margin-left: 1rem;
}

.card .card-body {
  padding: 0.5rem; /* Ændret fra standard 1.5rem til 0.5rem */
}

/* Tilføj disse styles */
.filter-section {
  background-color: #212121;
  border: 1px solid #333;
}

.filter-section select,
.filter-section input {
  background-color: #2c2c2c;
  border: 1px solid #444;
  color: #fff;
}

.filter-section select:focus,
.filter-section input:focus {
  background-color: #333;
  border-color: #5e72e4;
  box-shadow: 0 0 0 0.2rem rgba(94, 114, 228, 0.25);
}

.filter-section label {
  color: #fff;
  margin-bottom: 0.5rem;
}

.filter-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  max-height: none;
  transition: max-height 0.3s ease-in-out;
}

.filter-chips.collapsed {
  max-height: 82px; /* Juster denne værdi baseret på dit design */
  overflow: hidden;
}

.filter-chip {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 16px;
  color: #fff;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-chip:hover {
  background-color: #333;
  border-color: #5e72e4;
}

.filter-chip.active {
  background-color: #5e72e4;
  border-color: #5e72e4;
}

/* Tilføj responsivt design for mindre skærme */
@media (max-width: 768px) {
  .filter-chips {
    gap: 4px;
  }

  .filter-chip {
    padding: 4px 8px;
    font-size: 0.8rem;
  }
}

.expand-button {
  background: none;
  border: none;
  color: #5e72e4;
  font-size: 0.875rem;
  padding: 4px 0;
  margin-top: 8px;
  cursor: pointer;
  text-decoration: underline;
}

.expand-button:hover {
  color: #4558be;
}

/* Animation for expand/collapse */
.filter-chips {
  transition: all 0.3s ease-in-out;
}

/* Ensure selected chips are always visible */
.filter-chip.active {
  order: -1;
}

.filter-header {
  cursor: pointer;
  border-bottom: 1px solid #333;
}

.filter-header:hover {
  background-color: #2a2a2a;
}

.btn-toggle {
  background: none;
  border: none;
  color: #5e72e4;
  font-size: 0.875rem;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.btn-toggle:hover {
  color: #4558be;
}

.filter-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.filter-content.expanded {
  max-height: 2000px; /* Juster denne værdi efter behov */
}

/* Animation for chevron */
.fas {
  transition: transform 0.3s ease;
}

.fa-chevron-up {
  transform: rotate(180deg);
}

/* Ensure smooth transition */
.card-body {
  padding: 1rem;
  transition: padding 0.3s ease-in-out;
}

.filter-content:not(.expanded) .card-body {
  padding: 0 1rem;
}

/* Tilføj disse styles */
.btn-primary {
  background-color: #5e72e4;
  border-color: #5e72e4;
  color: white;
}

.btn-danger {
  background-color: #f5365c;
  border-color: #f5365c;
  color: white;
}

.btn:hover {
  opacity: 0.9;
}

/* Tilføj eller opdater disse styles i din <style> sektion */
.filter-chip.action {
  background-color: #2c2c2c;
  border: 2px solid #28a745;  /* Grøn border */
  color: #28a745;  /* Grøn tekst */
  transition: all 0.3s ease;
}

.filter-chip.action:hover {
  background-color: #28a745;
  color: white;
}

.filter-chip.action-danger {
  background-color: #2c2c2c;
  border: 2px solid #dc3545;  /* Rød border */
  color: #dc3545;  /* Rød tekst */
  transition: all 0.3s ease;
}

.filter-chip.action-danger:hover {
  background-color: #dc3545;
  color: white;
}

/* Tilføj disse nye styles */
.filters-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.filter-card {
  background-color: #212121;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 1rem;
}

.criteria-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.filter-chip.small {
  font-size: 0.75rem;
  padding: 2px 8px;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1050;
}

.modal.show {
  display: block;
}

.modal-content {
  background-color: #212121;
  border: 1px solid #333;
}

.nav-tabs {
  border-bottom: 1px solid #333;
}

.nav-link {
  color: #aaa;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.nav-link:hover {
  color: #fff;
}

.nav-link.active {
  color: #5e72e4;
  border-bottom: 2px solid #5e72e4;
}

.saved-filters-bar {
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 0.5rem;
}

.filter-type-selector {
  min-width: 200px;
}

.filter-type-selector select {
  background-color: #212121;
  border: 1px solid #333;
  color: #fff;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
}

.badge {
  background-color: rgba(255,255,255,0.1);
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.75rem;
}

.filter-chip.active {
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.filter-chip .badge {
  background-color: rgba(0,0,0,0.2);
}

.filter-chip-container {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.filter-actions {
  display: inline-flex;
  gap: 4px;
}

.filter-chip.action-small,
.filter-chip.action-danger-small {
  padding: 4px 8px;
  font-size: 0.75rem;
}

.filter-chip.action-small {
  border-color: #28a745;
  color: #28a745;
}

.filter-chip.action-danger-small {
  border-color: #dc3545;
  color: #dc3545;
}

.filter-chip.action-small:hover {
  background-color: #28a745;
  color: white;
}

.filter-chip.action-danger-small:hover {
  background-color: #dc3545;
  color: white;
}

.notification-toast {
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 300px;
  padding: 15px;
  border-radius: 8px;
  z-index: 1060;
  background-color: #212121;
  border: 1px solid;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;
}

.notification-header {
  font-weight: bold;
  margin-bottom: 5px;
}

.notification-toast.success {
  border-color: #28a745;
  color: #28a745;
}

.notification-toast.warning {
  border-color: #ffc107;
  color: #ffc107;
}

.notification-toast.danger {
  border-color: #dc3545;
  color: #dc3545;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.car-image {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 0.375rem;
  overflow: hidden;
}

.car-actions-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.action-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  background: #5e72e4;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button:hover:not(:disabled) {
  background: #4558be;
}

.action-button:disabled {
  background: #4558be80;
  cursor: not-allowed;
}

.action-button.save {
  background: #28a745;
}

.action-button.save:hover {
  background: #218838;
}

.action-button.cancel {
  background: #dc3545;
}

.action-button.cancel:hover {
  background: #c82333;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.btn-sm.action-button {
  padding: 2px 8px;
  font-size: 0.75rem;
  height: 24px;
  line-height: 20px;
  white-space: nowrap;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

/* Opdater disse styles */
.card-footer {
  background-color: #161616;
  border-top: none;
  padding: 0.5rem;
  height: auto;
}

.card-footer h6 {
  color: #ffffff;
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.2;
  width: 100%;
}

.card-footer .d-flex {
  margin-top: 0.25rem;
}

.card-footer small {
  flex: 0 0 auto;
}

.btn-sm.action-button {
  padding: 2px 8px;
  font-size: 0.75rem;
  height: 24px;
  line-height: 20px;
  white-space: nowrap;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

/* Tilføj disse nye styles */
.justify-content-between {
  justify-content: space-between !important;
  width: 100%;
}

.align-items-center {
  align-items: center !important;
}

.d-flex {
  display: flex !important;
}

/* Opdater card styling */
.filter-section.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
  will-change: transform;
}

/* Input fields */
.search-input,
.filter-section input,
.filter-section select {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 12px 20px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Buttons */
.filter-chip {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 8px 16px;
  color: white;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateZ(0);
  will-change: transform, background-color;
  box-shadow: 
    0 2px 5px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.05);
}

/* Modal styling */
.modal-content {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

/* Badge styling */
.badge {
  backdrop-filter: blur(4px);
  border-radius: 20px;
  padding: 5px 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Optimized animations */
@keyframes modalShow {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

.modal.show .modal-content {
  animation: modalShow 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

/* Car card styling */
.car-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
  will-change: transform;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  height: auto;
  overflow: hidden;
}

.car-card:hover {
  transform: translateY(-5px) translateZ(0);
  box-shadow: 
    0 12px 40px 0 rgba(31, 38, 135, 0.45),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

.car-card.selected {
  border: 2px solid rgba(94, 114, 228, 0.8);
  box-shadow: 
    0 12px 40px 0 rgba(94, 114, 228, 0.3),
    0 0 0 1px rgba(94, 114, 228, 0.3);
}

/* Car image container */
.car-image {
  position: relative;
  width: 100%;
  padding-top: 75%; /* 4:3 aspect ratio */
  overflow: hidden;
}

.car-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.car-card:hover .car-image img {
  transform: scale(1.05);
}

/* Card footer styling */
.card-footer {
  background: rgba(22, 22, 22, 0.9);
  backdrop-filter: blur(5px);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding: 1rem;
}

/* Action buttons */
.action-button {
  background: linear-gradient(
    135deg, 
    rgba(94, 114, 228, 0.15), 
    rgba(94, 114, 228, 0.25)
  );
  backdrop-filter: blur(8px);
  border: 1px solid rgba(94, 114, 228, 0.3);
  border-radius: 12px;
  color: white;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.action-button:hover:not(:disabled) {
  background: linear-gradient(
    135deg, 
    rgba(94, 114, 228, 0.25), 
    rgba(94, 114, 228, 0.35)
  );
  transform: translateY(-2px);
}

.action-button.save {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.15), 
    rgba(66, 186, 150, 0.25)
  );
  border-color: rgba(66, 186, 150, 0.3);
}

.action-button.cancel {
  background: linear-gradient(
    135deg, 
    rgba(245, 54, 92, 0.15), 
    rgba(245, 54, 92, 0.25)
  );
  border-color: rgba(245, 54, 92, 0.3);
}

/* Filter section improvements */
.filter-content {
  transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.filter-header {
  background: rgba(255, 255, 255, 0.02);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

/* Notification toast improvements */
.notification-toast {
  background: rgba(33, 33, 33, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  animation: slideIn 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateX(0) translateZ(0);
  }
}

/* Loading states */
.loading-overlay {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  border-radius: inherit;
}

/* Accessibility improvements */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Dark mode optimizations */
@media (prefers-color-scheme: dark) {
  .car-card,
  .filter-section.card {
    background: rgba(255, 255, 255, 0.03);
  }
  
  .card-footer {
    background: rgba(18, 18, 18, 0.95);
  }
}

/* Responsive improvements */
@media (max-width: 768px) {
  .car-card {
    width: 100%;
  }
  
  .filter-chips {
    gap: 0.5rem;
  }
  
  .action-button {
    padding: 0.5rem;
  }
}
</style>