<template>
  <div class="container-fluid py-4">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8">
        <ProjectSteps
          v-model:currentStep="currentStep"
          :totalSteps="totalSteps"
        />
        <div class="card mt-4">
          <div class="card-body">
            <form class="mb-8 multisteps-form__form" @submit.prevent>
              <!-- Trin 1: Emne -->
              <div
                class="p-3 bg-white multisteps-form__panel"
                :class="{ 'js-active': currentStep === 1 }"
                data-animation="FadeIn"
              >
                <div class="multisteps-form__content">
                  <div class="mt-3 row">
                    <div class="col-12">
                      <label for="projectName">Emne</label>
                      <input
                        id="projectName"
                        v-model="projectData.title"
                        maxlength="20"
                        @input="limitTitleLength"
                        placeholder="Indtast emne"
                        class="multisteps-form__input form-control"
                      />
                      <span class="character-count">{{ projectData.title.length }}/20</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Trin 2: Overskrift -->
              <div
                class="p-3 bg-white multisteps-form__panel"
                :class="{ 'js-active': currentStep === 2 }"
                data-animation="FadeIn"
              >
                <div class="multisteps-form__content">
                  <div class="mt-3 row">
                    <div class="col-12">
                      <label for="postHeadline">Overskrift</label>
                      <input
                        id="postHeadline"
                        v-model="projectData.headline"
                        maxlength="100"
                        @input="limitHeadlineLength"
                        placeholder="Indtast overskrift"
                        class="multisteps-form__input form-control"
                      />
                      <span class="character-count">{{ projectData.headline.length }}/100</span>
                    </div>
                  </div>
                  <div class="mt-3 row">
                    <div class="col-12">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          v-model="projectData.hasProductLink"
                          id="hasProductLink"
                          class="form-check-input"
                        />
                        <label class="form-check-label" for="hasProductLink">
                          Dette opslag omhandler et produkt
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-if="projectData.hasProductLink" class="mt-3 row">
                    <div class="col-12">
                      <label>Produktlink</label>
                      <input
                        type="url"
                        v-model="projectData.productLink"
                        placeholder="Indtast produktlink"
                        class="multisteps-form__input form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Trin 3: Platforme -->
              <div
                class="p-3 bg-white multisteps-form__panel"
                :class="{ 'js-active': currentStep === 3 }"
                data-animation="FadeIn"
              >
                <div class="multisteps-form__content">
                  <div class="mt-3 row">
                    <div class="col-12">
                      <label>Vælg platforme</label>
                      <div class="platform-selector">
                        <button
                          v-for="platform in availablePlatforms"
                          :key="platform"
                          @click.prevent="togglePlatform(platform)"
                          :class="{ 'selected': projectData.tags.includes(platform) }"
                          class="btn"
                          type="button"
                        >
                          {{ platform }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Trin 4: Upload -->
              <div
                class="p-3 bg-white multisteps-form__panel"
                :class="{ 'js-active': currentStep === 4 }"
                data-animation="FadeIn"
              >
                <div class="multisteps-form__content">
                  <div class="mt-3 row">
                    <div class="col-12">
                      <div
                        id="dropzone"
                        ref="dropzoneElement"
                        class="dropzone"
                      >
                        <div class="dz-message" data-dz-message>
                          <span>Træk filer hertil eller klik for at vælge</span>
                        </div>
                      </div>
                      <div v-for="(progress, filename) in uploadProgress" :key="filename" class="upload-progress">
                        <span>{{ filename }}: {{ progress.toFixed(2) }}%</span>
                        <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Trin 5: Beskrivelse -->
              <div
                class="p-3 bg-white multisteps-form__panel"
                :class="{ 'js-active': currentStep === 5 }"
                data-animation="FadeIn"
              >
                <div class="multisteps-form__content">
                  <div class="mt-3 row">
                    <div class="col-12">
                      <label for="projectDescription">Beskrivelse</label>
                      <textarea
                        id="projectDescription"
                        v-model="projectData.description"
                        rows="4"
                        placeholder="Indtast beskrivelse"
                        class="multisteps-form__textarea form-control"
                      ></textarea>
                    </div>
                  </div>
                  <div class="button-group">
                    <button @click="handleGenerateText" class="generate-button">Generer tekst</button>
                    <button @click="handleCorrectText" class="correct-button">Ret tekst</button>
                  </div>
                </div>
              </div>

              <!-- Trin 6: Opslagstype -->
              <div
                class="p-3 bg-white multisteps-form__panel"
                :class="{ 'js-active': currentStep === 6 }"
                data-animation="FadeIn"
              >
                <div class="multisteps-form__content">
                  <div class="mt-3 row">
                    <div class="col-12">
                      <div v-for="platform in projectData.tags" :key="platform" class="post-type-selector">
                        <label>{{ platform }}</label>
                        <select v-model="projectData.postTypes[platform]" class="form-control">
                          <option value="">Vælg en opslagstype for {{ platform }}</option>
                          <option v-for="option in getAvailablePostTypes(platform)" :key="option.value" :value="option.value">
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Trin 7: Dato og tid -->
              <div
                class="p-3 bg-white multisteps-form__panel"
                :class="{ 'js-active': currentStep === 7 }"
                data-animation="FadeIn"
              >
                <div class="multisteps-form__content">
                  <div class="mt-3 row">
                    <div class="col-6">
                      <label>Start Dato</label>
                      <flat-pickr
                        v-model="projectData.startDate"
                        :config="dateConfig"
                        placeholder="Vælg startdato"
                        class="multisteps-form__input form-control"
                      ></flat-pickr>
                    </div>
                    <div class="col-6">
                      <label>Start Tid</label>
                      <flat-pickr
                        v-model="projectData.startTime"
                        :config="timeConfig"
                        placeholder="Vælg starttid"
                        class="multisteps-form__input form-control"
                      ></flat-pickr>
                    </div>
                  </div>
                  <div class="mt-3 row">
                    <div class="col-12">
                      <button @click.prevent="showAdSetModal" class="btn btn-warning" type="button">Opret Ad Set</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="mt-4 button-row d-flex" :class="{'justify-content-end': currentStep === 1, 'justify-content-between': currentStep > 1}">
          <button
            v-if="currentStep > 1"
            class="btn bg-gradient-light"
            type="button"
            title="Forrige"
            @click="prevStep"
          >
            Forrige
          </button>
          <button
            v-if="currentStep < totalSteps"
            class="btn bg-gradient-dark"
            type="button"
            title="Næste"
            @click="nextStep"
          >
            Næste
          </button>
          <button
            v-else
            class="btn bg-gradient-dark"
            type="button"
            title="Opret"
            @click.prevent="createProject"
          >
            Opret & Gem opslag
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch } from "vue";
import ProjectSteps from "./ProjectSteps.vue";
import flatPickr from "vue-flatpickr-component";
import Dropzone from "dropzone";
import { getAuth } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../firebase.js";
import { uploadFile } from "../../../services/uploadeService.js";
import axios from "axios";
import Swal from "sweetalert2";
import { createApp } from "vue";
import AdSetModal from "@/views/pages/components/AdSetModal.vue";

const projectData = ref({
  title: "",
  headline: "",
  description: "",
  isPrivate: false,
  tags: [],
  startDate: "",
  startTime: "",
  files: [],
  postTypes: {},
  links: [],
  hasProductLink: false,
  productLink: ""
});

const isLoading = ref(false);
const errorMessage = ref("");
const dropzoneElement = ref(null);
let dropzoneInstance = null;
const uploadProgress = ref({});
const currentStep = ref(1);
const totalSteps = 7;

const availablePlatforms = ['Facebook', 'Instagram', 'LinkedIn', 'YouTube'];

const hasUploadedFiles = computed(() => projectData.value.files.length > 0);

const dateConfig = {
  dateFormat: "Y-m-d",
  allowInput: true,
};

const timeConfig = {
  enableTime: true,
  noCalendar: true,
  dateFormat: "H:i",
  time_24hr: true,
  allowInput: true,
};

onMounted(() => {
  initializeDropzone();
});

onBeforeUnmount(() => {
  if (dropzoneInstance) {
    dropzoneInstance.destroy();
  }
});

const initializeDropzone = () => {
  if (dropzoneElement.value) {
    dropzoneInstance = new Dropzone(dropzoneElement.value, {
      url: "about:blank",
      addRemoveLinks: true,
      autoProcessQueue: false,
      parallelUploads: 5,
      maxFiles: 10,
      acceptedFiles: "image/*,video/*",
      init: function() {
        this.on("addedfile", handleFileAdded);
        this.on("removedfile", handleFileRemoved);
      }
    });
  }
};

const handleFileAdded = async (file) => {
  try {
    const downloadURL = await uploadFile(file, "projectFiles", (progress) => {
      uploadProgress.value[file.name] = progress;
    });
    projectData.value.files.push(downloadURL);
    file.downloadURL = downloadURL;
  } catch (error) {
    console.error(`Fejl ved upload af fil ${file.name}:`, error);
    dropzoneInstance.removeFile(file);
    alert(`Fejl ved upload af fil ${file.name}: ${error.message}`);
  } finally {
    delete uploadProgress.value[file.name];
  }
};

const handleFileRemoved = (file) => {
  if (file.downloadURL) {
    projectData.value.files = projectData.value.files.filter(url => url !== file.downloadURL);
  }
};

const createProject = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      if (!validateProjectData()) return;

      const newProject = {
        ...projectData.value,
        startDateTime: combineDateTime(projectData.value.startDate, projectData.value.startTime),
        userId: user.uid,
        createdAt: new Date(),
      };

      const projectsCollection = collection(db, "projects");
      await addDoc(projectsCollection, newProject);

      alert("Projekt oprettet!");
      resetForm();
    } else {
      alert("Du skal være logget ind for at oprette et projekt.");
    }
  } catch (error) {
    console.error("Fejl ved oprettelse af projekt:", error);
    alert(`Fejl ved oprettelse af projekt: ${error.message}`);
  }
};

const validateProjectData = () => {
  if (!projectData.value.title) {
    alert("Projekt navn er påkrævet.");
    return false;
  }
  if (!projectData.value.headline) {
    alert("Overskrift er påkrævet.");
    return false;
  }
  if (!projectData.value.description) {
    alert("Projekt beskrivelse er påkrævet.");
    return false;
  }
  if (!projectData.value.startDate || !projectData.value.startTime) {
    alert("Start dato og tid er påkrævet.");
    return false;
  }
  return true;
};

const resetForm = () => {
  projectData.value = {
    title: "",
    headline: "",
    description: "",
    isPrivate: false,
    tags: [],
    startDate: "",
    startTime: "",
    files: [],
    postTypes: {},
    links: [],
    hasProductLink: false,
    productLink: ""
  };
  if (dropzoneInstance) dropzoneInstance.removeAllFiles(true);
  currentStep.value = 1;
};

const combineDateTime = (date, time) => {
  return date && time ? `${date} ${time}` : "";
};

const limitTitleLength = () => {
  if (projectData.value.title.length > 20) {
    projectData.value.title = projectData.value.title.slice(0, 20);
  }
};

const limitHeadlineLength = () => {
  if (projectData.value.headline.length > 100) {
    projectData.value.headline = projectData.value.headline.slice(0, 100);
  }
};

const handleGenerateText = async () => {
  await processText('generate');
};

const handleCorrectText = async () => {
  await processText('correct');
};

const processText = async (action) => {
  isLoading.value = true;
  errorMessage.value = "";

  try {
    const functionUrl = "https://us-central1-toft-d4f39.cloudfunctions.net/correctText";
    const requestData = {
      action: action,
      text: projectData.value.description,
      emne: projectData.value.title,
      platform: projectData.value.tags.join(', '),
      messages: constructMessages(action)
    };

    const response = await axios.post(functionUrl, requestData, {
      headers: { 'Content-Type': 'application/json' }
    });
    
    if (response.data && response.data.correctedText) {
      projectData.value.description = response.data.correctedText;
    } else if (response.data && response.data.error) {
      throw new Error(response.data.error);
    } else {
      throw new Error('Uventet svarformat fra serveren');
    }
  } catch (error) {
    console.error('Fejl under processering af tekst:', error);
    errorMessage.value = `Der opstod en fejl: ${error.message}`;
  } finally {
    isLoading.value = false;
  }
};

const constructMessages = (action) => {
  let messages = [
    {
      role: "system",
      content: `Du skal være min hjælper til at læse korrektur på min tekst og forbedre den. 
                Regler: 
                - Du må gerne bruge emojis`
    }
  ];

  if (action === 'generate') {
    messages.push({
      role: "user",
      content: [
        {
          type: "text",
          text: `Baseret på følgende billeder eller videobeskrivelser, generer venligst en passende tekst til et opslag på ${projectData.value.tags.join(', ') || 'sociale medier'}. Emnet er: ${projectData.value.title}`
        }
      ]
    });

    projectData.value.files.forEach(fileUrl => {
      if (fileUrl.toLowerCase().endsWith('.mp4') || fileUrl.toLowerCase().endsWith('.mov')) {
        messages[1].content.push({
          type: "video_url",
          video_url: fileUrl
        });
      } else {
        messages[1].content.push({
          type: "image_url",
          image_url: {
            url: fileUrl
          }
        });
      }
    });
  } else if (action === 'correct') {
    messages.push({
      role: "user",
      content: `Korrigér og forbedre følgende tekst til et opslag på ${projectData.value.tags.join(', ') || 'sociale medier'}. Emnet er: ${projectData.value.title}\n\n${projectData.value.description}`
    });
  }

  return messages;
};

const showAdSetModal = async () => {
  const { value: formValues } = await Swal.fire({
    title: "Opret Ad Set",
    html: '<div id="adset-modal-container"></div>',
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: "Luk",
    width: "80%",
    didOpen: () => {
      const app = createApp(AdSetModal, {
        campaignName: projectData.value.title,
        startDate: projectData.value.startDate,
        endDate: projectData.value.stopTime,
      });
      app.mount("#adset-modal-container");
    },
    willClose: () => {
      const container = document.getElementById("adset-modal-container");
      if (container && container.__vue_app__) {
        container.__vue_app__.unmount();
      }
    }
  });

  if (formValues) {
    console.log("Ad Set oprettet:", formValues);
    // Håndter de returnerede data her
  }
};

const togglePlatform = (platform) => {
  const index = projectData.value.tags.indexOf(platform);
  if (index > -1) {
    projectData.value.tags.splice(index, 1);
  } else {
    projectData.value.tags.push(platform);
  }
};

const getAvailablePostTypes = (platform) => {
  const types = [];
  const hasVideo = projectData.value.files.some(file => file.endsWith('.mp4') || file.endsWith('.mov'));
  const hasImage = projectData.value.files.some(file => !file.endsWith('.mp4') && !file.endsWith('.mov'));
  const hasMediaFile = projectData.value.files.length > 0;
  const hasLink = projectData.value.description.includes('http://') || projectData.value.description.includes('https://');
  
  if (platform === 'Facebook') {
    if (hasVideo && projectData.value.description) {
      types.push({ value: 'facebookReels', label: 'Facebook Reels' });
    }
    if (hasMediaFile) {
      types.push({ value: 'facebookStory', label: 'Facebook Story' });
    }
    if (hasLink && hasImage) {
      types.push({ value: 'facebookLinkImage', label: 'Facebook Link med billede' });
    }
    if (hasVideo) {
      types.push({ value: 'facebookVideoLink', label: 'Facebook Video Link' });
    }
  } else if (platform === 'Instagram') {
    if (hasVideo) {
      types.push({ value: 'instagramReels', label: 'Instagram Reels' });
    }
    if (hasMediaFile) {
      types.push({ value: 'instagramStory', label: 'Instagram Story' });
    }
  }
  
  return types;
};

const nextStep = () => {
  if (currentStep.value < totalSteps) {
    currentStep.value++;
  }
};

const prevStep = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
};

const handleEnterKey = (event) => {
  if (event.target.tagName.toLowerCase() === 'textarea') return;
  if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
    event.preventDefault();
  }

  if (currentStep.value < totalSteps) {
    nextStep();
  } else if (currentStep.value === totalSteps) {
    createProject();
  }
};

watch(() => projectData.value.tags, (newPlatforms) => {
  const newPostTypes = {};
  newPlatforms.forEach(platform => {
    if (!projectData.value.postTypes[platform]) {
      newPostTypes[platform] = '';
    } else {
      newPostTypes[platform] = projectData.value.postTypes[platform];
    }
  });
  projectData.value.postTypes = newPostTypes;
}, { deep: true });
</script>

<style scoped>
.card {
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  min-height: 400px; /* Juster dette efter behov */
  height: auto;
  overflow-y: auto;
}

.card-body {
  padding: 2rem;
}

.multisteps-form__panel {
  height: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.multisteps-form__panel.js-active {
  opacity: 1;
  visibility: visible;
}

.form-control {
  border: 1px solid #d2d6da;
}

.form-control:focus {
  border-color: #5e72e4;
  box-shadow: 0 0 0 0.2rem rgba(94, 114, 228, 0.25);
}

.character-count {
  font-size: 0.8rem;
  color: #8898aa;
}

.platform-selector button {
  margin-right: 10px;
  margin-bottom: 10px;
}

.platform-selector button.selected {
  background-color: #5e72e4;
  color: white;
}

.dropzone {
  border: 2px dashed #d2d6da;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone:hover {
  border-color: #5e72e4;
}

.dropzone .dz-message {
  font-size: 1.2em;
  color: #6c757d;
}

.upload-progress {
  margin-top: 10px;
}

.progress-bar {
  height: 4px;
  background-color: #5e72e4;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.date-time-picker {
  display: flex;
  gap: 10px;
}

.date-time-picker input {
  flex: 1;
}

.button-row {
  margin-top: 2rem;
}

.button-row button {
  min-width: 100px;
}

.step-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #344767;
}
</style>
