<script setup>
import { ref, onMounted, reactive, watch } from "vue";
import * as Choices from "choices.js";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { usePasswordManagement } from './components/usePasswordManagement';

const auth = getAuth();
const db = getFirestore();
const storage = getStorage();

const userName = ref('');
const uploadedFile = ref(null);
const userRole = ref(null);
const profileImageFile = ref(null);
const profileImageName = ref('');
const coverImageName = ref('');
const existingProfileImage = ref('');
const existingCoverImage = ref('');

// Tilføj disse nye reaktive variabler
const profileImagePreview = ref(null);
const coverImagePreview = ref(null);

// Opret en lokal reaktiv kopi af brugerdata
const localUserData = reactive({
  name: '',
  adminSettings: {
    notifyViaEmail: false,
    smsConfirmation: false,
    checkDevices: false,
    findDevice: false,
    lockDevice: false,
    manageApps: false,
    MetaSettings: {
      userToken: '',
      adAccountId: '',
      appId: ''
    }
  }
});

const {
  resetEmail,
  errorMessage,
  successMessage,
  resetPassword,
  loadUserEmail
} = usePasswordManagement();

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    uploadedFile.value = file;
    coverImageName.value = file.name;
  }
};

const handleProfileImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    profileImageFile.value = file;
    profileImageName.value = file.name;
    // Opret en URL til forhåndsvisning af billedet
    profileImagePreview.value = URL.createObjectURL(file);
  }
};

const handleCoverImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    uploadedFile.value = file;
    coverImageName.value = file.name;
    // Opret en URL til forhåndsvisning af billedet
    coverImagePreview.value = URL.createObjectURL(file);
  }
};

const updateProfile = async () => {
  if (userRole.value !== 1 && userRole.value !== 2) {
    console.log('Kun brugere med rolle 1 eller 2 kan opdatere profilen');
    return;
  }

  const userId = auth.currentUser.uid;
  console.log('Nuværende bruger ID:', userId);
  console.log('Brugerrolle:', userRole.value);

  const userRef = doc(db, "users", userId);

  let updateData = {
    name: userName.value,
    adminSettings: {
      ...localUserData.adminSettings,
      MetaSettings: {
        userToken: localUserData.adminSettings.MetaSettings.userToken,
        adAccountId: localUserData.adminSettings.MetaSettings.adAccountId,
        appId: localUserData.adminSettings.MetaSettings.appId
      }
    }
  };

  if (uploadedFile.value) {
    try {
      const imagePath = `headerImages/${userId}/${uploadedFile.value.name}`;
      console.log('Forsøger at uploade til:', imagePath);
      const imageRef = storageRef(storage, imagePath);
      await uploadBytes(imageRef, uploadedFile.value);
      const downloadURL = await getDownloadURL(imageRef);
      updateData.adminSettings.headerImage = downloadURL;
      console.log('Coverbillede uploadet succesfuldt');
    } catch (error) {
      console.error('Fejl ved upload af coverbillede:', error);
      alert(`Der opstod en fejl ved upload af coverbilledet: ${error.message}`);
      return;
    }
  }

  if (profileImageFile.value) {
    try {
      const imageRef = storageRef(storage, `profileImages/${userId}/${profileImageFile.value.name}`);
      await uploadBytes(imageRef, profileImageFile.value);
      const downloadURL = await getDownloadURL(imageRef);
      updateData.adminSettings.profileImage = downloadURL;
    } catch (error) {
      console.error('Fejl ved upload af profilbillede:', error);
      alert('Der opstod en fejl ved upload af profilbilledet. Prøv venligst igen.');
      return;
    }
  }

  try {
    console.log('Opdaterer Firebase med:', updateData);
    await updateDoc(userRef, updateData);
    console.log('Profil og indstillinger opdateret i Firebase:', updateData);
    alert('Profil og indstillinger opdateret succesfuldt!');
  } catch (error) {
    console.error('Fejl ved opdatering af profil og indstillinger i Firebase:', error);
    alert('Der opstod en fejl ved opdatering af profilen og indstillingerne. Prøv venligst igen.');
  }
};

const toggleSetting = async (settingKey, event) => {
  const settingValue = event.target.checked;
  console.log(`Toggling ${settingKey} to ${settingValue}`);

  // Opdater lokal tilstand
  localUserData.adminSettings[settingKey] = settingValue;

  // Opdater Firestore
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(db, "users", user.uid);
    const updateData = {};
    updateData[`adminSettings.${settingKey}`] = settingValue;

    try {
      await updateDoc(userDocRef, updateData);
      console.log(`Updated ${settingKey} to ${settingValue} in Firestore.`);
    } catch (error) {
      console.error("Error updating settings in Firestore:", error);
    }
  }
};

const loadProfileData = async () => {
  const userId = auth.currentUser.uid;
  const userRef = doc(db, "users", userId);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    const data = userSnap.data();
    userRole.value = data.role;
    if (userRole.value === 1 || userRole.value === 2) {
      userName.value = data.name || '';
      if (data.adminSettings) {
        Object.assign(localUserData.adminSettings, data.adminSettings);
        if (data.adminSettings.profileImage) {
          existingProfileImage.value = data.adminSettings.profileImage;
          profileImageName.value = 'Eksisterende profilbillede';
        }
        if (data.adminSettings.headerImage) {
          existingCoverImage.value = data.adminSettings.headerImage;
          coverImageName.value = 'Eksisterende coverbillede';
        }
        
        if (data.adminSettings.MetaSettings) {
          localUserData.adminSettings.MetaSettings.userToken = data.adminSettings.MetaSettings.userToken || '';
          localUserData.adminSettings.MetaSettings.adAccountId = data.adminSettings.MetaSettings.adAccountId || '';
          localUserData.adminSettings.MetaSettings.appId = data.adminSettings.MetaSettings.appId || '';
        }
      }
    } else {
      console.log('Brugeren har ikke tilladelse til at redigere profilen');
    }
  }
};

onMounted(async () => {
  if (document.getElementById("choices-questions")) {
    var element = document.getElementById("choices-questions");
    new Choices(element, { allowHTML: true });
  }
  
  await loadProfileData();
  loadUserEmail();
});
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 pb-3">
        <div class="card">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Sikkerhedsindstillinger</h6>
          </div>
          <div class="card-body p-3">
            <div class="form-group d-flex align-items-center justify-content-between">
              <span class="text-sm">Giv mig besked via e-mail ved login</span>
              <ArgonSwitch 
                :checked="localUserData.adminSettings.notifyViaEmail" 
                id="notify-via-email" 
                name="Email"
                @change="toggleSetting('notifyViaEmail', $event)"
              />
            </div>
            <div class="form-group d-flex align-items-center justify-content-between">
              <span class="text-sm">Send SMS-bekræftelse for alle online betalinger</span>
              <ArgonSwitch 
                :checked="localUserData.adminSettings.smsConfirmation" 
                id="sms-confirmation" 
                name="SMS"
                @change="toggleSetting('smsConfirmation', $event)"
              />
            </div>
            <div class="form-group d-flex align-items-center justify-content-between">
              <span class="text-sm">Kontroller hvilke enheder der har adgang til din konto</span>
              <ArgonSwitch 
                :checked="localUserData.adminSettings.checkDevices" 
                id="check-devices" 
                name="Devices"
                @change="toggleSetting('checkDevices', $event)"
              />
            </div>
            <div class="form-group d-flex align-items-center justify-content-between">
              <span class="text-sm">Find min enhed, sørg for at din enhed kan findes, hvis den bliver væk</span>
              <ArgonSwitch 
                :checked="localUserData.adminSettings.findDevice" 
                id="find-device" 
                name="Find Device"
                @change="toggleSetting('findDevice', $event)"
              />
            </div>
            <div class="form-group d-flex align-items-center justify-content-between">
              <span class="text-sm">Lås din enhed med en PIN, mønster eller adgangskode</span>
              <ArgonSwitch 
                :checked="localUserData.adminSettings.lockDevice" 
                id="lock-device" 
                name="Lock Device"
                @change="toggleSetting('lockDevice', $event)"
              />
            </div>
            <div class="form-group d-flex align-items-center justify-content-between">
              <span class="text-sm">Administrer hvilke apps der har adgang til app-brugsdata på din enhed</span>
              <ArgonSwitch 
                :checked="localUserData.adminSettings.manageApps" 
                id="manage-apps" 
                name="Apps"
                @change="toggleSetting('manageApps', $event)"
              />
            </div>
          </div>
        </div>

        <!-- Flyttet Adgangskode sektion -->
        <div class="card mt-4">
          <div class="card-header p-3 pb-0">
            <h6 class="mb-1">Adgangskode</h6>
            <p class="text-sm mb-0">
              Nulstil din adgangskode her.
            </p>
          </div>
          <div class="card-body p-3">
            <ArgonInput v-model="resetEmail" id="reset-email" type="email" placeholder="Din e-mailadresse" label="E-mail" />
            <ArgonButton 
              @click="resetPassword" 
              color="info" 
              class="w-100"
              :class="{ 'loading': isLoading }"
            >
              Send nulstillingslink
            </ArgonButton>
            
            <p v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</p>
            <p v-if="successMessage" class="text-success mt-3">{{ successMessage }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6" v-if="userRole === 1 || userRole === 2">
        <div class="card">
          <div class="card-header p-3 pb-0">
            <h6 class="mb-1">Rediger profil</h6>
            <p class="text-sm mb-0">
              Opdater dit profilbillede, coverbillede og navn her.
            </p>
          </div>
          <div class="card-body p-3">
            <div class="row mb-4">
              <div class="col-12 d-flex align-items-center">
                <div class="me-4">
                  <div class="image-upload-container profile-container mb-2">
                    <input type="file" id="profileImageUpload" @change="handleProfileImageUpload" accept="image/*" class="image-upload-input">
                    <label for="profileImageUpload" class="image-upload-label">
                      <img v-if="profileImagePreview" :src="profileImagePreview" alt="Profilbillede" class="preview-image">
                      <img v-else-if="localUserData.adminSettings?.profileImage" :src="localUserData.adminSettings.profileImage" alt="Profilbillede" class="preview-image">
                      <div v-else class="upload-icon-wrapper">
                        <i class="fas fa-user-circle"></i>
                      </div>
                    </label>
                  </div>
                  <label class="form-label text-center d-block small">Profilbillede</label>
                </div>
                <div>
                  <div class="image-upload-container cover-container mb-2">
                    <input type="file" id="coverImageUpload" @change="handleCoverImageUpload" accept="image/*" class="image-upload-input">
                    <label for="coverImageUpload" class="image-upload-label">
                      <img v-if="coverImagePreview" :src="coverImagePreview" alt="Coverbillede" class="preview-image cover-image">
                      <img v-else-if="localUserData.adminSettings?.headerImage" :src="localUserData.adminSettings.headerImage" alt="Coverbillede" class="preview-image cover-image">
                      <div v-else class="upload-icon-wrapper">
                        <i class="fas fa-image"></i>
                      </div>
                    </label>
                  </div>
                  <label class="form-label text-center d-block small">Coverbillede</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">Navn</label>
              <input v-model="userName" type="text" class="form-control" placeholder="Dit navn">
            </div>
          </div>
        </div>
        
        <!-- Ny boks for Meta-indstillinger -->
        <div class="card mt-4">
          <div class="card-header p-3 pb-0">
            <h6 class="mb-1">Meta-indstillinger</h6>
            <p class="text-sm mb-0">
              Indtast dine Meta-indstillinger her.
            </p>
          </div>
          <div class="card-body p-3">
            <div class="form-group">
              <label class="form-label">User Token</label>
              <input v-model="localUserData.adminSettings.MetaSettings.userToken" type="text" class="form-control" placeholder="Indtast User Token">
            </div>
            <div class="form-group mt-3">
              <label class="form-label">Ad Account ID</label>
              <input v-model="localUserData.adminSettings.MetaSettings.adAccountId" type="text" class="form-control" placeholder="Indtast Ad Account ID">
            </div>
            <div class="form-group mt-3">
              <label class="form-label">App ID</label>
              <input v-model="localUserData.adminSettings.MetaSettings.appId" type="text" class="form-control" placeholder="Indtast App ID">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Flyttet Annuller og Gem ændringer knapper -->
    <div class="row mt-4">
      <div class="col-12 text-end">
        <ArgonButton 
          variant="outline" 
          color="success" 
          size="sm" 
          class="me-1"
        >
          Annuller
        </ArgonButton>
        <ArgonButton 
          @click="updateProfile" 
          color="success" 
          size="sm"
          :class="{ 'loading': isSaving }"
        >
          Gem ændringer
        </ArgonButton>
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-upload-container {
  position: relative;
  border: 2px solid #3a416f;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.profile-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  right: -10px;
}

.cover-container {
  width: 200px;
  height: 60px;
}

.image-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.image-upload-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative; /* Tilføj denne */
  left: -5px; /* Flyt labelen 5px til venstre */
}

/* Hvis du vil justere billedet specifikt */
.image-upload-label img {
  object-position: left; /* Dette vil justere billedet til venstre inden for dets container */
}

/* Hvis du vil justere ikonet specifikt */
.image-upload-label i {
  margin-left: 0px; /* Tilføj lidt margin til venstre af ikonet */
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cover-image {
  object-position: center;
}

.upload-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-icon-wrapper i {
  font-size: 1.5rem;
  color: #3a416f;
}

.form-label.small {
  font-size: 0.75rem;
  margin-top: 0.25rem;
  text-align: center;
  width: 100%;
}

/* Tilføj disse nye styles baseret på design guiden */

/* Card styling */
.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
  will-change: transform;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.card:hover {
  transform: translateY(-2px) translateZ(0);
  box-shadow: 
    0 12px 40px 0 rgba(31, 38, 135, 0.45),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

/* Input styling */
.form-control {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 12px 20px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-control:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
  box-shadow: 
    inset 0 2px 4px rgba(0, 0, 0, 0.1),
    0 0 0 3px rgba(255, 255, 255, 0.1);
}

/* Image upload containers */
.image-upload-container {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 4px 16px rgba(31, 38, 135, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.image-upload-container:hover {
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.2);
}

/* Text styling */
h6 {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  letter-spacing: 0.5px;
}

.text-sm {
  color: rgba(255, 255, 255, 0.7);
}

/* Custom button styling (tilføj :deep for at ramme child komponenter) */
:deep(.custom-button) {
  background: rgba(255, 255, 255, 0.08) !important;
  backdrop-filter: blur(8px) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  padding: 8px 16px !important;
  color: white !important;
  font-weight: 500 !important;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1) !important;
  transform: translateZ(0);
  will-change: transform, background-color;
  box-shadow: 
    0 2px 5px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.05) !important;
}

/* Animation keyframes */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

/* Tilføj animation til cards */
.card {
  animation: slideUp 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

/* Switch component styling */
:deep(.argon-switch) {
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

:deep(.argon-switch.checked) {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.15), 
    rgba(66, 186, 150, 0.25)
  ) !important;
}

/* Button styling baseret på design guiden */
:deep(.argon-button) {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 8px 16px;
  color: white;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateZ(0);
  will-change: transform, background-color;
  box-shadow: 
    0 2px 5px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.05);
}

/* Success button variant */
:deep(.argon-button.btn-success) {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.15), 
    rgba(66, 186, 150, 0.25)
  );
  border-color: rgba(66, 186, 150, 0.3);
}

/* Outline success variant */
:deep(.argon-button.btn-outline-success) {
  background: transparent;
  border: 1px solid rgba(66, 186, 150, 0.5);
  color: rgba(66, 186, 150, 0.9);
}

/* Info button variant */
:deep(.argon-button.btn-info) {
  background: linear-gradient(
    135deg, 
    rgba(33, 150, 243, 0.15), 
    rgba(33, 150, 243, 0.25)
  );
  border-color: rgba(33, 150, 243, 0.3);
}

/* Small size variant */
:deep(.argon-button.btn-sm) {
  padding: 6px 12px;
  font-size: 0.875rem;
}

/* Full width variant */
:deep(.argon-button.w-100) {
  width: 100%;
  justify-content: center;
}

/* Hover effects */
:deep(.argon-button:hover) {
  transform: translateY(-1px) translateZ(0);
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.15),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

:deep(.argon-button.btn-success:hover) {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.25), 
    rgba(66, 186, 150, 0.35)
  );
}

:deep(.argon-button.btn-outline-success:hover) {
  background: rgba(66, 186, 150, 0.1);
}

:deep(.argon-button.btn-info:hover) {
  background: linear-gradient(
    135deg, 
    rgba(33, 150, 243, 0.25), 
    rgba(33, 150, 243, 0.35)
  );
}

/* Active state */
:deep(.argon-button:active) {
  transform: translateY(0) translateZ(0);
  box-shadow: 
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.05);
}

/* Disabled state */
:deep(.argon-button:disabled) {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
}

/* Loading state (hvis du har en loading state) */
:deep(.argon-button.loading) {
  position: relative;
  pointer-events: none;
}

:deep(.argon-button.loading::after) {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: button-loading-spinner 0.6s linear infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

/* Spacing mellem knapper */
:deep(.argon-button + .argon-button) {
  margin-left: 8px;
}
</style>