import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:3000'; // Juster dette til din backends URL

export const createBlogPost = async (blogPost) => {
  try {
    const response = await axios.post(`${API_URL}/api/blog-posts`, blogPost);
    return response.data;
  } catch (error) {
    console.error('Fejl ved oprettelse af blogindlæg:', error);
    throw error;
  }
};

export const getBlogPosts = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/blog-posts`);
    return response.data;
  } catch (error) {
    console.error('Fejl ved hentning af blogindlæg:', error);
    throw error;
  }
};
