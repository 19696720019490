// src/utils/imageGenerator.js
import CryptoJS from 'crypto-js';

// Hent API-nøglen fra miljøvariablerne
const ROBOLY_API_KEY = 'b7PAfIZTWFRI0C7FkKZPYkeM2jpCm1KPCxEFR7aW';

// Tilføj formatDate funktion
function formatDate(dateString) {
  if (!dateString) return null;
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export function generateImage(car, templateId) {
  const modifications = {
    scale: 1,
    // Basis information
    api_id: car.id,
    api_categoryName: car.category?.name,
    api_headline: car.headline,
    api_description: car.description,
    
    // Datoer
    api_createdDate: formatDate(car.createdDate),
    api_lastEditedDate: formatDate(car.lastEditedDate),
    api_lastListedDate: formatDate(car.lastListedDate),
    
    // Pris og visninger
    api_price: formatNumber(car.price),
    api_priceInt: car.price || 0,
    api_viewCount: formatNumber(car.viewCount),
    api_weeklyViewCount: formatNumber(car.weeklyViewCount),
    api_dailyViewCount: formatNumber(car.dailyViewCount),
    
    // Salgsdetaljer
    api_salesType: car.salesType,
    api_paymentTypes: Array.isArray(car.paymentTypes) ? car.paymentTypes.join(', ') : '',
    api_url: car.url,
    
    // Billeder
    api_image1: car.attachments?.image_1,
    api_image2: car.attachments?.image_2,
    api_image3: car.attachments?.image_3,
    api_image4: car.attachments?.image_4,
    api_image5: car.attachments?.image_5,
    api_image6: car.attachments?.image_6,
    api_image7: car.attachments?.image_7,
    api_image8: car.attachments?.image_8,
    api_image9: car.attachments?.image_9,
    api_image10: car.attachments?.image_10,
    api_image11: car.attachments?.image_11,
    api_image12: car.attachments?.image_12,
    api_image13: car.attachments?.image_13,
    api_image14: car.attachments?.image_14,
    
    // Køretøjsspecifikationer
    api_color: car.fields?.['Farve'] || car.fields?.['Farve'],
    api_brand: car.fields?.['Mærke'] || car.fields?.['Mærke'],
    api_model: car.fields?.['Model'] || car.fields?.['Model'],
    api_variant: car.fields?.['Variant'] || car.fields?.['Variant'],
    api_greenOwnershipTaxAnnual: formatNumber(car.fields?.['Grøn Ejerafgift helårlig'] || car.fields?.['Grøn ejerafgift']),
    api_gearbox: car.fields?.['Gearkasse'],
    api_fuelType: car.fields?.['Brændstoftype'],
    api_horsepower: car.fields?.['Hestekræfter'] ? `${car.fields['Hestekræfter']} HK` : null,
    api_year: car.fields?.['Årgang'],
    api_kilometers: car.fields?.['Kilometer'] ? `${formatNumber(car.fields['Kilometer'])} km` : null,
    api_registrationDate: formatRegistrationDate(car.fields?.['Registreringsdato']),
    
    // Tekniske specifikationer
    api_numberOfDoors: car.fields?.['Døre'],
    api_newPrice: formatNumber(car.fields?.['Nypris'] || car.fields?.['Ny pris']),
    api_numberOfGears: car.fields?.['Antal gear'],
    api_cylinders: car.fields?.['Cylindre'],
    api_driveWheels: car.fields?.['Trækhjul'] || car.fields?.['Drivhjul'],
    api_fuelTank: car.fields?.['Tankstørrelse'],
    api_kmPerLiter: car.fields?.['Km/l'],
    api_zeroToHundredKmH: car.fields?.['0 - 100 km/t'] || car.fields?.['0-100 km/t'],
    api_topSpeed: car.fields?.['Tophastighed'],
    api_weight: formatNumber(car.fields?.['Vægt']),
    api_payload: formatNumber(car.fields?.['Lasteevne']),
    api_airbags: car.fields?.['Airbags'],
    
    // Nye el-bil specifikke felter
    api_range: car.fields?.['Rækkevidde'],
    api_batteryCapacity: car.fields?.['Batterikapacitet'],
    api_energyConsumption: car.fields?.['Energiforbrug'],
    
    // Udstyr
    api_carType: car.fields?.['Biltype'] || car.fields?.['Karrosseri']
  };

  try {
    const signedRenderLink = getSignedRenderLink({
      apiKey: ROBOLY_API_KEY,
      format: "jpg",
      templateId: templateId,
      modifications: modifications,
    });
    return signedRenderLink;
  } catch (error) {
    console.error('Fejl ved generering af signeret render link:', error);
    return null;
  }
}

function getSignedRenderLink({ templateId, modifications, apiKey, format = "jpg" }) {
  const query = new URLSearchParams(modifications).toString();
  const message = [templateId, format, query].join(":");
  
  // Konverter apiKey til WordArray, hvis det er en streng
  const keyWordArray = typeof apiKey === 'string' ? CryptoJS.enc.Utf8.parse(apiKey) : apiKey;
  
  const signature = CryptoJS.HmacSHA256(message, keyWordArray).toString(CryptoJS.enc.Hex);
  const url = `https://api.robolly.com/templates/${templateId}/render.${format}?${query}&sig=${signature}`;
  return url;
}

// Hjælpefunktioner til formatering
function formatRegistrationDate(dateString) {
  const [day, month, year] = dateString.split('-');
  const months = [
    'januar', 'februar', 'marts', 'april', 'maj', 'juni',
    'juli', 'august', 'september', 'oktober', 'november', 'december'
  ];
  const monthIndex = parseInt(month, 10) - 1; // Konverter måned til 0-baseret indeks
  return `${months[monthIndex]} ${year}`;
}

function formatNumber(number) {
  // Returner 0 eller tom streng hvis number er undefined eller null
  if (number == null) return '0';
  
  // Konverter til string og formater med tusindtalsseparator
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}