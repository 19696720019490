import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import logger from './utils/logger';

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);

// Tilføj global error handler
window.onerror = function(message, source, lineno, colno, error) {
  logger.error('Global error:', { message, source, lineno, colno, error });
  return false;
};

// Håndter uhandlede Promise rejections
window.addEventListener('unhandledrejection', event => {
  logger.error('Unhandled promise rejection:', event.reason);
});

appInstance.mount("#app");
