<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase';

import Configurator from "@/examples/Configurator.vue";
// import Navbar from "@/examples/Navbars/Navbar.vue"; // Fjernet Navbar
import AppFooter from "@/examples/Footer.vue";

const isReady = ref(false);
const store = useStore();
const layout = computed(() => store.state.layout);
const showFooter = computed(() => store.state.showFooter);
const showConfig = computed(() => store.state.showConfig);
const hideConfigButton = computed(() => store.state.hideConfigButton);

onMounted(() => {
  onAuthStateChanged(auth, () => {
    isReady.value = true;
  });
});
</script>
<template>
  <div v-if="!isReady" class="loading-overlay">
    <div class="loader"></div>
  </div>
  <template v-else>
    <div
      v-show="layout === 'landing'"
      class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
    ></div>
    <main class="main-content position-relative max-height-vh-100 h-100">
      <!-- <navbar v-if="showNavbar" /> --> <!-- Fjernet Navbar -->
      <router-view />
      <app-footer v-show="showFooter" />
      <configurator
        :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
      />
    </main>
  </template>
</template>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Skjul Firebase netværks logs */
[data-firebase-log] {
  display: none !important;
}
</style>
