// src/services/TargetingService.js
import config from "../../functions/config.js";

const DAWA_API_URL = "https://api.dataforsyningen.dk/autocomplete";

export const API_BASE_URL = `https://graph.facebook.com/v15.0/act_${config.meta.adAccountId}/targetingsuggestions`;

// Funktion til at søge geografisk baseret på adresse eller postal kode
export async function searchGeographic(query, locationTypes, countryCode) {
  try {
    const response = await fetch(`${API_BASE_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${config.meta.userToken}`,
      },
      body: JSON.stringify({
        query,
        type: locationTypes, // f.eks. ['address']
        country: countryCode,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Geographic search data:", data);
    return data;
  } catch (error) {
    console.error("Error searching geographic locations:", error);
    throw error;
  }
}

// Funktion til at søge efter steder (Places)
export async function searchPlaces(query, countryCode) {
  try {
    const response = await fetch(`${API_BASE_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${config.meta.userToken}`,
      },
      body: JSON.stringify({
        query,
        type: ["place"],
        country: countryCode,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Places search data:", data);
    return data;
  } catch (error) {
    console.error("Error searching places:", error);
    throw error;
  }
}

export const searchInterests = async (query) => {
  const response = await fetch(
    `https://graph.facebook.com/v12.0/search?type=adinterest&limit=50&q=${encodeURIComponent(query)}&access_token=${config.meta.userToken}`,
  );
  const data = await response.json();
  return data;
};

export const searchDemographics = async (query, classType) => {
  const response = await fetch(
    `https://graph.facebook.com/v12.0/search?type=adgeolocation&class=${classType}&q=${encodeURIComponent(query)}&access_token=${config.meta.userToken}`,
  );
  const data = await response.json();
  return data;
};

export const getRadiusSuggestions = async (latitude, longitude, unit) => {
  const response = await fetch(
    `https://graph.facebook.com/v12.0/search?type=adradiussuggestion&latitude=${latitude}&longitude=${longitude}&distance_unit=${unit}&access_token=${config.meta.userToken}`,
  );
  const data = await response.json();
  return data;
};

export async function searchLocationTargeting(query) {
  try {
    console.log("Searching for location:", query);

    const response = await fetch(
      `${DAWA_API_URL}?q=${encodeURIComponent(query)}&type=adresse&fuzzy`,
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error("API Error Response:", errorText);
      throw new Error(
        `Network response was not ok: ${response.status} ${response.statusText}\n${errorText}`,
      );
    }

    const data = await response.json();
    console.log("Location search data:", data);

    // Formatér resultaterne
    return data.map((item) => ({
      key: item.data.id,
      name: item.tekst,
      type: "address",
      data: {
        latitude: item.data.y,
        longitude: item.data.x,
      },
    }));
  } catch (error) {
    console.error("Error searching locations:", error);
    throw error;
  }
}
