import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default function useCurrentProfile() {
  const route = useRoute()
  const store = useStore()
  
  const profileUserId = computed(() => {
    // Hvis vi har et route param ID og er admin/manager, brug det
    if (route.params.id && 
        (store.state.activeUser?.role === 1 || 
         store.state.activeUser?.role === 2)) {
      return route.params.id
    }
    // For alle brugertyper, returner deres eget ID
    return store.state.activeUser?.id
  })

  const isViewingOwnProfile = computed(() => {
    return profileUserId.value === store.state.activeUser?.id
  })

  const canAccessOtherUsers = computed(() => {
    // Kun admin (1) og manager (2) kan se andre brugeres profiler
    return store.state.activeUser?.role === 1 || store.state.activeUser?.role === 2
  })

  return {
    profileUserId,
    isViewingOwnProfile,
    canAccessOtherUsers
  }
} 