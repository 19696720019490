// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { 
  enableNetwork,
  disableNetwork,
  initializeFirestore
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Din Firebase konfiguration
const firebaseConfig = {
  apiKey: "AIzaSyAx_5ZnR__8sizS5_1k3uP-gilviDiGO6Q",
  authDomain: "toft-d4f39.firebaseapp.com",
  projectId: "toft-d4f39",
  storageBucket: "toft-d4f39.appspot.com",
  messagingSenderId: "277892110082",
  appId: "1:277892110082:web:4d4578f88e521c2fb3141b",
  measurementId: "G-L976F1ZSTY",
};

// Sørg for at app kun initialiseres én gang
let app;
try {
  app = initializeApp(firebaseConfig);
} catch (error) {
  if (!/already exists/.test(error.message)) {
    console.error('Firebase initialisering fejl:', error.stack);
  }
}

// Firestore settings
const settings = {
  cacheSizeBytes: 1048576, // 1 MB
  ignoreUndefinedProperties: true,
  experimentalAutoDetectLongPolling: true,
};

// Initialiser Firestore med settings
const db = initializeFirestore(app, settings);


// Initialiser andre services
const auth = getAuth(app);
const storage = getStorage(app);

// Auth state observer
const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
  if (user) {
    enableNetwork(db).catch(console.error);
  } else {
    disableNetwork(db).catch(console.error);
  }
});

// Cleanup ved window unload
window.addEventListener('unload', () => {
  unsubscribeAuth();
});

// Konfigurer Firebase logging
if (process.env.NODE_ENV === 'production') {
  // Deaktiver alle Firebase logs i produktion
  window.console.log = () => {};
  window.console.error = () => {};
  window.console.debug = () => {};
  window.console.warn = () => {};
}

// Eksporter det hele
export { app, auth, db, storage };
