<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-3">
        <div class="card">
          <div class="card-header p-3 pb-0">
            <h4 style="font-weight: bolder !important;">Gruppenavne</h4>
          </div>
          <div class="p-3">
            <div v-for="designType in availableDesignTypes" :key="designType" class="row mb-2 align-items-center">
              <div class="col">
                <div class="designGroup" 
                     @click="selectDesignType(designType)" 
                     :class="{ active: designType === activeDesignType }" 
                     style="cursor: pointer;">
                  {{ designType }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-2">
                <h4 style="font-weight: bolder !important;">{{ selectedStatus }}</h4>
              </div>
              <div class="col-6">
                <!-- Format vælger fjernet -->
              </div>
              <div class="col-4 text-end">
                <button class="btn mb-0 bg-gradient-dark btn-md me-2" @click="$emit('back')">Gå tilbage</button>
                <button class="btn mb-0 bg-gradient-success btn-md" @click="$emit('next')" :disabled="!selectedDesign">Næste</button>
              </div>
            </div>
          </div>
        </div>
        
        <div class="designs-container">
          <div v-for="design in filteredDesigns" :key="design.uuid" 
               class="design-card" 
               @click="selectDesign(design)" 
               :class="{ 'selected': isSelected(design) }">
            <div class="image-container">
              <img :src="design.file" alt="" class="design-image">
              <button class="expand-btn" @click.stop="openModal(design)">
                <i class="fas fa-expand"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div v-if="showModal" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <button class="modal-close" @click="closeModal">×</button>
      <img :src="selectedModalImage" alt="" class="modal-image">
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue';

const props = defineProps({
  designTypes: {
    type: Array,
    required: true
  },
  designImages: {
    type: Array,
    required: true
  },
  selectedStatus: {
    type: String,
    required: true
  },
  selectedDesign: {
    type: Object,
    default: null
  }
});

const emit = defineEmits(['update:selectedDesign', 'back', 'next']);

const availableDesignTypes = computed(() => {
  return props.designTypes.filter(designType => {
    // Check om der findes mindst ét design for denne designtype
    return props.designImages.some(design => {
      if (!design?.file || !Array.isArray(design.groups)) return false;
      
      // Check om designet har de korrekte kanaler
      const hasValidChannels = Array.isArray(design.channels) && design.channels.some(channel => 
        channel.toLowerCase() === 'facebook' || channel.toLowerCase() === 'instagram'
      );
      
      // Check om designet har de korrekte emner
      const hasValidEmner = Array.isArray(design.emner) && (
        design.emner.includes(props.selectedStatus) ||
        design.emner.some(emne => props.selectedStatus.toLowerCase().includes(emne.toLowerCase())) ||
        design.emner.some(emne => emne.toLowerCase().includes(props.selectedStatus.toLowerCase()))
      );
      
      return design.groups.includes(designType) && hasValidChannels && hasValidEmner;
    });
  });
});

const activeDesignType = ref('');
watchEffect(() => {
  if (availableDesignTypes.value.length > 0 && !availableDesignTypes.value.includes(activeDesignType.value)) {
    activeDesignType.value = availableDesignTypes.value[0];
  }
});

const filteredDesigns = computed(() => {
  return props.designImages.filter(design => {
    // Validér først at design objektet har de nødvendige properties
    if (!design?.file) return false;

    // Check filtype
    const matchesType = /\.(jpg|jpeg|png)$/i.test(design.file.split('?')[0]);
    if (!matchesType) return false;

    // Design type matching
    const hasValidGroups = Array.isArray(design.groups);
    const matchesDesignType = hasValidGroups && design.groups.includes(activeDesignType.value);
    if (!matchesDesignType) return false;
    
    // Status/Emne matching
    const hasValidEmner = Array.isArray(design.emner);
    const matchesStatus = hasValidEmner && (
      design.emner.includes(props.selectedStatus) ||
      design.emner.some(emne => props.selectedStatus.toLowerCase().includes(emne.toLowerCase())) ||
      design.emner.some(emne => emne.toLowerCase().includes(props.selectedStatus.toLowerCase()))
    );
    if (!matchesStatus) return false;

    // Kanal matching - case insensitive
    const hasValidChannels = Array.isArray(design.channels);
    if (!hasValidChannels) return false;

    const designChannels = design.channels.map(channel => channel.toLowerCase());
    return designChannels.includes('facebook') || designChannels.includes('instagram');
  });
});

const selectDesignType = (designType) => {
  activeDesignType.value = designType;
};

const selectDesign = (design) => {
  // Hvis det valgte design er det samme som det nuværende, fravælg det
  if (props.selectedDesign?.uuid === design.uuid) {
    emit('update:selectedDesign', null);
  } else {
    // Ellers vælg det nye design
    emit('update:selectedDesign', design);
  }
};

const isSelected = (design) => {
  return props.selectedDesign?.uuid === design.uuid;
};

const showModal = ref(false);
const selectedModalImage = ref('');

const openModal = (design) => {
  selectedModalImage.value = design.file;
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
  selectedModalImage.value = '';
};
</script>

<style scoped>
.design-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  overflow: hidden;
  padding: 0.3rem;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

.design-card:hover {
  transform: translateY(-5px);
}

.design-card.selected {
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

.designs-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.design-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.designGroup {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.designGroup:hover {
  background: rgba(255, 255, 255, 0.1);
}

.designGroup.active {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.image-container {
  position: relative;
}

.expand-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .expand-btn {
  opacity: 1;
}

.expand-btn:hover {
  background: rgba(0, 0, 0, 0.7);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 1rem;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.modal-image {
  max-width: 100%;
  max-height: calc(90vh - 2rem);
  object-fit: contain;
  border-radius: 15px;
}

.modal-close {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.modal-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
</style>