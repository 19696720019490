import axios from 'axios';

// Eksporter ANGLES så den kan bruges i andre komponenter
export const ANGLES = ['01', '05', '09', '17', '23', '25', '28', '29'];

// Global state til at holde styr på loading og cache
const state = {
  isPreloading: false,
  loadedAngles: new Set(),
  imageCache: new Map(),
};

// Hjælpefunktion til at preloade billeder
const preloadImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(url);
    img.onerror = () => reject(new Error(`Fejl ved preload af billede: ${url}`));
    img.src = url;
  });
};

// Transmission mapping
const mapTransmission = (transmission) => {
  const mapping = {
    'Automatisk': 'automatic',
    'Manuel': 'manual'
  };
  return mapping[transmission] || 'automatic';
};

// PowerTrain mapping
const mapPowerTrain = (fuelType) => {
  const mapping = {
    'Benzin': 'petrol',
    'Diesel': 'diesel',
    'El': 'electric',
    'Hybrid': 'hybrid'
  };
  return mapping[fuelType] || 'petrol';
};

// Ny farve mapping funktion
const mapPaintId = (color) => {
  const colorMapping = {
    'Sort': 'imaginblack',
    'Sortmetal': 'imaginblack',
    'Hvid': 'imaginwhite',
    'Hvidmetal': 'imaginwhite',
    'Grå': 'imagingrey',
    'Gråmetal': 'imagingrey',
    'Rød': 'imaginred',
    'Rødmetal': 'imaginred'
  };
  return colorMapping[color] || null;
};

// Tilføj disse konstanter i toppen af filen
export const SURROUNDINGS = [
  { id: 'sur1', name: 'Mediterranean Village Square' },
  { id: 'sur2', name: 'White Hall' },
  { id: 'sur3', name: 'Sunset House' },
  { id: 'sur4', name: 'Desert Rock' },
  { id: 'sur5', name: 'Warehouse' },
  { id: 'sur6', name: 'Tundra Drive' },
  { id: 'sur21', name: 'Brick House' }
];

export const VIEWPOINTS = ['1', '2', '3', '4', '5'];
export const ASPECT_RATIOS = ['16:10', '10:16', '2:1', '32:9'];
export const OVERLAY_AREAS = ['none', 'right', 'left'];

// Opdateret generateImaginUrl funktion
export const generateImaginUrl = async (carData, options = {}) => {
  try {
    // Check først for standard farver
    const mappedPaintId = mapPaintId(carData.fields?.Farve);
    
    const baseParams = {
      customer: 'n4c',
      make: carData.fields?.Mærke?.toLowerCase() || '',
      modelFamily: carData.fields?.Model?.toLowerCase() || '',
      modelRange: carData.fields?.Model?.toLowerCase() || '',
      modelYear: carData.fields?.Årgang || '2020',
      // Brug mappedPaintId hvis det findes, ellers brug den eksisterende logik
      paintId: mappedPaintId || (typeof carData.customPaintId === 'object' ? carData.customPaintId.paintId : (carData.customPaintId || 'pspc0004')),
      powerTrain: mapPowerTrain(carData.fields?.Brændstoftype),
      transmission: mapTransmission(carData.fields?.Gearkasse),
      zoomType: 'fullscreen',
      quality: 85,
      width: 800,
      surrounding: options.surrounding,
      viewPoint: options.viewPoint,
      aspectRatio: options.aspectRatio,
      overlayArea: options.overlayArea,
    };

    console.log('Bruger paintId:', baseParams.paintId);

    // Generer en unik nøgle for denne bil/vinkel kombination
    const getCacheKey = (angle) => {
      return `${baseParams.make}_${baseParams.modelFamily}_${baseParams.modelYear}_${baseParams.paintId}_${angle}`;
    };

    // Funktion til at forhåndsindlæse næste vinkler
    const preloadNextAngles = async (currentAngle) => {
      const nextAngles = ['01', '05', '09'].filter(angle => angle !== currentAngle);
      nextAngles.forEach(angle => {
        const cacheKey = getCacheKey(angle);
        if (!state.imageCache.has(cacheKey)) {
          const params = { ...baseParams, angle };
          axios.get('https://cdn.imagin.studio/getImage', { params })
            .then(response => {
              if (response.status === 200) {
                state.imageCache.set(cacheKey, response.request.responseURL);
                preloadImage(response.request.responseURL);
              }
            })
            .catch(() => {/* Ignorer fejl ved preloading */});
        }
      });
    };

    // Hent URL for den anmodede vinkel
    const angle = options.angle || '01';
    const cacheKey = getCacheKey(angle);
    
    // Check cache først
    if (state.imageCache.has(cacheKey)) {
      // Start preload af andre vinkler
      preloadNextAngles(angle);
      return state.imageCache.get(cacheKey);
    }

    // Hvis ikke i cache, hent fra API
    const params = { ...baseParams, angle };
    const response = await axios.get('https://cdn.imagin.studio/getImage', { params });
    
    if (response.status === 200) {
      const url = response.request.responseURL;
      state.imageCache.set(cacheKey, url);
      // Start preload af andre vinkler
      preloadNextAngles(angle);
      await preloadImage(url);
      return url;
    }

    throw new Error('Kunne ikke generere billede');
  } catch (error) {
    console.error('Fejl ved generering af billede:', error);
    throw error;
  }
};