<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { signOut } from "firebase/auth";
import { auth } from "@/firebase"; // Ensure Firebase auth is set up correctly
import { activateDarkMode } from "@/assets/js/dark-mode"; // Only activate dark mode

const store = useStore();
const router = useRouter();

// Remove the assignment statement
computed(() => store.state.isNavFixed);
computed(() => store.state.sidebarType);
const toggleConfigurator = () => store.commit("toggleConfigurator");

// mutations
const setSidebarType = (type) => store.commit("sidebarType", type);

// Force dark mode to always be activated
store.state.darkMode = true;
activateDarkMode();
setSidebarType("bg-default");

// Logout function
const logOut = async () => {
  try {
    await signOut(auth); // Firebase sign out
    router.push("authentication/signin"); // Redirect to login page after logout
  } catch (error) {
    console.error("Logout error: ", error);
  }
};
</script>

<template>
  <div class="fixed-plugin">
    <a
      class="px-3 py-2 fixed-plugin-button text-dark position-fixed"
      @click="toggleConfigurator"
    >
      <i class="py-2 fa fa-cog"></i>
    </a>
    <div class="shadow-lg card">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="" :class="isRTL ? 'float-end' : 'float-start'">
          <h5 class="mt-3 mb-0">MakeMore</h5>
          <p>Indstillinger</p>
        </div>
        <div
          class="mt-4"
          :class="isRTL ? 'float-start' : 'float-end'"
          @click="toggleConfigurator"
        >
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="pt-0 card-body pt-sm-3">
        <!-- Sidebar Backgrounds -->
        <a href="#" class="switch-trigger background-color"> </a>
        <p class="mt-2 text-sm d-xl-none d-block">
          You can change the sidenav type just on desktop view.
        </p>

        <hr class="horizontal dark my-4" />

        <!-- Logout Button -->
        <div class="mt-4">
          <button class="btn btn-danger w-100" @click="logOut">
            Leave for now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
