<template>
  <div class="opret-designs">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-3">
          <div class="card">
            <div class="card-header p-3 pb-0">
              <h4 style="font-weight: bolder !important;">Design Grupper</h4>
            </div>
            <div class="p-3">
              <div v-for="gruppe in designGrupper" :key="gruppe.id" class="row mb-2 align-items-center">
                <div class="col">
                  <div class="designGroup" @click="vælgGruppe(gruppe)" style="cursor: pointer;">{{ gruppe.name }}</div>
                </div>
                <div class="col-auto">
                  <argon-button @click="redigerGruppe(gruppe)" color="primary" size="sm" class="me-1 py-1 px-2 button">Rediger</argon-button>
                  <argon-button @click="sletGruppe(gruppe.id)" color="danger" size="xs" variant="outline" class="py-1 px-2 button">Slet</argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-2">
                  <h4 style="font-weight: bolder !important;">Post Designs</h4>
                </div>
                <div class="col-6">
                  <div class="row" style="column-gap: 1rem;">
                    <div class="col" style="padding: unset; text-align: center; width: 6rem;">
                      <select v-model="filters.type" class="form-control" name="type-filter">
                        <option value="">Vælg type</option>
                        <option value="statisk">Statisk</option>
                        <option value="dynamisk">Dynamisk</option>
                      </select>
                    </div>
                    <div class="col" style="padding: unset; text-align: center; width: 6rem;">
                      <select v-model="filters.channel" class="form-control" name="kanal-filter">
                        <option value="">Vælg kanal</option>
                        <option v-for="channel in uniqueChannels" :key="channel" :value="channel">{{ channel }}</option>
                      </select>
                    </div>
                    <div class="col" style="padding: unset; text-align: center; width: 6rem;">
                      <select v-model="filters.format" class="form-control" name="format-filter">
                        <option value="">Vælg format</option>
                        <option v-for="format in uniqueFormats" :key="format" :value="format">{{ format }}</option>
                      </select>
                    </div>
                    <div class="col" style="padding: unset; text-align: center; width: 6rem;">
                      <select v-model="filters.group" class="form-control" name="group-filter">
                        <option value="">Vælg gruppe</option>
                        <option v-for="group in designGrupper" :key="group.id" :value="group.name">{{ group.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <argon-button @click="visOpretGruppeModal" color="success" size="md" class="me-2">OPRET GRUPPE</argon-button>
                  <argon-button @click="visOpretDesignModal" color="success" size="md">OPRET DESIGN</argon-button>
                </div>
              </div>
            </div>
          </div>
          
          <h4 style="font-weight: bolder !important; margin-top: 1rem;">{{ valgtGruppe ? valgtGruppe.name : 'Alle designs' }}</h4>
          <div class="designs-container">
            <div v-for="design in filteredDesigns" :key="design.id" class="design-card">
              <img :src="design.file" alt="" class="design-image">
              <p>UUID: <b>{{ design.uuid }}</b></p>
              <div class="button-group">
                <argon-button @click="redigerDesign(design)" color="primary" size="sm" class="me-1 py-1 px-2 button">Rediger</argon-button>
                <argon-button @click="sletDesign(design.id)" color="danger" size="xs" variant="outline" class="py-1 px-2 button">Slet</argon-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Opret/Rediger Gruppe Modal -->
    <div v-if="visGruppeModal" class="modal-overlay">
      <div class="modal-container">
        <div class="modal-header">
          <h3>{{ redigeringstilstand ? 'Rediger Gruppe' : 'Opret Ny Gruppe' }}</h3>
          <argon-button @click="lukGruppeModal" class="close-button" color="transparent" size="sm">&times;</argon-button>
        </div>
        <form @submit.prevent="gemGruppe" class="modal-form">
          <div class="form-group">
            <label for="gruppeNavn">Gruppenavn</label>
            <input 
              type="text" 
              id="gruppeNavn" 
              v-model="aktivGruppe.navn" 
              required
              placeholder="Indtast gruppenavn"
            >
          </div>
          <div class="button-group">
            <argon-button @click="lukGruppeModal" color="light" size="md" class="me-2">ANNULLER</argon-button>
            <argon-button type="submit" color="success" size="md">{{ redigeringstilstand ? 'GEM ÆNDRINGER' : 'GEM GRUPPE' }}</argon-button>
          </div>
        </form>
      </div>
    </div>
    <!-- Tilføj DesignModal komponenten her -->
    <DesignModal 
      :isVisible="visDesignModal"
      :designToEdit="designToEdit"
      @close="lukDesignModal"
      @save="gemDesign"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { collection, doc, setDoc, updateDoc, deleteDoc, serverTimestamp, getDocs, getDoc, addDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import ArgonButton from "@/components/ArgonButton.vue";
import DesignModal from "./components/DesignModal.vue";
import { deleteDesignAndReferences } from '@/services/designDeletionService';

const designGrupper = ref([]);
const designs = ref([]);
const visGruppeModal = ref(false);
const redigeringstilstand = ref(false);
const aktivGruppe = ref({
  id: null,
  navn: ''
});
const visDesignModal = ref(false);
const designToEdit = ref(null);
const valgtGruppe = ref(null);
const filters = ref({
  type: '',
  channel: '',
  format: '',
  group: ''
});

const uniqueChannels = computed(() => {
  return [...new Set(designs.value.flatMap(design => design.channels))];
});

const uniqueFormats = computed(() => {
  return [...new Set(designs.value.flatMap(design => design.format))];
});

const filteredDesigns = computed(() => {
  return designs.value.filter(design => {
    return (!filters.value.type || design.type === filters.value.type) &&
           (!filters.value.channel || design.channels.includes(filters.value.channel)) &&
           (!filters.value.format || design.format.includes(filters.value.format)) &&
           (!valgtGruppe.value || design.groups.includes(valgtGruppe.value.name));
  });
});

const visOpretGruppeModal = () => {
  redigeringstilstand.value = false;
  aktivGruppe.value = { id: null, navn: '' };
  visGruppeModal.value = true;
};

const redigerGruppe = (gruppe) => {
  redigeringstilstand.value = true;
  aktivGruppe.value = { id: gruppe.id, navn: gruppe.name };
  visGruppeModal.value = true;
};

const lukGruppeModal = () => {
  visGruppeModal.value = false;
  aktivGruppe.value = { id: null, navn: '' };
};

const gemGruppe = async () => {
  try {
    if (redigeringstilstand.value) {
      await updateDoc(doc(db, 'designGroup', aktivGruppe.value.id), {
        name: aktivGruppe.value.navn,
        updatedAt: serverTimestamp()
      });
      console.log('Gruppe opdateret med ID: ', aktivGruppe.value.id);
    } else {
      const gruppeRef = doc(collection(db, 'designGroup'));
      const gruppeData = {
        id: gruppeRef.id,
        name: aktivGruppe.value.navn,
        createdAt: serverTimestamp()
      };
      await setDoc(gruppeRef, gruppeData);
      console.log('Gruppe oprettet med ID: ', gruppeRef.id);
    }
    lukGruppeModal();
    await hentDesignGrupper();
  } catch (error) {
    console.error('Fejl ved gem/opdater gruppe: ', error);
  }
};

const sletGruppe = async (gruppeId) => {
  if (confirm('Er du sikker på, at du vil slette denne gruppe?')) {
    try {
      await deleteDoc(doc(db, 'designGroup', gruppeId));
      console.log('Gruppe slettet med ID: ', gruppeId);
      await hentDesignGrupper();
    } catch (error) {
      console.error('Fejl ved sletning af gruppe: ', error);
    }
  }
};

const hentDesignGrupper = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'designGroup'));
    designGrupper.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Fejl ved hentning af designgrupper: ', error);
  }
};

const visOpretDesignModal = () => {
  designToEdit.value = null;
  visDesignModal.value = true;
};

const lukDesignModal = () => {
  visDesignModal.value = false;
  designToEdit.value = null;
};

const gemDesign = async (designData) => {
  try {
    const designToSave = {
      ...designData,
      emner: designData.type === 'statisk' ? designData.emner : [], // Kun gem emner hvis typen er statisk
      updatedAt: serverTimestamp()
    };

    if (designToEdit.value) {
      await updateDoc(doc(db, 'designImages', designToEdit.value.id), designToSave);
      console.log('Design opdateret:', designToEdit.value.id);
    } else {
      const docRef = await addDoc(collection(db, 'designImages'), {
        ...designToSave,
        createdAt: serverTimestamp(),
      });
      console.log('Nyt design oprettet med ID:', docRef.id);
    }
    await hentDesigns();
    lukDesignModal();
  } catch (error) {
    console.error('Fejl ved gem/opdater design: ', error);
  }
};

const hentDesigns = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'designImages'));
    designs.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Fejl ved hentning af designs: ', error);
  }
};

const redigerDesign = async (design) => {
  try {
    const designDoc = await getDoc(doc(db, 'designImages', design.id));
    if (designDoc.exists()) {
      designToEdit.value = { id: designDoc.id, ...designDoc.data() };
      visDesignModal.value = true;
    } else {
      console.error('Design ikke fundet');
    }
  } catch (error) {
    console.error('Fejl ved hentning af design: ', error);
  }
};

const sletDesign = async (designId) => {
  if (!confirm('Er du sikker på, at du vil slette dette design? Det vil også slette alle referencer til designet i hele systemet.')) {
    return;
  }

  try {
    const designToDelete = designs.value.find(d => d.id === designId);
    
    if (!designToDelete) {
      throw new Error('Design ikke fundet');
    }

    await deleteDesignAndReferences(designId, designToDelete.uuid);
    
    await hentDesigns(); // Genindlæs designs
    
  } catch (error) {
    console.error('Fejl ved sletning af design:', error);
    alert('Der opstod en fejl ved sletning af designet. Prøv venligst igen.');
  }
};

const vælgGruppe = (gruppe) => {
  valgtGruppe.value = gruppe;
  filters.value.group = gruppe.name;
};

onMounted(() => {
  hentDesignGrupper();
  hentDesigns();
});
</script>

<style scoped>
/* ... eksisterende styles ... */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #393939;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 400px;
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.modal-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.row_gap_5 {
  row-gap: 1.25rem;
}

.mini-button {
  font-size: 0.65rem !important;
  line-height: 1.2 !important;
  padding: 0.15rem 0.3rem !important;
}

.align-items-center {
  align-items: center !important;
}

.designGroup {
  cursor: pointer;
  transition: color 0.3s ease;
}

.designGroup:hover {
  color: #5e72e4;
}

.design-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.filters {
  display: flex;
  gap: 1rem;
}

.designs-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.design-card {
  background-color: #2c2c2c;
  border-radius: 8px;
  overflow: hidden;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;/* 250px for billedet + 10px padding (5px på hver side) */
  height: auto; /* Lad højden tilpasse sig indholdet */
  margin: 0 auto; /* Centrerer kortet hvis det er i et grid */
}

.design-image {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
</style>