<script setup>
import { ref, onMounted } from "vue";
import { doc, getDoc } from "firebase/firestore"; // Firestore-funktioner
import { auth, db } from "@/firebase"; // Importer din Firebase-opsætning
import setNavPills from "@/assets/js/nav-pills.js";

const activeTab = ref("Hjem");
const isSuperAdmin = ref(false);
const isAdminOrSuperAdmin = ref(false);
const userRole = ref(0);

onMounted(async () => {
  setNavPills();

  // Hent den aktuelle bruger fra Firebase Authentication
  const user = auth.currentUser;
  if (user) {
    const userId = user.uid;

    // Hent brugerdata fra Firestore
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      userRole.value = userData.role;

      // Kontrollér om brugeren er admin eller super-admin
      if (userData.role === 1 || userData.role === 2) {
        isAdminOrSuperAdmin.value = true;
      }
      if (userData.role === 1) {
        isSuperAdmin.value = true;
      }
    } else {
      console.error("Ingen brugerdata fundet i Firestore.");
    }
  } else {
    console.error("Ingen bruger er logget ind.");
  }
});

const setActiveTab = (tabName) => {
  activeTab.value = tabName;
  emit('update:activeTab', tabName);
};

const emit = defineEmits(['update:activeTab']);
</script>

<template>
  <div class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist">
      <li class="nav-item" v-if="!isAdminOrSuperAdmin">
        <a
          class="nav-link mb-0 px-0 py-1"
          :class="{ active: activeTab === 'Hjem' }"
          @click="setActiveTab('Hjem')"
          href="#"
          role="tab"
          aria-selected="true"
        >
          <i class="fas fa-home text-sm me-2"></i> Hjem
        </a>
      </li>
      <li class="nav-item" v-if="isAdminOrSuperAdmin">
        <a
          class="nav-link mb-0 px-0 py-1"
          :class="{ active: activeTab === 'AdminPanel' }"
          @click="setActiveTab('AdminPanel')"
          href="#"
          role="tab"
          aria-selected="false"
        >
          <i class="fas fa-cog text-sm me-2"></i> Admin Panel
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1"
          :class="{ active: activeTab === 'Indstillinger' }"
          @click="setActiveTab('Indstillinger')"
          href="#"
          role="tab"
          aria-selected="false"
        >
          <i class="fas fa-user text-sm me-2"></i> Indstillinger
        </a>
      </li>
      <li class="nav-item" v-if="isSuperAdmin">
        <a
          class="nav-link mb-0 px-0 py-1"
          :class="{ active: activeTab === 'OpretDesigns' }"
          @click="setActiveTab('OpretDesigns')"
          href="#"
          role="tab"
          aria-selected="false"
        >
          <i class="fas fa-plus text-sm me-2"></i> Opret Designs
        </a>
      </li>
      <li class="nav-item" v-if="isSuperAdmin">
        <a
          class="nav-link mb-0 px-0 py-1"
          :class="{ active: activeTab === 'BlogAdmin' }"
          @click="setActiveTab('BlogAdmin')"
          href="#"
          role="tab"
          aria-selected="false"
        >
          <i class="fas fa-blog text-sm me-2"></i> Blog
        </a>
      </li>
    </ul>
  </div>
</template>
