<script setup>
import { ref } from "vue";

const props = defineProps({
  currentStep: {
    type: Number,
    required: true
  },
  totalSteps: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['update:currentStep']);

const getStepTitle = (step) => {
  const titles = [
    'Emne',
    'Overskrift',
    'Platforme',
    'Upload',
    'Beskrivelse',
    'Opslagstype',
    'Dato og tid'
  ];
  return `${step}. ${titles[step - 1]}`;
};

const setStep = (step) => {
  emit('update:currentStep', step);
};
</script>

<template>
  <div class="multisteps-form__progress">
    <button
      v-for="step in totalSteps"
      :key="step"
      class="multisteps-form__progress-btn"
      :class="{ 'js-active': currentStep >= step }"
      @click="setStep(step)"
    >
      {{ getStepTitle(step) }}
    </button>
  </div>
</template>

<style scoped>
.multisteps-form__progress {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.multisteps-form__progress-btn {
  background-color: transparent;
  border: none;
  color: #999;
  font-weight: 400;
  padding: 0 10px;
  position: relative;
  transition: 0.2s;
  text-decoration: none;
}

.multisteps-form__progress-btn::before,
.multisteps-form__progress-btn::after {
  display: none;
}

.multisteps-form__progress-btn.js-active {
  color: #5e72e4;
  font-weight: 600;
}
</style>
